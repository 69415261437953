import React, { useEffect, useRef, useState } from "react";
import { SketchPicker } from "react-color";
import { ToastContainer } from "react-toastify";
import { toastError, toastSuccess } from "../../../../services/ToastService";
import {
  getBranding,
  updateBranding,
  updateLogo,
} from "../../../../services/api";

const Branding = () => {
  const [barcolor, setBarColor] = useState("#768fbe");
  const [selectedBarColor, setSelectedBarColor] = useState("#768fbe");

  const [contentColor, setContentColor] = useState("#00419a");
  const [selectedContentColor, setSelectedContentColor] = useState("#00419a");

  const logoRef = useRef(null);
  const [logo, setLogo] = useState();
  const [id, setId] = useState();

  const setBarColorFunc = async (e) => {
    setBarColor(e.hex);
  };

  const selectBarColor = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    setSelectedBarColor(barcolor);
  };

  const setContentColorFunc = async (e) => {
    setContentColor(e.hex);
  };

  const selectContentColor = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    setSelectedContentColor(contentColor);
  };

  const fetchData = async () => {
    try {
      let branding = await getBranding();
      if (branding.data) {
        setSelectedBarColor(branding.data[0].barColor);
        setSelectedContentColor(branding.data[0].contentColor);
        setLogo(branding.data[0].logo);
        setId(branding.data[0]._id);
      }
    } catch (error) {
      console.error("Error fetching slider content:", error);
      toastError();
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onSubmit = async (e) => {
    e.stopPropagation();
    e.preventDefault();

    const shouldUploadFile = (fileRef, fileType) => {
      if (!fileRef.current || fileRef.current.files.length === 0) {
        return false;
      }
      const file = fileRef.current.files[0];
      return file && file.type.startsWith(fileType + "/");
    };

    try {
      let data = {
        barColor: barcolor,
        contentColor: contentColor,
      };
      await updateBranding(data, id);
      if (logoRef) {
        if (shouldUploadFile(logoRef, "image")) {
          const formData = new FormData();
          formData.append("image", logoRef.current.files[0]);
          await updateLogo(formData, id);
        }
      }
      toastSuccess();
      fetchData();
    } catch (error) {
      console.log(error);
    }
  };

  const resetColors = async () => {
    setBarColor("#768fbe"); // Reset bar color
    setSelectedBarColor("#768fbe");
    setContentColor("#00419a"); // Reset content color
    setSelectedContentColor("#00419a");

    let data = {
      barColor: barcolor,
      contentColor: contentColor,
    };
    await updateBranding(data, id);
  };

  return (
    <>
      <main className="main">
        <div className="pagetitle">
          <h1>Page Branding</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="index.html">Auth</a>
              </li>
              <li className="breadcrumb-item">Components</li>
              <li className="breadcrumb-item active">Page Branding</li>
            </ol>
          </nav>
        </div>
        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="d-flex justify-content-between align-items-center">
                        <h5 className="card-title">Add Bar Color</h5>
                      </div>
                      <hr className="border-2" />
                      <div className="row">
                        <div className="col-6">
                          <SketchPicker
                            color={barcolor}
                            onChange={(e) => setBarColorFunc(e)}
                          />
                          <button
                            type="button"
                            style={{ marginTop: "10px", marginLeft: "80px" }}
                            className="btn btn-primary "
                            onClick={selectBarColor}
                          >
                            OK{" "}
                          </button>
                        </div>
                        <div style={{ marginTop: "160px" }} className="col-6">
                          <input
                            type="text"
                            className="form-control"
                            value={selectedBarColor.toString()}
                            disabled
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="d-flex justify-content-between align-items-center">
                        <h5 className="card-title">Add Content Color</h5>
                        <button
                          style={{
                            backgroundColor: "#6F8FAF",
                            color: "white",
                            border: "none",
                            borderRadius: "5px",
                            padding: "0 10px",
                            fontSize: "16px",
                            cursor: "pointer",
                            transition: "background-color 0.3s ease",
                            height: "35px",
                            display: "flex",
                            alignItems: "center",
                          }}
                          onMouseOver={(e) =>
                            (e.target.style.backgroundColor = "#6F7FAF")
                          }
                          onMouseOut={(e) =>
                            (e.target.style.backgroundColor = "#6F8FAF")
                          }
                          onClick={resetColors}
                        >
                          RESET COLOR
                        </button>
                      </div>
                      <hr className="border-2" />
                      <div className="row">
                        <div className="col-6">
                          <SketchPicker
                            color={contentColor}
                            onChange={(e) => setContentColorFunc(e)}
                          />
                          <button
                            type="button"
                            style={{ marginTop: "10px", marginLeft: "80px" }}
                            className="btn btn-primary"
                            onClick={selectContentColor}
                          >
                            OK{" "}
                          </button>
                        </div>
                        <div style={{ marginTop: "160px" }} className="col-6">
                          <input
                            type="text"
                            className="form-control"
                            value={selectedContentColor.toString()}
                            disabled
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mt-5">
                      <div className="col-md-6 mb-3">
                        <h4>Header Logo</h4>
                        <label className="form-label">
                          Logo (Best Fit Size: height: 90px; width: 237px;)
                        </label>
                        <input
                          ref={logoRef}
                          type="file"
                          accept="image/jpg, image/png, image/gif, image/jpeg"
                        />
                      </div>

                      <div className="mt-5 col-md-6">
                        <img
                          style={{
                            height: "200px",
                            width: "300px",
                            objectFit: "contain",
                          }}
                          src={logo}
                          alt="Uploaded Photograph"
                          accept=".jpg,.jpeg,.png,.gif"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    style={{ marginTop: "70px" }}
                    className="d-flex justify-content-center"
                  >
                    <button
                      onClick={(e) => onSubmit(e)}
                      type="button"
                      className="btn btn-primary "
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <ToastContainer />
    </>
  );
};

export default Branding;
