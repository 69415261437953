import React, { useEffect, useState } from "react";
import {
  deleteGeoColor,
  getGeoColor,
  updateGeoColor,
} from "../../../../services/api";
import { toastError, toastSuccess } from "../../../../services/ToastService";
import { ToastContainer } from "react-toastify";
import { SketchPicker } from "react-color";

const GeoColor = () => {
  const [isCollapsed, setIsCollapsed] = useState([]);
  const [geoColors, setGeoColors] = useState({
    geoColor: [
      {
        color: "",
      },
    ],
  });

  const getColor = async () => {
    try {
      let res = await getGeoColor();

      if (res.data[0]) {
        setGeoColors((previousState) => ({
          ...previousState,
          geoColor: res.data[0].geocolor,
        }));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (index, event) => {
    setGeoColors((prevState) => {
      const updatedColor = [...prevState.geoColor];
      updatedColor[index]["color"] = event.hex;
      return { ...prevState, geoColor: updatedColor };
    });
  };

  const handleAddColor = () => {
    setGeoColors({
      ...geoColors,
      geoColor: [
        ...geoColors.geoColor,
        {
          color: "",
        },
      ],
    });
  };

  const handleRemoveColor = async (index, color) => {
    if (color._id && color._id !== "") {
      await deleteGeoColor(color._id);

      removeColor(index);
    } else {
      removeColor(index);
    }
  };

  const removeColor = (index) => {
    const updatedGeoColor = [...geoColors.geoColor];
    updatedGeoColor.splice(index, 1);
    setGeoColors({
      ...geoColors,
      geoColor: updatedGeoColor,
    });
  };

  useEffect(() => {
    setIsCollapsed(new Array(geoColors.geoColor.length).fill(true));
  }, [geoColors.geoColor.length]);

  useEffect(() => {
    getColor();
  }, []);

  const toggleCollapse = (index) => {
    setIsCollapsed((prevState) =>
      prevState.map((state, i) => (i === index ? !state : state))
    );
  };

  const handleSaveColor = async (index, e) => {
    e.preventDefault();
    try {
      if (geoColors) {
        const formData = new FormData();
        formData.append("color", geoColors.geoColor[index].color);
        formData.append("id", geoColors.geoColor[index]._id);
        await updateGeoColor(formData, geoColors.geoColor[index]._id);
      }
      toastSuccess();
    } catch (error) {
      console.log(error);
      toastError();
    }
  };

  return (
    <main className="main">
      <div className="pagetitle">
        <h1>The Journey Colors</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="index.html">Auth</a>
            </li>
            <li className="breadcrumb-item">Components</li>
            <li className="breadcrumb-item active">The Journey Colors</li>
          </ol>
        </nav>
      </div>
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                {geoColors.geoColor.map((colour, index) => (
                  <React.Fragment key={index}>
                    <div className="parameters-container">
                      <div
                        className="parameters-header"
                        onClick={() => toggleCollapse(index)}
                      >
                        <span className="parameters-title">
                          {index + 1}. Journey Colors
                        </span>
                        <div className="icon-containers">
                          <button className="parameters-toggle-btn">
                            <i
                              onClick={handleAddColor}
                              class="bi bi-plus-lg"
                            ></i>
                          </button>
                          <button className="parameters-toggle-btn">
                            <i
                              onClick={() =>
                                handleRemoveColor(
                                  index,
                                  geoColors.geoColor[index],
                                  colour
                                )
                              }
                              class="bi bi-trash3"
                            ></i>
                          </button>
                          <button
                            className="parameters-toggle-btn"
                            id={`toggleButton${index}`}
                          >
                            {isCollapsed[index] ? "\u25BC" : "\u25B2"}
                          </button>
                        </div>
                      </div>
                      <div
                        className="parameters-body"
                        id={`parametersBody${index}`}
                        style={{
                          display: isCollapsed[index] ? "none" : "block",
                        }}
                      >
                        <div className=" border border-dark rounded">
                          <div className="d-flex mt-3 container">
                            <h5 className="card-title">
                              {index + 1}. Journey Colors
                            </h5>
                            <hr className="border-2" />
                            <div className=" justify-content-end">
                              <button
                                onClick={(e) => handleSaveColor(index, e)}
                                className="btn btn-primary"
                              >
                                Save
                              </button>
                            </div>
                          </div>
                          <div className=" row m-2 ">
                            <div className="col-6">
                              <SketchPicker
                                color={colour.color}
                                onChange={(e) => handleInputChange(index, e)}
                              />
                            </div>
                          </div>

                          <div className="m-3 d-flex justify-content-end  ">
                            <button
                              onClick={() =>
                                handleRemoveColor(
                                  index,
                                  geoColors.geoColor[index],
                                  colour
                                )
                              }
                              className="btn btn-primary"
                            >
                              Remove Journey Colors
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <br />
                  </React.Fragment>
                ))}

                <div className="mt-3 mb-3 d-flex justify-content-end">
                  <button className="btn btn-primary" onClick={handleAddColor}>
                    Add Journey Colors
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </main>
  );
};

export default GeoColor;
