import React, { useEffect, useState } from "react";
import {
  getDonationDetails,
  getDonationUserDetails,
} from "../../../../services/api";
import { toastError } from "../../../../services/ToastService";

const DonationDetailPage = () => {
  const [users, setUsers] = useState([
    {
      purchase_order_id: "",
      customer_info: {
        name: "",
        email: "",
        phone: "",
      },
    },
  ]);
  const [donationDetail, setDonationDetail] = useState([
    {
      pidx: "",
      total_amount: 0,
      status: "",
      transaction_id: "",
      fee: "",
      refunded: "",
    },
  ]);

  useEffect(() => {
    const getDetails = async () => {
      try {
        let userDetail = await getDonationUserDetails();
        if (userDetail.data) {
          setUsers(userDetail.data);
        }
        let donationDetail = await getDonationDetails();
        if (donationDetail.data) {
          setDonationDetail(donationDetail.data);
        }
      } catch (error) {
        console.error("Error fetching slider content:", error);
        toastError();
      }
    };
    getDetails();
  }, []);

  return (
    <>
      <main className="main">
        <div className="pagetitle">
          <h1>Donation Detail</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="index.html">Auth</a>
              </li>
              <li className="breadcrumb-item">Components</li>
              <li className="breadcrumb-item active">Donation Detail</li>
            </ol>
          </nav>
        </div>
        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="table-responsive">
                      <table className="table table-striped">
                        <thead>
                          <tr>
                            <th>pidx</th>
                            <th>Transation Id</th>
                            <th>Purchase Id</th>
                            <th>Amount (Rs)</th>
                          </tr>
                        </thead>
                        <tbody>
                          {donationDetail &&
                            donationDetail
                              .map((detail) => {
                                if (detail) {
                                  return (
                                    <tr key={detail._id}>
                                      <td>{detail.pidx}</td>
                                      <td>{detail.transaction_id}</td>
                                      <td>{detail.purchase_order_id}</td>
                                      <td>
                                        {detail.total_amount.toFixed(2) / 100}
                                      </td>
                                    </tr>
                                  );
                                }
                                return null;
                              })
                              .filter((item) => item !== null)}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
};

export default DonationDetailPage;
