import React from "react";
import { useSpring, animated } from "react-spring";

const NumberCounter = ({ n, animate }) => {
  const { number } = useSpring({
    from: { number: 0 },
    number: animate ? n : 0,
    delay: 100,
    config: { mass: 1, tension: 20, friction: 10 },
  });
  return (
    <animated.span className="our-impact-number">
      {number.to((n) => n.toFixed(0))}
    </animated.span>
  );
};

export default NumberCounter;
