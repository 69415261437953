import "./App.css";

import "./components/auth/admin/section/NewLetter/popup/customModal.css";
import "react-toastify/dist/ReactToastify.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import Routes from "./router/index";
import { getBranding } from "./services/api";
import { toastError } from "./services/ToastService";
import { useEffect } from "react";
function App() {
  const fetchData = async () => {
    try {
      const branding = await getBranding();

      if (branding.data && branding.data.length > 0) {
        const barColor = branding.data[0].barColor;
        const BarColor = barColor && barColor !== "" ? barColor : "#768fbe";

        const contentColor = branding.data[0].contentColor;
        const ContentColor =
          contentColor && contentColor !== "" ? contentColor : "#00419a";

        document.documentElement.style.setProperty("--light-blue", BarColor);
        document.documentElement.style.setProperty("--blue", ContentColor);
      } else {
        document.documentElement.style.setProperty("--light-blue", "#768fbe");
        document.documentElement.style.setProperty("--blue", "#00419a");
      }
    } catch (error) {
      console.error("Error fetching content:", error);
      toastError();
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Routes />
    </>
  );
}

export default App;
