import React, { useEffect, useState } from "react";
import {
  deletePublication,
  getAllPublications,
  updatePublication,
} from "../../../../services/api";
import { ToastContainer } from "react-toastify";
import { toastError, toastSuccess } from "../../../../services/ToastService";

const PublicationAdmin = () => {
  const [isCollapsed, setIsCollapsed] = useState([]);
  const [formsField, setformsField] = useState({
    publication: [
      {
        _id: "",
        title: "",
        pdf: "",
      },
    ],
  });

  const getAllPublication = async () => {
    try {
      let res = await getAllPublications();
      if (res.data) {
        res.data.forEach((item) => {
          if (item.locale === "") {
            setformsField((prevState) => ({
              ...prevState,
              publication: item.publication,
            }));
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    setformsField((prevState) => {
      const updatedPublication = [...prevState.publication];
      updatedPublication[index][name] = value;
      return { ...prevState, publication: updatedPublication };
    });
  };

  const handleAddPublication = () => {
    setformsField({
      ...formsField,
      publication: [
        ...formsField.publication,
        {
          _id: "",
          pdf: "",
          title: "",
        },
      ],
    });
  };

  const handleRemovePublication = async (index, publication) => {
    if (publication._id && publication._id !== "") {
      await deletePublication(publication._id);

      removePublication(index);
    } else {
      removePublication(index);
    }
  };

  const removePublication = (index) => {
    const updatedPublication = [...formsField.publication];
    updatedPublication.splice(index, 1);
    setformsField({
      ...formsField,
      publication: updatedPublication,
    });
  };

  const handlePdfChange = (index, event) => {
    const file = event.target.files[0];

    setformsField((prevState) => {
      const updatedPublication = [...prevState.publication];
      updatedPublication[index].pdf = file;
      return { ...prevState, publication: updatedPublication };
    });
  };

  useEffect(() => {
    setIsCollapsed(new Array(formsField.publication.length).fill(true));
  }, [formsField.publication.length]);

  useEffect(() => {
    getAllPublication();
  }, []);

  const toggleCollapse = (index) => {
    setIsCollapsed((prevState) =>
      prevState.map((state, i) => (i === index ? !state : state))
    );
  };

  const handleSavePublication = async (index, e) => {
    e.preventDefault();
    try {
      if (formsField) {
        const formData = new FormData();
        formData.append("title", formsField.publication[index].title);
        formData.append("pdf", formsField.publication[index].pdf);
        formData.append("id", formsField.publication[index]._id);
        await updatePublication(formData);
      }
      toastSuccess();
    } catch (error) {
      console.log(error);
      toastError();
    }
  };

  return (
    <main className="main">
      <div className="pagetitle">
        <h1>Publication</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="index.html">Auth</a>
            </li>
            <li className="breadcrumb-item">Components</li>
            <li className="breadcrumb-item active">Publication</li>
          </ol>
        </nav>
      </div>
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                {formsField.publication.map((publications, index) => (
                  <React.Fragment key={index}>
                    <div className="parameters-container">
                      <div
                        className="parameters-header"
                        onClick={() => toggleCollapse(index)}
                      >
                        <span className="parameters-title">
                          {index + 1}. Publication
                        </span>
                        <div className="icon-containers">
                          <button className="parameters-toggle-btn">
                            <i
                              onClick={handleAddPublication}
                              class="bi bi-plus-lg"
                            ></i>
                          </button>
                          <button className="parameters-toggle-btn">
                            <i
                              onClick={() =>
                                handleRemovePublication(index, publications)
                              }
                              class="bi bi-trash3"
                            ></i>
                          </button>
                          <button
                            className="parameters-toggle-btn"
                            id={`toggleButton${index}`}
                          >
                            {isCollapsed[index] ? "\u25BC" : "\u25B2"}
                          </button>
                        </div>
                      </div>
                      <div
                        className="parameters-body"
                        id={`parametersBody${index}`}
                        style={{
                          display: isCollapsed[index] ? "none" : "block",
                        }}
                      >
                        <div className=" border border-dark rounded">
                          <div className="d-flex mt-3 container">
                            <h5 className="card-title">
                              {index + 1}. Publication
                            </h5>
                            <hr className="border-2" />
                            <div className=" justify-content-end">
                              <button
                                onClick={(e) => handleSavePublication(index, e)}
                                className="btn btn-primary"
                              >
                                Save
                              </button>
                            </div>
                          </div>
                          <div className=" row m-2 ">
                            <div className="col-md-6 mb-3">
                              <label className="form-label">PDF</label>
                              <input
                                type="file"
                                accept="application/pdf"
                                onChange={(e) => handlePdfChange(index, e)}
                              />
                            </div>

                            <div className=" mb-3 col-md-6">
                              <iframe
                                style={{
                                  height: "200px",
                                  width: "300px",
                                  objectFit: "contain",
                                }}
                                src={publications.pdf}
                                alt="Uploaded File"
                              />
                            </div>

                            <div className="col-md-12">
                              <hr className="border-2" />
                              <div className=" mb-3">
                                <label className="form-label">Title</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={publications.title}
                                  name="title"
                                  onChange={(e) => handleInputChange(index, e)}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="m-3 d-flex justify-content-end  ">
                            <button
                              onClick={() =>
                                handleRemovePublication(index, publications)
                              }
                              className="btn btn-primary"
                            >
                              Remove Publication
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <br />
                  </React.Fragment>
                ))}

                <div className="mt-3 mb-3 d-flex justify-content-end">
                  <button
                    className="btn btn-primary"
                    onClick={handleAddPublication}
                  >
                    Add Publication
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </main>
  );
};

export default PublicationAdmin;
