import React, { useEffect, useState } from "react";
import { getAllPublications } from "../../services/api";
import { NavLink } from "react-router-dom";

const PublicationHome = () => {
  const [formsField, setformsField] = useState({
    publication: [
      {
        _id: "",
        title: "",
        pdf: "",
      },
    ],
  });
  const getAllPublication = async () => {
    try {
      let res = await getAllPublications();
      if (res.data) {
        res.data.forEach((item) => {
          if (item.locale === "") {
            setformsField((prevState) => ({
              ...prevState,
              publication: item.publication,
            }));
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getAllPublication();
  }, []);
  return (
    <>
      <div class="container">
        <div class="row mt-4">
          {formsField.publication.length > 0 ? (
            formsField.publication.map((pub) => (
              <div key={pub._id} className="col-12 col-md-4 col-xl-2 ml-4 ">
                <div className=" d-flex flex-column align-items-center">
                  <div className="pdf-card ">
                    <div className="pdf-frame-container">
                      <embed
                        src={pub.pdf ? pub.pdf : ""}
                        title={pub.title}
                        type="application/pdf"
                        className="pdf-frame"
                      ></embed>
                    </div>
                    <div className="card-info">
                      <NavLink to={`/publication/${pub._id}`}>
                        <h3 className="font-weight-bold text-blue">
                          {pub.title}
                        </h3>
                      </NavLink>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>No form fields to display</p>
          )}
        </div>
      </div>
    </>
  );
};

export default PublicationHome;
