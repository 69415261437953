import React, { useEffect, useRef, useState } from "react";
import { toastError, toastSuccess } from "../../../../../services/ToastService";
import { ToastContainer } from "react-toastify";
import JoditEditor from "jodit-react";
import {
  englishConfig,
  nepaliConfig,
} from "../../../../../services/joditConfigService";
import {
  getAllPrivacyPolicy,
  saveEnglishPrivacyPolicy,
  saveNepaliPrivacyPolicy,
} from "../../../../../services/api";

const PrivacyPolicy = () => {
  const englishEditor = useRef(null);
  const nepaliEditor = useRef(null);

  const [englishFormContent, setEnglishFormContent] = useState({
    content:
      "We ensure that persons with mental health conditions and psychosocial disabilities are included in the community and that they are not isolated or segregated from it.",
  });

  const [nepaliFormContent, setNepaliFormContent] = useState({
    content:
      "हामी सुनिश्चित गर्छौं कि मानसिक स्वास्थ्य अवस्था र मनोसामाजिक अपाङ्गता भएका व्यक्तिहरूलाई समुदायमा समावेश गरिएको छ र उनीहरूलाई यसबाट अलग वा अलग गरिएको छैन।",
  });

  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      if (englishFormContent) {
        await saveEnglishPrivacyPolicy(englishFormContent);
      }
      if (nepaliFormContent) {
        await saveNepaliPrivacyPolicy(nepaliFormContent);
      }
      toastSuccess();
    } catch (error) {
      console.error("Error saving slider content:", error);
      toastError();
    }
  };

  const getallPrivacyPolicy = async () => {
    try {
      let res = await getAllPrivacyPolicy();
      if (res.data) {
        res.data.forEach((item) => {
          if (item.locale === "eng") {
            setEnglishFormContent((prevState) => ({
              ...prevState,
              content: item.content,
            }));
          } else if (item.locale === "nep") {
            setNepaliFormContent((prevState) => ({
              ...prevState,
              content: item.content,
            }));
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getallPrivacyPolicy();
  }, []);
  return (
    <>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Privacy Policy</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="index.html">Auth</a>
              </li>
              <li className="breadcrumb-item">Components</li>
              <li className="breadcrumb-item active">Privacy Policy </li>
            </ol>
          </nav>
        </div>
        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title"> Privacy Policy Page </h5>
                  <hr className="border-2" />

                  <h5 className="card-title text-center">English Editor</h5>
                  <hr className="border-2" />
                  <div>
                    <JoditEditor
                      ref={englishEditor}
                      value={englishFormContent.content}
                      tabIndex={1}
                      config={englishConfig}
                      onBlur={(newContent) =>
                        setEnglishFormContent((prevState) => ({
                          ...prevState,
                          content: newContent,
                        }))
                      }
                    />
                  </div>
                  <br />
                  <br />
                  <br />

                  <h5 className="card-title text-center">Nepali Editor</h5>
                  <hr className="border-2" />
                  <div>
                    <JoditEditor
                      ref={nepaliEditor}
                      value={nepaliFormContent.content}
                      tabIndex={1}
                      config={nepaliConfig}
                      onBlur={(newContent) =>
                        setNepaliFormContent((prevState) => ({
                          ...prevState,
                          content: newContent,
                        }))
                      }
                    />
                  </div>

                  <div
                    style={{ marginTop: "50px" }}
                    className="d-flex justify-content-center"
                  >
                    <button
                      onClick={onSubmit}
                      type="submit"
                      className="btn btn-primary "
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <ToastContainer />
    </>
  );
};

export default PrivacyPolicy;
