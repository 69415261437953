import React, { useEffect, useState } from "react";
import newsImage from "../../../assets/img/news-1.png";
import { getNews } from "../../../services/api";
import { NavLink } from "react-router-dom";

function LatestNewsViewAll() {
  const [locale, setLocale] = useState("eng");
  const [latestNews, setLatestNews] = useState(null);

  useEffect(() => {
    let locale = localStorage.getItem("locale") || "eng";
    setLocale(locale);

    const fetchData = async () => {
      try {
        const res = await getNews();

        if (res) {
          setLatestNews(res.data);
        }
      } catch (error) {
        console.error("Error fetching news:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <section className="news-section mt-5">
        <div className="container">
          <h2 className="heading text-center text-blue">
            {locale === "eng" ? "Latest News" : "नवीनतम समाचार"}
          </h2>
          <div className="row">
            {latestNews &&
              latestNews.news
                .slice()
                .reverse()
                .map((item, index) => (
                  <div key={index} className="col-lg-4 col-md-6 mb-4">
                    <div className="news-card text-left">
                      <div className="position-relative text-center">
                        <img
                          src={item.image || newsImage}
                          alt={`news ${index}`}
                          className="img-fluid"
                        />
                        <div className="news-date d-flex flex-column">
                          <h2>{item.day || "14"}</h2>
                          <h4 className="mt-n2">{item.month || "Jan"}</h4>
                        </div>
                      </div>
                      <h3 className="font-weight-bold text-blue mt-5">
                        {item.title ||
                          "Empowering Communities Through Education"}
                      </h3>
                      <p className="">
                        {item.contentDescription ||
                          "Our organization has been actively involved in providing education to underprivileged communities, thereby empowering them to create a better future for themselves."}
                      </p>
                      <NavLink
                        to={`/latestnews/readMore/${item._id}`}
                        className="text-blue mb-2"
                      >
                        {locale === "eng" ? "Read More" : "थप पढ्नुहोस्"}{" "}
                        <i className="fas fa-circle-arrow-right ml-2"></i>
                      </NavLink>
                    </div>
                  </div>
                ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default LatestNewsViewAll;
