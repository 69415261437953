const URLS = {
  BASE_URL: "https://koshish.org.np",
  // BASE_URL: "http://103.233.58.121:5000",
  // BASE_URL: "http://localhost:5000",
  // HOST_URL: "http://localhost:3000",
  // HOST_URL: "http://103.233.58.121:3000",
  HOST_URL: "https://koshish.org.np",
};

export default URLS;
