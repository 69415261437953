import React from "react";
import { NavLink } from "react-router-dom";

function AdminHeader() {
  const toggleSideBar = (event) => {
    event.preventDefault();
    const body = document.querySelector("body");
    if (body) {
      body.classList.toggle("toggle-sidebar");
    }
  };

  return (
    <>
      <header
        id="ikhataHeader"
        className="ikhataHeader adminHeader fixed-top d-flex align-items-center"
      >
        <div className="d-flex align-items-center justify-content-between">
          <NavLink className="logoIkhata d-flex align-items-center">
            <img
              src="https://www.koshishnepal.org/wp-content/uploads/2021/09/cropped-main-koshish-1.png"
              alt=""
            />
            <span className="d-none d-lg-block">Koshish Admin</span>
          </NavLink>
          <i
            onClick={toggleSideBar}
            className="bi bi-list toggle-sidebar-btn"
          ></i>
        </div>

        <nav className="ikhataHeader-nav ms-auto">
          <ul className="d-flex align-items-center">
            <li className="nav-item dropdown pe-3">
              <NavLink
                className="nav-link nav-profile d-flex align-items-center pe-0"
                to="/"
                data-bs-toggle="dropdown"
              >
                <img
                  src="https://www.koshishnepal.org/wp-content/uploads/2021/09/cropped-main-koshish-1.png"
                  alt="Profile"
                  className="rounded-circle"
                />
                <span className="d-none d-md-block dropdown-toggle ps-2">
                  Koshish Admin
                </span>
              </NavLink>

              <ul className="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                <li style={{ textAlign: "center" }} className="dropdown-Header">
                  <h6>Koshish Admin</h6>
                </li>
                <li>
                  <hr className="dropdown-divider" />
                </li>

                <li>
                  <NavLink
                    className="dropdown-item d-flex align-items-center"
                    to="/"
                  >
                    <i className="bi bi-box-arrow-right"></i>
                    <span>Sign Out</span>
                  </NavLink>
                </li>
              </ul>
            </li>
          </ul>
        </nav>
      </header>
    </>
  );
}

export default AdminHeader;
