import React, { useEffect, useState } from "react";
import Accessibility from "../Accessibility";
import { getOurPartner } from "../../../services/api";
import { useParams } from "react-router-dom";

const OurPartnerReadMore = () => {
  const [ourPartner, setOurPartner] = useState("");
  let { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getOurPartner();
        setTimeout(() => {
          if (res.data) {
            let ourPartner = res.data.partner.find(
              (item) => item._id.toString() === id.toString()
            );
            if (ourPartner) {
              setOurPartner(ourPartner);
            } else {
              setOurPartner(res.data.partner[0]);
            }
          }
        });
      } catch (error) {}
    };

    fetchData();
  }, []);

  return (
    <>
      <section className="about-us-section ">
        <Accessibility />

        <div>
          <div dangerouslySetInnerHTML={{ __html: ourPartner.content }} />
        </div>
      </section>
    </>
  );
};

export default OurPartnerReadMore;
