import React, { useEffect, useState, useRef } from "react";
import { donateUs } from "../../services/NewsLetterUserService";
import { ToastContainer, toast } from "react-toastify";
import { Link, NavLink, Router, useNavigate } from "react-router-dom";
import logo from "../../assets/img/logo.png";
import coverImg from "../../assets/img/cover.jpg";
import {
  getAboutUsImages,
  getBranding,
  getGetInvolvedImages,
  getNavbarText,
  getOurWorkImages,
  getPopupPage,
  getResourcesImages,
  getSearchDetails,
  getSocialLinks,
} from "../../services/api";
import PopUp from "../shared/CustomModal/PopUp";
import { getFooter } from "../../services/footerService";
import "./Header.css";
import MoonLoader from "react-spinners/MoonLoader";

export default function Header() {
  const [locale, setlocale] = useState("EN");
  const [aboutUsImages, setAboutUsImages] = useState();
  const [ourWorkImage, setOurWorkImage] = useState();
  const [resourcesImages, setResourcesImages] = useState();
  const [getInvolved, setGetInvolved] = useState();
  const [socialLinks, setSocialLinks] = useState();
  const [showModal, setShowModal] = useState(false);
  const [footerData, setFooterData] = useState();
  const [headerLogo, setHeaderLogo] = useState();
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [isNavbarHidden, setIsNavbarHidden] = useState(false); // State to track navbar visibility
  const [navbarTexts, setNavbarTexts] = useState();
  const navigate = useNavigate();
  const [searchInput, setSearchInput] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [searchClicked, setSearchClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let res = await getAboutUsImages();
        if (res.data) {
          setAboutUsImages(res.data);
        }

        let ourWorkImages = await getOurWorkImages();
        if (ourWorkImages.data) {
          setOurWorkImage(ourWorkImages.data);
        }

        let resources = await getResourcesImages();
        if (resources.data) {
          setResourcesImages(resources.data);
        }

        let getInvolved = await getGetInvolvedImages();
        if (getInvolved.data) {
          setGetInvolved(getInvolved.data);
        }

        let socialLinks = await getSocialLinks();
        if (socialLinks.data) {
          setSocialLinks(socialLinks.data);
        }

        let branding = await getBranding();
        if (branding.data) {
          setHeaderLogo(branding.data[0].logo);
        }

        let popup = await getPopupPage();

        if (popup.data.selectedData) {
          if (
            popup.data.selectedData.showPopup &&
            popup.data.selectedData.showPopup === "true"
          ) {
            setTimeout(() => {
              setShowModal(true);
            }, 1000);
          }
        }

        let footer = await getFooter();
        setFooterData(footer.data.data);

        let navbarTextData = await getNavbarText();
        setNavbarTexts(navbarTextData.data);
      } catch (error) {
        console.error("Error fetching slider content:", error);
      }
    };
    fetchData();

    let locale = localStorage.getItem("locale")
      ? localStorage.getItem("locale") === "eng"
        ? "EN"
        : "NP"
      : "EN";
    setlocale(locale);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = document.documentElement.scrollTop;
      if (scrollTop > lastScrollTop) {
        setIsNavbarHidden(true); // Hide navbar on scroll down
      } else if (lastScrollTop - scrollTop > 100) {
        setIsNavbarHidden(false); // Show navbar on scroll up by more than 100 pixels
      }
      setLastScrollTop(scrollTop <= 0 ? 0 : scrollTop);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [lastScrollTop]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const payViaKhalti = async (amountInfo) => {
    const formData = {
      return_url: "http://localhost:5000/api/newsLetter/donateUs",
      website_url: "http://localhost:3000/",
      amount: 5000,
      purchase_order_id: "Order01",
      purchase_order_name: "test",
      customer_info: "",
    };
    const data = await donateUs(formData);
    toast.success(`going to payment page`, {
      position: "top-center",
      autoClose: 700,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: 0,
      theme: "colored",
    });
    window.location.to = `${data.responseData.payment_url}`;
  };

  const [isModalOpen, setModalOpen] = useState(false);

  const enterAmount = (amount) => {
    toast.info(` redirecting to payment page  `, {
      position: "top-center",
      autoClose: 700,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: 0,
      theme: "colored",
    });
    setModalOpen(false);
    payViaKhalti(amount);
  };

  const onLanguageOptionClicked = (event) => {
    event.preventDefault();
    const isClickInside = document
      .getElementById("language-btn")
      .contains(event.target);
    const isClickOutside = document
      .getElementById("language-dropdown")
      .contains(event.target);
    if (isClickInside) {
      const x = document.getElementById("language-dropdown");
      if (x.style.display === "block") {
        x.style.display = "none";
      } else {
        x.style.display = "block";
      }
    }
    if (isClickOutside) {
      const x = document.getElementById("language-dropdown");
      if (x.style.display === "block") {
        x.style.display = "none";
      } else {
        x.style.display = "block";
      }
    }
  };

  const changeLocale = (event) => {
    event.preventDefault();
    const x = document.getElementById("language-dropdown");
    x.style.display = "none";
    if (event.target.id === "nep") {
      setlocale("NP");
    } else {
      setlocale("EN");
    }
    localStorage.locale = event.target.id;
    window.location.reload();
  };

  const handleToggle = (event) => {
    event.preventDefault();
    const isClickInside = document
      .getElementById("hamburger-menu")
      .contains(event.target);
    if (isClickInside) {
      const x = document.getElementById("main-menu");
      if (x.style.display === "block") {
        x.style.display = "none";
      } else {
        x.style.display = "block";
      }
    }
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const hideMenu = (e, id, route) => {
    e.preventDefault();
    let submenu = document.getElementById(id);
    if (submenu) {
      submenu.style.display = "none";
      navigate(route);
      if (isMobile) {
        const x = document.getElementById("main-menu");
        if (x.style.display === "block") {
          x.style.display = "none";
        } else {
          x.style.display = "block";
        }
      }

      setTimeout(() => {
        submenu.removeAttribute("style");
      }, 1000);
    }
  };

  const handleInputChange = async (e) => {
    e.preventDefault();
    setLoading(true);
    setSearchClicked(true); // Show the search results div immediately

    try {
      let res = await getSearchDetails(searchInput);
      if (res) {
        setSearchData(res.data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleCancelClick = () => {
    setSearchInput("");
    setSearchClicked(false);
  };

  return (
    <>
      <style>
        {`
          @keyframes l3 {
            100% {
              transform: scale(50);
              opacity: 0;
            }
          }
        `}
      </style>
      {showModal && <PopUp onCloseModal={closeModal} />}
      <header
        className={`header-main ${isNavbarHidden ? "hidden" : "visible"}`}
        id="header"
      >
        <div className="font-size-16">
          <div className="topbar">
            <div className=" container py-1 d-flex align-items-center flex-wrap">
              <div className="topbar-item  px-3">
                <NavLink to="/needhelp">
                  <i className="fas fa-phone"></i>{" "}
                  {locale === "EN" ? "Need Help" : "मदद चाहिए"}?{" "}
                  <span className="font-weight-bold">
                    {locale === "EN" ? "Call" : "कल"}:{" "}
                    {locale === "EN" && footerData
                      ? footerData.tollFreePhone
                      : footerData && footerData.tollFreePhoneNepali}
                  </span>
                </NavLink>
              </div>
              <div className="d-flex align-items-center">
                <div className="topbar-item  px-3">
                  <NavLink to="/mentalHealth">
                    {navbarTexts &&
                      navbarTexts.headerText &&
                      navbarTexts.headerText.headerText1}
                  </NavLink>
                </div>
                <div className="topbar-item  px-3">
                  <NavLink to="/resources/blog">
                    {locale === "EN" ? "Blog" : "ब्लग"}
                  </NavLink>
                </div>
              </div>
              <div className="flex-fill"></div>
              <div className="d-flex align-items-center">
                <div className="topbar-item px-3 header-search-wrapper">
                  <div className="search-bar search-component">
                    <form
                      className="search-form d-flex align-items-center"
                      method="POST"
                      action="#"
                    >
                      <input
                        type="search"
                        name="query"
                        placeholder="Search..."
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                        className="topbar header-search-input"
                      />
                      <button
                        className="search-btn"
                        type="submit"
                        title="Search"
                        onClick={(e) => handleInputChange(e)}
                      >
                        <i className="fas fa-search"></i>
                      </button>
                    </form>
                    {searchClicked && (
                      <div className="modal-overlay">
                        <div className="results-container">
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                          >
                            <h6 style={{ color: "#768fbe" }}>Search Results</h6>
                            <button
                              onClick={handleCancelClick}
                              style={{
                                backgroundColor: "transparent",
                                border: "none",
                                color: "#768fbe",
                                cursor: "pointer",
                              }}
                            >
                              <i className="fas fa-times"></i>
                            </button>
                          </div>
                          {loading ? (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <MoonLoader
                                color="#0e6afe"
                                cssOverride={{}}
                                size={30}
                                speedMultiplier={1}
                              />
                              <span
                                style={{ color: "#768fbe", marginLeft: "8px" }}
                              >
                                Searching Please Wait...
                              </span>
                            </div>
                          ) : (
                            <>
                              {searchData.length > 0 ? (
                                searchData.map((item, index) => (
                                  <div key={index} className="result-item">
                                    <NavLink
                                      onClick={handleCancelClick}
                                      to={item.link}
                                    >
                                      <span>{item.searchQuery}</span>
                                      <span style={{ marginLeft: "50px" }}>
                                        {item.link}
                                      </span>
                                    </NavLink>
                                  </div>
                                ))
                              ) : (
                                <div
                                  style={{ color: "#00419a" }}
                                  className="result-item"
                                >
                                  Match not found!
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="topbar-item px-3">
                  <div onClick={onLanguageOptionClicked} id="language-btn">
                    <span>{locale} </span> <i className="fas fa-angle-down"></i>
                  </div>
                  <div id="language-dropdown">
                    <div>
                      <NavLink name="english" id="eng" onClick={changeLocale}>
                        EN
                      </NavLink>
                    </div>
                    <div>
                      <NavLink name="nepali" id="nep" onClick={changeLocale}>
                        NP
                      </NavLink>
                    </div>
                  </div>
                </div>
                <div className="social-icons px-3">
                  <Link
                    to={
                      socialLinks && socialLinks.facebook
                        ? socialLinks.facebook
                        : ""
                    }
                  >
                    <i className="fab fa-facebook-f pr-3"></i>
                  </Link>
                  <Link
                    to={
                      socialLinks && socialLinks.twitter
                        ? socialLinks.twitter
                        : ""
                    }
                  >
                    <i className="fab fa-x-twitter pr-3"></i>
                  </Link>
                  <Link
                    to={
                      socialLinks && socialLinks.instagram
                        ? socialLinks.instagram
                        : ""
                    }
                  >
                    <i className="fab fa-instagram pr-3"></i>
                  </Link>

                  <Link
                    to={
                      socialLinks && socialLinks.linkedIn
                        ? socialLinks.linkedIn
                        : ""
                    }
                  >
                    <i className="fab fa-linkedin pr-3"></i>
                  </Link>

                  <Link
                    to={
                      socialLinks && socialLinks.youtube
                        ? socialLinks.youtube
                        : ""
                    }
                  >
                    <i className="fab fa-youtube"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container d-flex align-items-center justify-content-between">
          <div className="strip d-flex justify-content-between py-3 bg-light">
            <Link to="/">
              <img
                src={headerLogo && headerLogo !== "" ? headerLogo : logo}
                alt="logo"
                className="header-logo"
                style={{ height: "90px", width: "230px" }}
              />
            </Link>
          </div>
          <div className="right-section-header py-2 ">
            <div className="d-flex align-items-center donation-icon-div">
              <NavLink to="/donation">
                <div className="heart-button">
                  <div className="loader"></div>
                  <div className="heart-button-text">
                    {locale === "EN" ? "Donate" : "दान गर्नुहोस्"}
                  </div>
                </div>
              </NavLink>
              <div
                onClick={handleToggle}
                className="hamburger-menu ml-3"
                id="hamburger-menu"
              >
                <i className="fas fa-bars"></i>
              </div>
            </div>

            <nav className="navbar-main mt-2" id="main-menu">
              <div className="navbar-main-menu d-flex justify-content-center">
                <div className="navbar-main-item">
                  <NavLink to="/" className="navbar-main-link">
                    <i className="fas fa-home"></i>
                  </NavLink>
                </div>
                <div className="navbar-main-item">
                  <NavLink to="#" className="navbar-main-link  menuLink">
                    <label>
                      {" "}
                      {navbarTexts &&
                        navbarTexts.mainMenuText.menu1 &&
                        navbarTexts.mainMenuText.menu1}
                    </label>

                    <i className="fa fa-angle-down text-blue-grey"></i>
                  </NavLink>
                  <div id="aboutus-submenu" className="submenu p-4">
                    <div className="container">
                      <div className="row">
                        <div id="history" className="col-6 col-md-3 nav-item">
                          <NavLink
                            to="/about-us/history"
                            onClick={(e) =>
                              hideMenu(
                                e,
                                "aboutus-submenu",
                                "/about-us/history"
                              )
                            }
                            className="nav-link"
                          >
                            <img
                              src={
                                aboutUsImages &&
                                aboutUsImages.aboutUsHistory &&
                                aboutUsImages.aboutUsHistory !== ""
                                  ? aboutUsImages.aboutUsHistory
                                  : coverImg
                              }
                              alt="menu img"
                              className="nav-img"
                            />
                            <div className="nav-overlay"></div>
                            <span className="submenu-text">
                              {navbarTexts &&
                                navbarTexts.submenuText.menu1 &&
                                navbarTexts.submenuText.menu1.subMenu1}
                            </span>
                          </NavLink>
                        </div>
                        <div className="col-6 col-md-3 nav-item position-relative">
                          <NavLink
                            to="/about-us/introduction"
                            onClick={(e) =>
                              hideMenu(
                                e,
                                "aboutus-submenu",
                                "/about-us/introduction"
                              )
                            }
                            className="nav-link"
                          >
                            <img
                              src={
                                aboutUsImages &&
                                aboutUsImages.aboutUsIntroduction &&
                                aboutUsImages.aboutUsIntroduction !== ""
                                  ? aboutUsImages.aboutUsIntroduction
                                  : coverImg
                              }
                              alt="menu img"
                              className="nav-img"
                            />
                            <div className="nav-overlay"></div>
                            <span className="submenu-text">
                              {navbarTexts &&
                                navbarTexts.submenuText.menu1 &&
                                navbarTexts.submenuText.menu1.subMenu2}
                            </span>
                          </NavLink>
                        </div>
                        <div className="col-6 col-md-3 nav-item position-relative">
                          <NavLink
                            to="aboutUs/boardCommittees"
                            onClick={(e) =>
                              hideMenu(
                                e,
                                "aboutus-submenu",
                                "/aboutUs/boardCommittees"
                              )
                            }
                            className="nav-link"
                          >
                            <img
                              src={
                                aboutUsImages &&
                                aboutUsImages.aboutUsOurTeam &&
                                aboutUsImages.aboutUsOurTeam !== ""
                                  ? aboutUsImages.aboutUsOurTeam
                                  : coverImg
                              }
                              alt="menu img"
                              className="nav-img"
                            />
                            <div className="nav-overlay"></div>
                            <span className="submenu-text">
                              {navbarTexts &&
                                navbarTexts.submenuText.menu1 &&
                                navbarTexts.submenuText.menu1.subMenu3}
                            </span>
                          </NavLink>
                        </div>

                        <div className="col-6 col-md-3 nav-item position-relative">
                          <NavLink
                            to="/aboutUsOurPartners"
                            className="nav-link"
                            onClick={(e) =>
                              hideMenu(
                                e,
                                "aboutus-submenu",
                                "/aboutUsOurPartners"
                              )
                            }
                          >
                            <img
                              src={
                                aboutUsImages &&
                                aboutUsImages.aboutUsOurPartners &&
                                aboutUsImages.aboutUsOurPartners !== ""
                                  ? aboutUsImages.aboutUsOurPartners
                                  : coverImg
                              }
                              alt="menu img"
                              className="nav-img"
                            />
                            <div className="nav-overlay"></div>
                            <span className="submenu-text">
                              {navbarTexts &&
                                navbarTexts.submenuText.menu1 &&
                                navbarTexts.submenuText.menu1.subMenu4}
                            </span>
                          </NavLink>
                        </div>

                        <div className="col-6 col-md-3 nav-item position-relative">
                          <NavLink
                            to="/aboutUsOurStrategicPlan"
                            className="nav-link"
                            onClick={(e) =>
                              hideMenu(
                                e,
                                "aboutus-submenu",
                                "/aboutUsOurStrategicPlan"
                              )
                            }
                          >
                            <img
                              src={
                                aboutUsImages &&
                                aboutUsImages.strategicPlan &&
                                aboutUsImages.strategicPlan !== ""
                                  ? aboutUsImages.strategicPlan
                                  : coverImg
                              }
                              alt="menu img"
                              className="nav-img"
                            />
                            <div className="nav-overlay"></div>
                            <span className="submenu-text">
                              {navbarTexts &&
                                navbarTexts.submenuText.menu1 &&
                                navbarTexts.submenuText.menu1.subMenu5}
                            </span>
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="navbar-main-item">
                  <NavLink to="#" className="navbar-main-link">
                    <label>
                      {" "}
                      {navbarTexts &&
                        navbarTexts.mainMenuText.menu2 &&
                        navbarTexts.mainMenuText.menu2}
                    </label>
                    <i className="fa fa-angle-down text-blue-grey"></i>
                  </NavLink>
                  <div id="ourwork-submenu" className="submenu p-4">
                    <div className="container">
                      <div className="row">
                        <div className="col-6 col-md-3 nav-item">
                          <NavLink
                            to="ourProgram/advocacyAwarness"
                            className="nav-link"
                            onClick={(e) =>
                              hideMenu(
                                e,
                                "ourwork-submenu",
                                "/ourProgram/advocacyAwarness"
                              )
                            }
                          >
                            <img
                              src={
                                ourWorkImage && ourWorkImage.advocacyAwarness
                                  ? ourWorkImage.advocacyAwarness
                                  : coverImg
                              }
                              alt="menu img"
                              className="nav-img"
                            />
                            <div className="nav-overlay"></div>
                            <span className="submenu-text">
                              {navbarTexts &&
                                navbarTexts.submenuText.menu2 &&
                                navbarTexts.submenuText.menu2.subMenu1}
                            </span>
                          </NavLink>
                        </div>

                        <div className="col-6 col-md-3 nav-item">
                          <NavLink
                            to="ourProgram/empowermentAndCommunityInclusion"
                            className="nav-link"
                            onClick={(e) =>
                              hideMenu(
                                e,
                                "ourwork-submenu",
                                "/ourProgram/empowermentAndCommunityInclusion"
                              )
                            }
                          >
                            <img
                              src={
                                ourWorkImage && ourWorkImage.empowerment
                                  ? ourWorkImage.empowerment
                                  : coverImg
                              }
                              alt="menu img"
                              className="nav-img"
                            />
                            <div className="nav-overlay"></div>
                            <span className="submenu-text">
                              {navbarTexts &&
                                navbarTexts.submenuText.menu2 &&
                                navbarTexts.submenuText.menu2.subMenu2}
                            </span>
                          </NavLink>
                        </div>

                        <div className="col-6 col-md-3 nav-item">
                          <NavLink
                            to="ourProgram/strengthenCommunitySupportSystem"
                            className="nav-link"
                            onClick={(e) =>
                              hideMenu(
                                e,
                                "ourwork-submenu",
                                "/ourProgram/strengthenCommunitySupportSystem"
                              )
                            }
                          >
                            <img
                              src={
                                ourWorkImage && ourWorkImage.support
                                  ? ourWorkImage.support
                                  : coverImg
                              }
                              alt="menu img"
                              className="nav-img"
                            />
                            <div className="nav-overlay"></div>
                            <span className="submenu-text">
                              {navbarTexts &&
                                navbarTexts.submenuText.menu2 &&
                                navbarTexts.submenuText.menu2.subMenu3}
                            </span>
                          </NavLink>
                        </div>

                        <div className="col-6 col-md-3 nav-item">
                          <NavLink
                            to="ourProgram/organizationalDevelopment"
                            className="nav-link"
                            onClick={(e) =>
                              hideMenu(
                                e,
                                "ourwork-submenu",
                                "/ourProgram/organizationalDevelopment"
                              )
                            }
                          >
                            <img
                              src={
                                ourWorkImage && ourWorkImage.orgDevelopment
                                  ? ourWorkImage.orgDevelopment
                                  : coverImg
                              }
                              alt="menu img"
                              className="nav-img"
                            />
                            <div className="nav-overlay"></div>
                            <span className="submenu-text">
                              {navbarTexts &&
                                navbarTexts.submenuText.menu2 &&
                                navbarTexts.submenuText.menu2.subMenu4}
                            </span>
                          </NavLink>
                        </div>

                        <div className="col-6 col-md-3 nav-item">
                          <NavLink
                            to="ourProgram/ecsc"
                            onClick={(e) =>
                              hideMenu(e, "ourwork-submenu", "/ourProgram/ecsc")
                            }
                            className="nav-link"
                          >
                            <img
                              src={
                                ourWorkImage && ourWorkImage.ecsc
                                  ? ourWorkImage.ecsc
                                  : coverImg
                              }
                              alt="menu img"
                              className="nav-img"
                            />
                            <div className="nav-overlay"></div>
                            <span className="submenu-text">
                              {navbarTexts &&
                                navbarTexts.submenuText.menu2 &&
                                navbarTexts.submenuText.menu2.submenu5}
                            </span>
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="navbar-main-item">
                  <NavLink to="#" className="navbar-main-link">
                    <label>
                      {" "}
                      {navbarTexts &&
                        navbarTexts.mainMenuText.menu3 &&
                        navbarTexts.mainMenuText.menu3}
                    </label>
                    <i className="fa fa-angle-down text-blue-grey"></i>
                  </NavLink>
                  <div id="resources-submenu" className="submenu p-4">
                    <div className="container">
                      <div className="row">
                        <div className="col-6 col-md-3 nav-item">
                          <NavLink
                            to="/resources/newsAndEvents"
                            className="nav-link"
                            onClick={(e) =>
                              hideMenu(
                                e,
                                "resources-submenu",
                                "/resources/newsAndEvents"
                              )
                            }
                          >
                            <img
                              src={
                                resourcesImages && resourcesImages.newsAndEvents
                                  ? resourcesImages.newsAndEvents
                                  : coverImg
                              }
                              alt="menu img"
                              className="nav-img"
                            />
                            <div className="nav-overlay"></div>
                            <span className="submenu-text">
                              {navbarTexts &&
                                navbarTexts.submenuText.menu3 &&
                                navbarTexts.submenuText.menu3.subMenu1}
                            </span>
                          </NavLink>
                        </div>
                        <div className="col-6 col-md-3 nav-item position-relative">
                          <NavLink
                            to="/resources/vacancy"
                            className="nav-link"
                            onClick={(e) =>
                              hideMenu(
                                e,
                                "resources-submenu",
                                "/resources/vacancy"
                              )
                            }
                          >
                            <img
                              src={
                                resourcesImages && resourcesImages.vacancy
                                  ? resourcesImages.vacancy
                                  : coverImg
                              }
                              alt="menu img"
                              className="nav-img"
                            />
                            <div className="nav-overlay"></div>
                            <span className="submenu-text">
                              {navbarTexts &&
                                navbarTexts.submenuText.menu3 &&
                                navbarTexts.submenuText.menu3.subMenu2}
                            </span>
                          </NavLink>
                        </div>

                        <div className="col-6 col-md-3 nav-item position-relative">
                          <NavLink
                            to="/resources/lives"
                            className="nav-link"
                            onClick={(e) =>
                              hideMenu(
                                e,
                                "resources-submenu",
                                "/resources/lives"
                              )
                            }
                          >
                            <img
                              src={
                                resourcesImages &&
                                resourcesImages.transformingLives
                                  ? resourcesImages.transformingLives
                                  : coverImg
                              }
                              alt="menu img"
                              className="nav-img"
                            />
                            <div className="nav-overlay"></div>
                            <span className="submenu-text">
                              {navbarTexts &&
                                navbarTexts.submenuText.menu3 &&
                                navbarTexts.submenuText.menu3.subMenu3}
                            </span>
                          </NavLink>
                        </div>

                        <div className="col-6 col-md-3 nav-item position-relative">
                          <NavLink
                            to="/resources/blog"
                            className="nav-link"
                            onClick={(e) =>
                              hideMenu(
                                e,
                                "resources-submenu",
                                "/resources/blog"
                              )
                            }
                          >
                            <img
                              src={
                                resourcesImages && resourcesImages.blog
                                  ? resourcesImages.blog
                                  : coverImg
                              }
                              alt="menu img"
                              className="nav-img"
                            />
                            <div className="nav-overlay"></div>
                            <span className="submenu-text">
                              {" "}
                              {navbarTexts &&
                                navbarTexts.submenuText.menu3 &&
                                navbarTexts.submenuText.menu3.subMenu4}
                            </span>
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="navbar-main-item">
                  <NavLink to="#" className="navbar-main-link">
                    <label>
                      {" "}
                      {navbarTexts &&
                        navbarTexts.mainMenuText.menu4 &&
                        navbarTexts.mainMenuText.menu4}
                    </label>
                    <i className="fa fa-angle-down text-blue-grey"></i>
                  </NavLink>
                  <div id="getinvolved-submenu" className="submenu p-4">
                    <div className="container">
                      <div className="row">
                        <div className="col-6 col-md-3 nav-item">
                          <NavLink
                            to="/getInvolved/vacancy"
                            className="nav-link"
                            onClick={(e) =>
                              hideMenu(
                                e,
                                "getinvolved-submenu",
                                "/getInvolved/vacancy"
                              )
                            }
                          >
                            <img
                              src={
                                getInvolved && getInvolved.vacancy
                                  ? getInvolved.vacancy
                                  : coverImg
                              }
                              alt="menu img"
                              className="nav-img"
                            />
                            <div className="nav-overlay"></div>
                            <span className="submenu-text">
                              {navbarTexts &&
                                navbarTexts.submenuText.menu4 &&
                                navbarTexts.submenuText.menu4.subMenu1}
                            </span>
                          </NavLink>
                        </div>

                        <div className="col-6 col-md-3 nav-item">
                          <NavLink
                            to="/getInvolved/procurement"
                            className="nav-link"
                            onClick={(e) =>
                              hideMenu(
                                e,
                                "getinvolved-submenu",
                                "/getInvolved/procurement"
                              )
                            }
                          >
                            <img
                              src={
                                getInvolved && getInvolved.procurement
                                  ? getInvolved.procurement
                                  : coverImg
                              }
                              alt="menu img"
                              className="nav-img"
                            />
                            <div className="nav-overlay"></div>
                            <span className="submenu-text">
                              {navbarTexts &&
                                navbarTexts.submenuText.menu4 &&
                                navbarTexts.submenuText.menu4.subMenu2}
                            </span>
                          </NavLink>
                        </div>

                        <div className="col-6 col-md-3 nav-item">
                          <NavLink
                            to="/getInvolved/volunteer"
                            className="nav-link"
                            onClick={(e) =>
                              hideMenu(
                                e,
                                "getinvolved-submenu",
                                "/getInvolved/volunteer"
                              )
                            }
                          >
                            <img
                              src={
                                getInvolved && getInvolved.volunteer
                                  ? getInvolved.volunteer
                                  : coverImg
                              }
                              alt="menu img"
                              className="nav-img"
                            />
                            <div className="nav-overlay"></div>
                            <span className="submenu-text">
                              {navbarTexts &&
                                navbarTexts.submenuText.menu4 &&
                                navbarTexts.submenuText.menu4.subMenu3}
                            </span>
                          </NavLink>
                        </div>

                        <div className="col-6 col-md-3 nav-item">
                          <NavLink
                            to="/donation"
                            className="nav-link"
                            onClick={(e) =>
                              hideMenu(e, "getinvolved-submenu", "/donation")
                            }
                          >
                            <img
                              src={
                                getInvolved && getInvolved.donate
                                  ? getInvolved.donate
                                  : coverImg
                              }
                              alt="menu img"
                              className="nav-img"
                            />
                            <div className="nav-overlay"></div>
                            <span className="submenu-text">
                              {navbarTexts &&
                                navbarTexts.submenuText.menu4 &&
                                navbarTexts.submenuText.menu4.subMenu4}
                            </span>
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="navbar-main-item">
                  <NavLink to="/contactUs" className="navbar-main-link">
                    {locale === "EN"
                      ? "Contact Us"
                      : "हामीलाई सम्पर्क गर्नुहोस्"}
                  </NavLink>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </header>
    </>
  );
}
