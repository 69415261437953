import React, { useEffect, useRef, useState } from "react";
import { getBannerImages, saveBannerImages } from "../../../../services/api";
import { toastError, toastSuccess } from "../../../../services/ToastService";
import { ToastContainer } from "react-toastify";

function BannerImages() {
  const aboutUsHistory = useRef(null);
  const aboutUsIntroductionRef = useRef(null);
  const aboutUsourTeamsRef = useRef(null);
  const aboutUsOurPartners = useRef(null);
  const strategicPlan = useRef(null);

  // ourWork
  const ourWorkAdvocacyAwarness = useRef(null);
  const ourWorkEmpowerment = useRef(null);
  const ourWorkSupport = useRef(null);
  const ourWorkOrgDevelopment = useRef(null);
  const ourWorkEcsc = useRef(null);

  // Resources
  const resourcesNewsAndEvents = useRef(null);
  const resourcesVacancy = useRef(null);
  const resourcesVolunteer = useRef(null);
  const resourcesDigitalLibrary = useRef(null);
  const resourcesTransformingLives = useRef(null);
  const resourcesBlog = useRef(null);

  // getInvolved
  const getInvolvedProcurement = useRef(null);
  const getInvolvedVacancy = useRef(null);
  const getInvolvedVolunteer = useRef(null);
  const getInvolvedDonate = useRef(null);

  // ContactUs
  const contactUs = useRef(null);

  const [aboutUsImages, setAboutUsImages] = useState();
  const [ourWorkImages, setOurWorkImages] = useState();
  const [resourcesImages, setResourcesImages] = useState();
  const [getInvolvedImages, setGetInvolvedImages] = useState();
  const [contactUsImages, setContactUsImages] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response = await getBannerImages();
        if (response.data) {
          let bannerData = response.data;
          console.log(response.data);
          let aboutUsData = {
            aboutUsHistory: "",
            aboutUsIntroduction: "",
            aboutUsOurPartners: "",
            aboutUsOurTeam: "",
            aboutUsStrategicPlan: "",
          };

          let getInvolvedData = {
            getInvolvedDonate: "",
            getInvolvedProcurement: "",
            getInvolvedVacancy: "",
            getInvolvedVolunteer: "",
          };

          let ourWorkData = {
            ourWorkAdvocacyAwarness: "",
            ourWorkESCS: "",
            ourWorkEmpowerment: "",
            ourWorkOrgDevelopment: "",
            ourWorkSupport: "",
          };

          let resourceData = {
            resourcesBlog: "",
            resourcesDigitalLibrary: "",
            resourcesNewsAndEvents: "",
            resourcesTransformingLives: "",
            resourcesVacancy: "",
            resourcesVolunteer: "",
          };

          let contactUsData = {
            contactUs: "",
          };

          // Assign values from bannerData to aboutUsData
          aboutUsData.aboutUsHistory = bannerData.aboutUsHistory;
          aboutUsData.aboutUsIntroduction = bannerData.aboutUsIntroduction;
          aboutUsData.aboutUsOurPartners = bannerData.aboutUsOurPartners;
          aboutUsData.aboutUsOurTeam = bannerData.aboutUsOurTeam;
          aboutUsData.aboutUsStrategicPlan = bannerData.aboutUsStrategicPlan;
          setAboutUsImages(aboutUsData);

          getInvolvedData.getInvolvedDonate = bannerData.getInvolvedDonate;
          getInvolvedData.getInvolvedProcurement =
            bannerData.getInvolvedProcurement;
          getInvolvedData.getInvolvedVacancy = bannerData.getInvolvedVacancy;
          getInvolvedData.getInvolvedVolunteer =
            bannerData.getInvolvedVolunteer;
          setGetInvolvedImages(getInvolvedData);

          ourWorkData.ourWorkAdvocacyAwarness =
            bannerData.ourWorkAdvocacyAwarness;
          ourWorkData.ourWorkESCS = bannerData.ourWorkESCS;
          ourWorkData.ourWorkEmpowerment = bannerData.ourWorkEmpowerment;
          ourWorkData.ourWorkOrgDevelopment = bannerData.ourWorkOrgDevelopment;
          ourWorkData.ourWorkSupport = bannerData.ourWorkSupport;
          setOurWorkImages(ourWorkData);

          resourceData.resourcesBlog = bannerData.resourcesBlog;
          resourceData.resourcesDigitalLibrary =
            bannerData.resourcesDigitalLibrary;
          resourceData.resourcesNewsAndEvents =
            bannerData.resourcesNewsAndEvents;
          resourceData.resourcesTransformingLives =
            bannerData.resourcesTransformingLives;
          resourceData.resourcesVacancy = bannerData.resourcesVacancy;
          resourceData.resourcesVolunteer = bannerData.resourcesVolunteer;
          setResourcesImages(resourceData);

          contactUsData.contactUs = bannerData.contactUs;
          setContactUsImages(contactUsData);
        }
      } catch (error) {
        console.error("Error fetching slider content:", error);
        toastError();
      }
    };
    fetchData();
  }, []);

  const onSubmit = async (item, e) => {
    const shouldUploadFile = (fileRef, fileType) => {
      if (!fileRef.current || fileRef.current.files.length === 0) {
        return false;
      }
      const file = fileRef.current.files[0];
      return file && file.type.startsWith(fileType + "/");
    };
    e.preventDefault();
    if (item === "aboutUs") {
      if (aboutUsHistory) {
        if (shouldUploadFile(aboutUsHistory, "image")) {
          const formData = new FormData();
          formData.append("image", aboutUsHistory.current.files[0]);
          formData.append("name", "aboutUsHistory");
          await saveBannerImages(formData);
          toastSuccess();
        }
      }

      if (aboutUsIntroductionRef) {
        if (shouldUploadFile(aboutUsIntroductionRef, "image")) {
          const formData = new FormData();
          formData.append("image", aboutUsIntroductionRef.current.files[0]);
          formData.append("name", "aboutUsIntroduction");
          await saveBannerImages(formData);
          toastSuccess();
        }
      }
      if (aboutUsourTeamsRef) {
        if (shouldUploadFile(aboutUsourTeamsRef, "image")) {
          const formData = new FormData();
          formData.append("image", aboutUsourTeamsRef.current.files[0]);
          formData.append("name", "aboutUsOurTeam");
          await saveBannerImages(formData);
          toastSuccess();
        }
      }

      if (aboutUsOurPartners) {
        if (shouldUploadFile(aboutUsOurPartners, "image")) {
          const formData = new FormData();
          formData.append("image", aboutUsOurPartners.current.files[0]);
          formData.append("name", "aboutUsOurPartners");
          await saveBannerImages(formData);
          toastSuccess();
        }
      }

      if (strategicPlan) {
        if (shouldUploadFile(strategicPlan, "image")) {
          const formData = new FormData();
          formData.append("image", strategicPlan.current.files[0]);
          formData.append("name", "aboutUsStrategicPlan");
          await saveBannerImages(formData);
          toastSuccess();
        }
      }
    }

    if (item === "ourwork") {
      if (ourWorkAdvocacyAwarness) {
        if (shouldUploadFile(ourWorkAdvocacyAwarness, "image")) {
          const formData = new FormData();
          formData.append("image", ourWorkAdvocacyAwarness.current.files[0]);
          formData.append("name", "ourWorkAdvocacyAwarness");
          await saveBannerImages(formData);
          toastSuccess();
        }
      }

      if (ourWorkEmpowerment) {
        if (shouldUploadFile(ourWorkEmpowerment, "image")) {
          const formData = new FormData();
          formData.append("image", ourWorkEmpowerment.current.files[0]);
          formData.append("name", "ourWorkEmpowerment");
          await saveBannerImages(formData);
          toastSuccess();
        }
      }
      if (ourWorkSupport) {
        if (shouldUploadFile(ourWorkSupport, "image")) {
          const formData = new FormData();
          formData.append("image", ourWorkSupport.current.files[0]);
          formData.append("name", "ourWorkSupport");
          await saveBannerImages(formData);
          toastSuccess();
        }
      }

      if (ourWorkOrgDevelopment) {
        if (shouldUploadFile(ourWorkOrgDevelopment, "image")) {
          const formData = new FormData();
          formData.append("image", ourWorkOrgDevelopment.current.files[0]);
          formData.append("name", "ourWorkOrgDevelopment");
          await saveBannerImages(formData);
          toastSuccess();
        }
      }
      if (ourWorkEcsc) {
        if (shouldUploadFile(ourWorkEcsc, "image")) {
          const formData = new FormData();
          formData.append("image", ourWorkEcsc.current.files[0]);
          formData.append("name", "ourWorkESCS");
          await saveBannerImages(formData);
          toastSuccess();
        }
      }
    }

    if (item === "getinvolved") {
      if (getInvolvedProcurement) {
        if (shouldUploadFile(getInvolvedProcurement, "image")) {
          const formData = new FormData();
          formData.append("image", getInvolvedProcurement.current.files[0]);
          formData.append("name", "getInvolvedProcurement");
          await saveBannerImages(formData);
          toastSuccess();
        }
      }

      if (getInvolvedVacancy) {
        if (shouldUploadFile(getInvolvedVacancy, "image")) {
          const formData = new FormData();
          formData.append("image", getInvolvedVacancy.current.files[0]);
          formData.append("name", "getInvolvedVacancy");
          await saveBannerImages(formData);
          toastSuccess();
        }
      }
      if (getInvolvedVolunteer) {
        if (shouldUploadFile(getInvolvedVolunteer, "image")) {
          const formData = new FormData();
          formData.append("image", getInvolvedVolunteer.current.files[0]);
          formData.append("name", "getInvolvedVolunteer");
          await saveBannerImages(formData);
          toastSuccess();
        }
      }

      if (getInvolvedDonate) {
        if (shouldUploadFile(getInvolvedDonate, "image")) {
          const formData = new FormData();
          formData.append("image", getInvolvedDonate.current.files[0]);
          formData.append("name", "getInvolvedDonate");
          await saveBannerImages(formData);
          toastSuccess();
        }
      }
    }

    if (item === "resources") {
      if (resourcesNewsAndEvents) {
        if (shouldUploadFile(resourcesNewsAndEvents, "image")) {
          const formData = new FormData();
          formData.append("image", resourcesNewsAndEvents.current.files[0]);
          formData.append("name", "resourcesNewsAndEvents");
          await saveBannerImages(formData);
          toastSuccess();
        }
      }

      if (resourcesVacancy) {
        if (shouldUploadFile(resourcesVacancy, "image")) {
          const formData = new FormData();
          formData.append("image", resourcesVacancy.current.files[0]);
          formData.append("name", "resourcesVacancy");
          await saveBannerImages(formData);
          toastSuccess();
        }
      }

      if (resourcesVolunteer) {
        if (shouldUploadFile(resourcesVolunteer, "image")) {
          const formData = new FormData();
          formData.append("image", resourcesVolunteer.current.files[0]);
          formData.append("name", "resourcesVolunteer");
          await saveBannerImages(formData);
          toastSuccess();
        }
      }

      if (resourcesDigitalLibrary) {
        if (shouldUploadFile(resourcesDigitalLibrary, "image")) {
          const formData = new FormData();
          formData.append("image", resourcesDigitalLibrary.current.files[0]);
          formData.append("name", "resourcesDigitalLibrary");
          await saveBannerImages(formData);
          toastSuccess();
        }
      }

      if (resourcesTransformingLives) {
        if (shouldUploadFile(resourcesTransformingLives, "image")) {
          const formData = new FormData();
          formData.append("image", resourcesTransformingLives.current.files[0]);
          formData.append("name", "resourcesTransformingLives");
          await saveBannerImages(formData);
          toastSuccess();
        }
      }

      if (resourcesBlog) {
        if (shouldUploadFile(resourcesBlog, "image")) {
          const formData = new FormData();
          formData.append("image", resourcesBlog.current.files[0]);
          formData.append("name", "resourcesBlog");
          await saveBannerImages(formData);
          toastSuccess();
        }
      }
    }

    if (item === "contactUs") {
      if (contactUs) {
        if (shouldUploadFile(contactUs, "image")) {
          const formData = new FormData();
          formData.append("image", contactUs.current.files[0]);
          formData.append("name", "contactUs");
          await saveBannerImages(formData);
          toastSuccess();
        }
      }
    }
  };
  return (
    <>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Banner</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="index.html">Auth</a>
              </li>
              <li className="breadcrumb-item">Components</li>
              <li className="breadcrumb-item active">Banner</li>
            </ol>
          </nav>
        </div>
        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Banner Images</h5>
                  <hr className="border-2" />
                  <div className="container ">
                    <h5 className="card-title">About Us Banner Images</h5>
                    <hr className="border-2" />
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <h4>AboutUs History</h4>
                        <label className="form-label">
                          Image (Best Fit Size: height:450 px; width: 1515 px;)
                        </label>
                        <input ref={aboutUsHistory} type="file" />
                      </div>

                      <div className="mb-3 col-md-6">
                        <img
                          style={{
                            height: "200px",
                            width: "300px",
                            objectFit: "contain",
                          }}
                          src={
                            aboutUsImages && aboutUsImages.aboutUsHistory
                              ? aboutUsImages.aboutUsHistory
                              : ""
                          }
                          alt="Uploaded Photograph"
                          accept=".jpg,.jpeg,.png,.gif"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <h4>AboutUs Introduction</h4>
                        <label className="form-label">
                          Image (Best Fit Size: height:450 px; width: 1515 px;)
                        </label>
                        <input ref={aboutUsIntroductionRef} type="file" />
                      </div>

                      <div className="mb-3 col-md-6">
                        <img
                          style={{
                            height: "200px",
                            width: "300px",
                            objectFit: "contain",
                          }}
                          src={
                            aboutUsImages && aboutUsImages.aboutUsIntroduction
                              ? aboutUsImages.aboutUsIntroduction
                              : ""
                          }
                          alt="Uploaded Photograph"
                          accept=".jpg,.jpeg,.png,.gif"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <h4>Our Teams</h4>
                        <label className="form-label">
                          Image (Best Fit Size: height:450 px; width: 1515 px;)
                        </label>
                        <input ref={aboutUsourTeamsRef} type="file" />
                      </div>

                      <div className="mb-3 col-md-6">
                        <img
                          style={{
                            height: "200px",
                            width: "300px",
                            objectFit: "contain",
                          }}
                          src={
                            aboutUsImages && aboutUsImages.aboutUsOurTeam
                              ? aboutUsImages.aboutUsOurTeam
                              : ""
                          }
                          alt="Uploaded Photograph"
                          accept=".jpg,.jpeg,.png,.gif"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <h4>Our Partners</h4>
                        <label className="form-label">
                          Image (Best Fit Size: height:450 px; width: 1515 px;)
                        </label>
                        <input ref={aboutUsOurPartners} type="file" />
                      </div>

                      <div className="mb-3 col-md-6">
                        <img
                          style={{
                            height: "200px",
                            width: "300px",
                            objectFit: "contain",
                          }}
                          src={
                            aboutUsImages && aboutUsImages.aboutUsOurPartners
                              ? aboutUsImages.aboutUsOurPartners
                              : ""
                          }
                          alt="Uploaded Photograph"
                          accept=".jpg,.jpeg,.png,.gif"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <h4>Our StrategicPlan</h4>
                        <label className="form-label">
                          Image (Best Fit Size: height:450 px; width: 1515 px;)
                        </label>
                        <input ref={strategicPlan} type="file" />
                      </div>

                      <div className="mb-3 col-md-6">
                        <img
                          style={{
                            height: "200px",
                            width: "300px",
                            objectFit: "contain",
                          }}
                          src={
                            aboutUsImages && aboutUsImages.aboutUsStrategicPlan
                              ? aboutUsImages.aboutUsStrategicPlan
                              : ""
                          }
                          alt="Uploaded Photograph"
                          accept=".jpg,.jpeg,.png,.gif"
                        />
                      </div>
                    </div>
                    <div className="d-flex justify-content-center">
                      <button
                        onClick={(e) => onSubmit("aboutUs", e)}
                        type="submit"
                        className="btn btn-primary m-5"
                      >
                        Save About Us Banner Images
                      </button>
                    </div>
                  </div>

                  <div className="container ">
                    <h5 className="card-title">Our Work Banner Images</h5>
                    <hr className="border-2" />
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <h4>Advocacy Awarness</h4>
                        <label className="form-label">
                          Image (Best Fit Size: height:450 px; width: 1515 px;)
                        </label>
                        <input ref={ourWorkAdvocacyAwarness} type="file" />
                      </div>

                      <div className="mb-3 col-md-6">
                        <img
                          style={{
                            height: "200px",
                            width: "300px",
                            objectFit: "contain",
                          }}
                          src={
                            ourWorkImages &&
                            ourWorkImages.ourWorkAdvocacyAwarness
                              ? ourWorkImages.ourWorkAdvocacyAwarness
                              : ""
                          }
                          alt="Uploaded Photograph"
                          accept=".jpg,.jpeg,.png,.gif"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <h4>Empowerment</h4>
                        <label className="form-label">
                          Image (Best Fit Size: height:450 px; width: 1515 px;)
                        </label>
                        <input ref={ourWorkEmpowerment} type="file" />
                      </div>

                      <div className="mb-3 col-md-6">
                        <img
                          style={{
                            height: "200px",
                            width: "300px",
                            objectFit: "contain",
                          }}
                          src={
                            ourWorkImages && ourWorkImages.ourWorkEmpowerment
                              ? ourWorkImages.ourWorkEmpowerment
                              : ""
                          }
                          alt="Uploaded Photograph"
                          accept=".jpg,.jpeg,.png,.gif"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <h4>Support</h4>
                        <label className="form-label">
                          Image (Best Fit Size: height:450 px; width: 1515 px;)
                        </label>
                        <input ref={ourWorkSupport} type="file" />
                      </div>

                      <div className="mb-3 col-md-6">
                        <img
                          style={{
                            height: "200px",
                            width: "300px",
                            objectFit: "contain",
                          }}
                          src={
                            ourWorkImages && ourWorkImages.ourWorkSupport
                              ? ourWorkImages.ourWorkSupport
                              : ""
                          }
                          alt="Uploaded Photograph"
                          accept=".jpg,.jpeg,.png,.gif"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <h4>Organization Development</h4>
                        <label className="form-label">
                          Image (Best Fit Size: height:450 px; width: 1515 px;)
                        </label>
                        <input ref={ourWorkOrgDevelopment} type="file" />
                      </div>

                      <div className="mb-3 col-md-6">
                        <img
                          style={{
                            height: "200px",
                            width: "300px",
                            objectFit: "contain",
                          }}
                          src={
                            ourWorkImages && ourWorkImages.ourWorkOrgDevelopment
                              ? ourWorkImages.ourWorkOrgDevelopment
                              : ""
                          }
                          alt="Uploaded Photograph"
                          accept=".jpg,.jpeg,.png,.gif"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <h4>ECSC</h4>
                        <label className="form-label">
                          Image (Best Fit Size: height:450 px; width: 1515 px;)
                        </label>
                        <input ref={ourWorkEcsc} type="file" />
                      </div>

                      <div className="mb-3 col-md-6">
                        <img
                          style={{
                            height: "200px",
                            width: "300px",
                            objectFit: "contain",
                          }}
                          src={
                            ourWorkImages && ourWorkImages.ourWorkESCS
                              ? ourWorkImages.ourWorkESCS
                              : ""
                          }
                          alt="Uploaded Photograph"
                          accept=".jpg,.jpeg,.png,.gif"
                        />
                      </div>
                    </div>

                    <div className="d-flex justify-content-center">
                      <button
                        onClick={(e) => onSubmit("ourwork", e)}
                        type="submit"
                        className="btn btn-primary m-5"
                      >
                        Save Our Work Banner Images
                      </button>
                    </div>
                  </div>

                  <div className="container ">
                    <h5 className="card-title">Get Involved Banner Images</h5>
                    <hr className="border-2" />
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <h4>Procurement</h4>
                        <label className="form-label">
                          Image (Best Fit Size: height:450 px; width: 1515 px;)
                        </label>
                        <input ref={getInvolvedProcurement} type="file" />
                      </div>

                      <div className="mb-3 col-md-6">
                        <img
                          style={{
                            height: "200px",
                            width: "300px",
                            objectFit: "contain",
                          }}
                          src={
                            getInvolvedImages &&
                            getInvolvedImages.getInvolvedProcurement
                              ? getInvolvedImages.getInvolvedProcurement
                              : ""
                          }
                          alt="Uploaded Photograph"
                          accept=".jpg,.jpeg,.png,.gif"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <h4>Vacancy</h4>
                        <label className="form-label">
                          Image (Best Fit Size: height:450 px; width: 1515 px;)
                        </label>
                        <input ref={getInvolvedVacancy} type="file" />
                      </div>

                      <div className="mb-3 col-md-6">
                        <img
                          style={{
                            height: "200px",
                            width: "300px",
                            objectFit: "contain",
                          }}
                          src={
                            getInvolvedImages &&
                            getInvolvedImages.getInvolvedVacancy
                              ? getInvolvedImages.getInvolvedVacancy
                              : ""
                          }
                          alt="Uploaded Photograph"
                          accept=".jpg,.jpeg,.png,.gif"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <h4>Volunteer</h4>
                        <label className="form-label">
                          Image (Best Fit Size: height:450 px; width: 1515 px;)
                        </label>
                        <input ref={getInvolvedVolunteer} type="file" />
                      </div>

                      <div className="mb-3 col-md-6">
                        <img
                          style={{
                            height: "200px",
                            width: "300px",
                            objectFit: "contain",
                          }}
                          src={
                            getInvolvedImages &&
                            getInvolvedImages.getInvolvedVolunteer
                              ? getInvolvedImages.getInvolvedVolunteer
                              : ""
                          }
                          alt="Uploaded Photograph"
                          accept=".jpg,.jpeg,.png,.gif"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <h4>Donate</h4>
                        <label className="form-label">
                          Image (Best Fit Size: height:450 px; width: 1515 px;)
                        </label>
                        <input ref={getInvolvedDonate} type="file" />
                      </div>

                      <div className="mb-3 col-md-6">
                        <img
                          style={{
                            height: "200px",
                            width: "300px",
                            objectFit: "contain",
                          }}
                          src={
                            getInvolvedImages &&
                            getInvolvedImages.getInvolvedDonate
                              ? getInvolvedImages.getInvolvedDonate
                              : ""
                          }
                          alt="Uploaded Photograph"
                          accept=".jpg,.jpeg,.png,.gif"
                        />
                      </div>
                    </div>

                    <div className="d-flex justify-content-center">
                      <button
                        onClick={(e) => onSubmit("getinvolved", e)}
                        type="submit"
                        className="btn btn-primary m-5"
                      >
                        Save Get Involved Banner Images
                      </button>
                    </div>
                  </div>

                  <div className="container ">
                    <h5 className="card-title">Resources Banner Images</h5>
                    <hr className="border-2" />
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <h4>News and Events</h4>
                        <label className="form-label">
                          Image (Best Fit Size: height:450 px; width: 1515 px;)
                        </label>
                        <input ref={resourcesNewsAndEvents} type="file" />
                      </div>

                      <div className="mb-3 col-md-6">
                        <img
                          style={{
                            height: "200px",
                            width: "300px",
                            objectFit: "contain",
                          }}
                          src={
                            resourcesImages &&
                            resourcesImages.resourcesNewsAndEvents
                              ? resourcesImages.resourcesNewsAndEvents
                              : ""
                          }
                          alt="Uploaded Photograph"
                          accept=".jpg,.jpeg,.png,.gif"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <h4>Media</h4>
                        <label className="form-label">
                          Image (Best Fit Size: height:450 px; width: 1515 px;)
                        </label>
                        <input ref={resourcesVacancy} type="file" />
                      </div>

                      <div className="mb-3 col-md-6">
                        <img
                          style={{
                            height: "200px",
                            width: "300px",
                            objectFit: "contain",
                          }}
                          src={
                            resourcesImages && resourcesImages.resourcesVacancy
                              ? resourcesImages.resourcesVacancy
                              : ""
                          }
                          alt="Uploaded Photograph"
                          accept=".jpg,.jpeg,.png,.gif"
                        />
                      </div>
                    </div>

                    {/* <div className="row">
                      <div className="col-md-6 mb-3">
                        <h4>Volunteer</h4>
                        <label className="form-label">
                          Image (Best Fit Size: height:450 px; width: 1515 px;)
                        </label>
                        <input ref={resourcesVolunteer} type="file" />
                      </div>

                      <div className="mb-3 col-md-6">
                        <img
                          style={{
                            height: "200px",
                            width: "300px",
                            objectFit: "contain",
                          }}
                          src={
                            resourcesImages &&
                            resourcesImages.resourcesVolunteer
                              ? resourcesImages.resourcesVolunteer
                              : ""
                          }
                          alt="Uploaded Photograph"
                          accept=".jpg,.jpeg,.png,.gif"
                        />
                      </div>
                    </div> */}

                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <h4>Publication</h4>
                        <label className="form-label">
                          Image (Best Fit Size: height:450 px; width: 1515 px;)
                        </label>
                        <input ref={resourcesTransformingLives} type="file" />
                      </div>

                      <div className="mb-3 col-md-6">
                        <img
                          style={{
                            height: "200px",
                            width: "300px",
                            objectFit: "contain",
                          }}
                          src={
                            resourcesImages &&
                            resourcesImages.resourcesTransformingLives
                              ? resourcesImages.resourcesTransformingLives
                              : ""
                          }
                          alt="Uploaded Photograph"
                          accept=".jpg,.jpeg,.png,.gif"
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <h4>Blog</h4>
                        <label className="form-label">
                          Image (Best Fit Size: height:450 px; width: 1515 px;)
                        </label>
                        <input ref={resourcesBlog} type="file" />
                      </div>

                      <div className="mb-3 col-md-6">
                        <img
                          style={{
                            height: "200px",
                            width: "300px",
                            objectFit: "contain",
                          }}
                          src={
                            resourcesImages && resourcesImages.resourcesBlog
                              ? resourcesImages.resourcesBlog
                              : ""
                          }
                          alt="Uploaded Photograph"
                          accept=".jpg,.jpeg,.png,.gif"
                        />
                      </div>
                    </div>

                    {/* <div className="row">
                      <div className="col-md-6 mb-3">
                        <h4>Digital Library</h4>
                        <label className="form-label">
                          Image (Best Fit Size: height:450 px; width: 1515 px;)
                        </label>
                        <input ref={resourcesDigitalLibrary} type="file" />
                      </div>

                      <div className="mb-3 col-md-6">
                        <img
                          style={{
                            height: "200px",
                            width: "300px",
                            objectFit: "contain",
                          }}
                          src={
                            resourcesImages &&
                            resourcesImages.resourcesDigitalLibrary
                              ? resourcesImages.resourcesDigitalLibrary
                              : ""
                          }
                          alt="Uploaded Photograph"
                          accept=".jpg,.jpeg,.png,.gif"
                        />
                      </div>
                    </div> */}

                    <div className="d-flex justify-content-center">
                      <button
                        onClick={(e) => onSubmit("resources", e)}
                        type="submit"
                        className="btn btn-primary m-5"
                      >
                        Save Resources Banner Images
                      </button>
                    </div>
                  </div>

                  <div className="container ">
                    <h5 className="card-title">ContactUs Image</h5>
                    <hr className="border-2" />
                    <div className="row">
                      <div className="col-md-6 mb-3">
                        <h4>ContactUs</h4>
                        <label className="form-label">
                          Image (Best Fit Size: height: 200px; width: 295px;)
                        </label>
                        <input ref={contactUs} type="file" />
                      </div>

                      <div className="mb-3 col-md-6">
                        <img
                          style={{
                            height: "200px",
                            width: "300px",
                            objectFit: "contain",
                          }}
                          src={
                            contactUsImages && contactUsImages.contactUs
                              ? contactUsImages.contactUs
                              : ""
                          }
                          alt="Uploaded Photograph"
                          accept=".jpg,.jpeg,.png,.gif"
                        />
                      </div>
                    </div>

                    <div className="d-flex justify-content-center">
                      <button
                        onClick={(e) => onSubmit("contactUs", e)}
                        type="submit"
                        className="btn btn-primary m-5"
                      >
                        Save ContactUs Images
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <ToastContainer />
    </>
  );
}

export default BannerImages;
