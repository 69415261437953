import React, { useEffect, useState } from "react";
import {
  getAllNavbarText,
  updateEnglishNavbarText,
  updateNepaliNavbarText,
} from "../../../../services/api";
import { toastError, toastSuccess } from "../../../../services/ToastService";
import { ToastContainer } from "react-toastify";

const NavbarTextAdmin = () => {
  const [navbarTextEng, setNavbarTextEng] = useState({
    headerText: {
      headerText1: "",
    },
    mainMenuText: {
      menu1: "",
      menu2: "",
      menu3: "",
      menu4: "",
    },
    submenuText: {
      menu1: {
        subMenu1: "",
        subMenu2: "",
        subMenu3: "",
        subMenu4: "",
        subMenu5: "",
      },
      menu2: {
        subMenu1: "",
        subMenu2: "",
        subMenu3: "",
        subMenu4: "",
        submenu5: "",
      },
      menu3: {
        subMenu1: "",
        subMenu2: "",
        subMenu3: "",
        subMenu4: "",
      },
      menu4: {
        subMenu1: "",
        subMenu2: "",
        subMenu3: "",
        subMenu4: "",
      },
    },
  });

  const [navbarTextNep, setNavbarTextNep] = useState({
    headerText: {
      headerText1: "",
    },
    mainMenuText: {
      menu1: "",
      menu2: "",
      menu3: "",
      menu4: "",
    },
    submenuText: {
      menu1: {
        subMenu1: "",
        subMenu2: "",
        subMenu3: "",
        subMenu4: "",
        subMenu5: "",
      },
      menu2: {
        subMenu1: "",
        subMenu2: "",
        subMenu3: "",
        subMenu4: "",
        submenu5: "",
      },
      menu3: {
        subMenu1: "",
        subMenu2: "",
        subMenu3: "",
        subMenu4: "",
      },
      menu4: {
        subMenu1: "",
        subMenu2: "",
        subMenu3: "",
        subMenu4: "",
      },
    },
  });

  const getAllNavbarTextData = async () => {
    try {
      let res = await getAllNavbarText();
      if (res.data) {
        res.data.forEach((item) => {
          if (item.locale === "eng") {
            setNavbarTextEng((prevState) => ({
              ...prevState,
              headerText: item.headerText,
              mainMenuText: item.mainMenuText,
              submenuText: item.submenuText,
            }));
            console.log(navbarTextEng);
          } else if (item.locale === "nep") {
            setNavbarTextNep((prevState) => ({
              ...prevState,
              headerText: item.headerText,
              mainMenuText: item.mainMenuText,
              submenuText: item.submenuText,
            }));
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getAllNavbarTextData();
  }, []);

  const handleEngInputChange = (e, section, key) => {
    const { value } = e.target;
    const keys = key.split(".");

    setNavbarTextEng((prevState) => {
      let updatedState = { ...prevState };
      if (keys.length === 1) {
        updatedState[section][keys[0]] = value;
      } else {
        let nestedObject = updatedState[section];
        for (let i = 0; i < keys.length - 1; i++) {
          nestedObject = nestedObject[keys[i]];
        }
        nestedObject[keys[keys.length - 1]] = value;
      }
      return updatedState;
    });
  };

  const handleNepInputChange = (e, section, key) => {
    const { value } = e.target;
    const keys = key.split(".");

    setNavbarTextNep((prevState) => {
      let updatedState = { ...prevState };
      if (keys.length === 1) {
        updatedState[section][keys[0]] = value;
      } else {
        let nestedObject = updatedState[section];
        for (let i = 0; i < keys.length - 1; i++) {
          nestedObject = nestedObject[keys[i]];
        }
        nestedObject[keys[keys.length - 1]] = value;
      }
      return updatedState;
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (navbarTextEng) {
      try {
        await updateEnglishNavbarText(navbarTextEng);

        toastSuccess();
      } catch (error) {
        toastError();
      }
    }

    if (navbarTextNep) {
      try {
        await updateNepaliNavbarText(navbarTextNep);
        toastSuccess();
      } catch (error) {
        toastError();
      }
    }
    console.log(navbarTextEng);
    console.log(navbarTextNep);
  };

  return (
    <main className="main">
      <div className="pagetitle">
        <h1>Navbar Texts</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="index.html">Auth</a>
            </li>
            <li className="breadcrumb-item">Components</li>
            <li className="breadcrumb-item active">Navbar Texts</li>
          </ol>
        </nav>
      </div>
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-6">
                    <h4 className="card-title">English </h4>
                    <hr className="border-2" />
                    <div className="mb-3">
                      <h5 className="mb-3">Header Text</h5>
                      {Object.keys(navbarTextEng.headerText).map((key) => (
                        <div key={key} className="mb-3">
                          <label className="form-label">{key}</label>
                          <input
                            type="text"
                            className="form-control"
                            value={navbarTextEng.headerText[key]}
                            onChange={(e) =>
                              handleEngInputChange(e, "headerText", key)
                            }
                          />
                        </div>
                      ))}
                    </div>
                    <h5 className="mb-3">Main Menu Text</h5>
                    {Object.keys(navbarTextEng.mainMenuText).map((key) => (
                      <div key={key} className="mb-3">
                        <label className="form-label">{key}</label>
                        <input
                          type="text"
                          className="form-control"
                          value={navbarTextEng.mainMenuText[key]}
                          onChange={(e) =>
                            handleEngInputChange(e, "mainMenuText", key)
                          }
                        />
                      </div>
                    ))}
                    <h5 className="mb-3">Submenu Text</h5>
                    {Object.keys(navbarTextEng.submenuText).map((menuKey) => (
                      <div key={menuKey} className="mb-3">
                        <h6 className="mb-3">{menuKey}</h6>
                        {Object.keys(navbarTextEng.submenuText[menuKey]).map(
                          (subMenuKey) => (
                            <div key={subMenuKey} className="mb-3">
                              <label className="form-label">{subMenuKey}</label>
                              <input
                                type="text"
                                className="form-control"
                                value={
                                  navbarTextEng.submenuText[menuKey][subMenuKey]
                                }
                                onChange={(e) =>
                                  handleEngInputChange(
                                    e,
                                    "submenuText",
                                    `${menuKey}.${subMenuKey}`
                                  )
                                }
                              />
                            </div>
                          )
                        )}
                      </div>
                    ))}
                  </div>
                  <div className="col-md-6">
                    <h4 className="card-title">Nepali </h4>
                    <hr className="border-2" />
                    <div className="mb-3">
                      <h5 className="mb-3">Header Text</h5>
                      {Object.keys(navbarTextNep.headerText).map((key) => (
                        <div key={key} className="mb-3">
                          <label className="form-label">{key}</label>
                          <input
                            type="text"
                            className="form-control"
                            value={navbarTextNep.headerText[key]}
                            onChange={(e) =>
                              handleNepInputChange(e, "headerText", key)
                            }
                          />
                        </div>
                      ))}
                    </div>
                    <h5 className="mb-3">Main Menu Text</h5>
                    {Object.keys(navbarTextNep.mainMenuText).map((key) => (
                      <div key={key} className="mb-3">
                        <label className="form-label">{key}</label>
                        <input
                          type="text"
                          className="form-control"
                          value={navbarTextNep.mainMenuText[key]}
                          onChange={(e) =>
                            handleNepInputChange(e, "mainMenuText", key)
                          }
                        />
                      </div>
                    ))}
                    <h5 className="mb-3">Submenu Text</h5>
                    {Object.keys(navbarTextNep.submenuText).map((menuKey) => (
                      <div key={menuKey} className="mb-3">
                        <h6 className="mb-3">{menuKey}</h6>
                        {Object.keys(navbarTextNep.submenuText[menuKey]).map(
                          (subMenuKey) => (
                            <div key={subMenuKey} className="mb-3">
                              <label className="form-label">{subMenuKey}</label>
                              <input
                                type="text"
                                className="form-control"
                                value={
                                  navbarTextNep.submenuText[menuKey][subMenuKey]
                                }
                                onChange={(e) =>
                                  handleNepInputChange(
                                    e,
                                    "submenuText",
                                    `${menuKey}.${subMenuKey}`
                                  )
                                }
                              />
                            </div>
                          )
                        )}
                      </div>
                    ))}
                  </div>
                </div>
                <div
                  style={{ marginTop: "50px" }}
                  className="d-flex justify-content-center"
                >
                  <button
                    onClick={onSubmit}
                    type="submit"
                    className="btn btn-primary "
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </main>
  );
};

export default NavbarTextAdmin;
