import React, { useEffect, useState } from "react";
import { getBlogs } from "../../../services/api";
import { useParams } from "react-router-dom";
import Accessibility from "../Accessibility";

function BlogsReadMore() {
  const [blogs, setBlogs] = useState("");
  const [locale, setLocale] = useState("eng");
  let { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getBlogs();
        setTimeout(() => {
          if (res.data) {
            let individualBlog = res.data.blogs.find(
              (item) => item._id.toString() === id.toString()
            );
            if (individualBlog) {
              setBlogs(individualBlog);
            } else {
              setBlogs(res.data.blog[0]);
            }
          }
        });
      } catch (error) {}
    };

    fetchData();
    let locale = localStorage.getItem("locale")
      ? localStorage.getItem("locale")
      : "eng";
    setLocale(locale);
  }, [id]);

  return (
    <>
      <section>
        <Accessibility />

        <div className="responsive-div">
          <div dangerouslySetInnerHTML={{ __html: blogs.details }} />
        </div>
      </section>
    </>
  );
}

export default BlogsReadMore;
