import React, { Suspense } from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import { mainRoutes } from "./routes";
import Protected from "../components/shared/Protected/Protected";
import { useSelector } from "react-redux";
import ScrollToTop from "../components/shared/Scroll-To-Top/ScrollToTopService";

function Index() {
  const isAuth = useSelector((state) => state.user.auth);
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Suspense fallback="...Loading">
        <Routes>
          {mainRoutes.map((route, ind) => {
            if (route && route.children) {
              return (
                route.element && (
                  <Route
                    key={ind}
                    path={route.path}
                    exact={route.exact ? route.exact : false}
                    element={
                      route.path === "/admin" ? (
                        <Protected isAuth={isAuth}>
                          {" "}
                          <route.element />
                        </Protected>
                      ) : (
                        <route.element />
                      )
                    }
                  >
                    {route.children.map((childRoute, index) => {
                      return (
                        <Route
                          key={index}
                          path={childRoute.path}
                          exact={childRoute.exact ? childRoute.exact : false}
                          name={childRoute.name}
                          element={
                            route.path === "/admin" ? (
                              <Protected isAuth={isAuth}>
                                <childRoute.element />
                              </Protected>
                            ) : (
                              <childRoute.element />
                            )
                          }
                        />
                      );
                    })}
                  </Route>
                )
              );
            } else {
              return (
                route.element && (
                  <Route
                    key={ind}
                    path={route.path}
                    exact={route.exact ? route.exact : false}
                    name={route.name}
                    element={
                      route.path === "/admin" ? (
                        <Protected isAuth={isAuth}>
                          {" "}
                          <route.element />
                        </Protected>
                      ) : (
                        <route.element />
                      )
                    }
                  />
                )
              );
            }
          })}
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default React.memo(Index);
