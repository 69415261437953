export const toastPosition = {
  position: "top-center",
  autoClose: 700,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: 0,
  theme: "colored",
};
