import React, { useEffect, useState } from "react";
import {
  deleteEngQuickLink,
  deleteNepQuickLink,
  getAllQuickLinks,
  updateEngQuickLink,
  updateNepQuickLink,
} from "../../../../services/api";
import { ToastContainer } from "react-toastify";
import { toastError, toastSuccess } from "../../../../services/ToastService";

const QuickLinks = () => {
  const [isCollapsed, setIsCollapsed] = useState([]);
  const [formsFieldsEnglish, setformsFieldsEnglish] = useState({
    quicklink: [
      {
        _id: "",
        title: "",
        href: "",
      },
    ],
  });

  const [formsFieldsNepali, setformsFieldsNepali] = useState({
    quicklink: [
      {
        _id: "",
        title: "",
        href: "",
      },
    ],
  });

  const getQuickLink = async () => {
    try {
      let res = await getAllQuickLinks();
      if (res.data) {
        res.data.forEach((item) => {
          if (item.locale === "eng") {
            setformsFieldsEnglish((prevState) => ({
              ...prevState,
              quicklink: item.quicklink,
            }));
          } else if (item.locale === "nep") {
            setformsFieldsNepali((prevState) => ({
              ...prevState,
              quicklink: item.quicklink,
            }));
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleEnglishInputChange = (index, event) => {
    const { name, value } = event.target;
    setformsFieldsEnglish((prevState) => {
      const updatedQuickLink = [...prevState.quicklink];
      updatedQuickLink[index][name] = value;
      return { ...prevState, quicklink: updatedQuickLink };
    });
  };

  const handleNepaliInputChange = (index, event) => {
    const { name, value } = event.target;
    setformsFieldsNepali((prevState) => {
      const updatedQuickLink = [...prevState.quicklink];
      updatedQuickLink[index][name] = value;
      return { ...prevState, quicklink: updatedQuickLink };
    });
  };

  const handleAddQuickLink = () => {
    setformsFieldsEnglish({
      ...formsFieldsEnglish,
      quicklink: [
        ...formsFieldsEnglish.quicklink,
        {
          _id: "",
          title: "",
          href: "",
        },
      ],
    });

    setformsFieldsNepali({
      ...formsFieldsNepali,
      quicklink: [
        ...formsFieldsNepali.quicklink,
        {
          _id: "",
          title: "",
          href: "",
        },
      ],
    });
  };

  const handleRemoveQuickLink = async (index, nepalilink, englishlink) => {
    if (
      nepalilink._id &&
      nepalilink._id !== "" &&
      englishlink._id &&
      englishlink._id !== ""
    ) {
      await deleteNepQuickLink(nepalilink._id);
      await deleteEngQuickLink(englishlink._id);

      removeQuickLink(index);
    } else {
      removeQuickLink(index);
    }
  };

  const removeQuickLink = (index) => {
    const updatedEngQuickLink = [...formsFieldsEnglish.quicklink];
    updatedEngQuickLink.splice(index, 1);
    setformsFieldsEnglish({
      ...formsFieldsEnglish,
      quicklink: updatedEngQuickLink,
    });

    const updatedNepQuickLink = [...formsFieldsNepali.quicklink];
    updatedNepQuickLink.splice(index, 1);
    setformsFieldsNepali({
      ...formsFieldsNepali,
      quicklink: updatedNepQuickLink,
    });
  };

  useEffect(() => {
    setIsCollapsed(new Array(formsFieldsEnglish.quicklink.length).fill(true));
  }, [formsFieldsEnglish.quicklink.length]);

  useEffect(() => {
    getQuickLink(); // Call getAllOurwork function when the component mounts
  }, []);

  const toggleCollapse = (index) => {
    setIsCollapsed((prevState) =>
      prevState.map((state, i) => (i === index ? !state : state))
    );
  };

  const handleSaveLink = async (index, e) => {
    e.preventDefault();
    try {
      if (formsFieldsEnglish) {
        const formData = new FormData();
        formData.append("title", formsFieldsEnglish.quicklink[index].title);
        formData.append("href", formsFieldsEnglish.quicklink[index].href);
        formData.append("id", formsFieldsEnglish.quicklink[index]._id);
        await updateEngQuickLink(formData);
      }

      if (formsFieldsNepali) {
        const formData = new FormData();
        formData.append("title", formsFieldsNepali.quicklink[index].title);
        formData.append("href", formsFieldsNepali.quicklink[index].href);
        formData.append("id", formsFieldsNepali.quicklink[index]._id);
        await updateNepQuickLink(formData);
      }
      toastSuccess();
    } catch (error) {
      console.log(error);
      toastError();
    }
  };

  return (
    <main className="main">
      <div className="pagetitle">
        <h1>Latest News</h1>
        <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="index.html">Auth</a>
            </li>
            <li className="breadcrumb-item">Components</li>
            <li className="breadcrumb-item active">Quick Link</li>
          </ol>
        </nav>
      </div>
      <section className="section">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                {formsFieldsEnglish.quicklink.map((link, index) => (
                  <React.Fragment key={index}>
                    <div className="parameters-container">
                      <div
                        className="parameters-header"
                        onClick={() => toggleCollapse(index)}
                      >
                        <span className="parameters-title">
                          {index + 1}. Quick Link
                        </span>
                        <div className="icon-containers">
                          <button className="parameters-toggle-btn">
                            <i
                              onClick={handleAddQuickLink}
                              class="bi bi-plus-lg"
                            ></i>
                          </button>
                          <button className="parameters-toggle-btn">
                            <i
                              onClick={() =>
                                handleRemoveQuickLink(
                                  index,
                                  formsFieldsNepali.quicklink[index],
                                  link
                                )
                              }
                              class="bi bi-trash3"
                            ></i>
                          </button>
                          <button
                            className="parameters-toggle-btn"
                            id={`toggleButton${index}`}
                          >
                            {isCollapsed[index] ? "\u25BC" : "\u25B2"}
                          </button>
                        </div>
                      </div>
                      <div
                        className="parameters-body"
                        id={`parametersBody${index}`}
                        style={{
                          display: isCollapsed[index] ? "none" : "block",
                        }}
                      >
                        <div className=" border border-dark rounded">
                          <div className="d-flex mt-3 container">
                            <h5 className="card-title">
                              {index + 1}. Quick Link
                            </h5>
                            <hr className="border-2" />
                            <div className=" justify-content-end">
                              <button
                                onClick={(e) => handleSaveLink(index, e)}
                                className="btn btn-primary"
                              >
                                Save
                              </button>
                            </div>
                          </div>
                          <div className=" row m-2 ">
                            <div className="col-md-6">
                              <h4 className="card-title">English </h4>
                              <hr className="border-2" />
                              <div className=" mb-3">
                                <label className="form-label">Title</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={link.title}
                                  name="title"
                                  onChange={(e) =>
                                    handleEnglishInputChange(index, e)
                                  }
                                />
                              </div>
                            </div>

                            <div className="col-md-6">
                              <h4 className="card-title">Nepali </h4>
                              <hr className="border-2" />
                              <div className="mb-3">
                                <label className="form-label">Title</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value={
                                    formsFieldsNepali.quicklink[index].title
                                  }
                                  name="title"
                                  onChange={(e) =>
                                    handleNepaliInputChange(index, {
                                      target: {
                                        name: "title",
                                        value: e.target.value,
                                      },
                                    })
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="mb-3">
                              <label className="form-label">Link</label>
                              <input
                                type="text"
                                className="form-control"
                                value={formsFieldsNepali.quicklink[index].href}
                                name="href"
                                onChange={(e) => {
                                  handleNepaliInputChange(index, {
                                    target: {
                                      name: "href",
                                      value: e.target.value,
                                    },
                                  });
                                  handleEnglishInputChange(index, e);
                                }}
                              />
                            </div>
                          </div>
                          <div className="m-3 d-flex justify-content-end  ">
                            <button
                              onClick={() =>
                                handleRemoveQuickLink(
                                  index,
                                  formsFieldsNepali.quicklink[index],
                                  link
                                )
                              }
                              className="btn btn-primary"
                            >
                              Remove Work
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>

                    <br />
                  </React.Fragment>
                ))}

                <div className="mt-3 mb-3 d-flex justify-content-end">
                  <button
                    className="btn btn-primary"
                    onClick={handleAddQuickLink}
                  >
                    Add Work
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ToastContainer />
    </main>
  );
};

export default QuickLinks;
