import React, { useEffect, useState } from "react";
import {
  getAllGeoMaps,
  getGeoMapData,
  getGeographicalCoverage,
} from "../../services/api";

import ProvinceMap from "./Custom_Nepal_Map_Component/ProvinceMap";

function GeographicalCoverage() {
  const [selectedProvinceData, setSelectedProvinceData] = useState("");
  const [geoMapData, setGeoMapData] = useState([]);

  const [geographicalCoverage, setGeographicalCoverage] = useState({
    districts: "",
    RMs: "",
    PNGOs: "",
    schools: "",
  });

  const [locale, setLocale] = useState("eng");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getGeographicalCoverage();
        if (res) {
          setGeographicalCoverage(res.data.geographicalCoverage);
          setSelectedProvinceData(res.data.geographicalCoverage);
        }

        const response = await getGeoMapData();
        if (response.data.map) {
          setGeoMapData(response.data.map);

          // Calculate total sum for districts, RMs, PNGOs, and schools with default values
          // const totalDistricts = response.data.map.reduce(
          //   (sum, item) => sum + (parseInt(item.districts, 10) || 0),
          //   0
          // );
          // const totalRMs = response.data.map.reduce(
          //   (sum, item) => sum + (parseInt(item.RMs, 10) || 0),
          //   0
          // );
          // const totalPNGOs = response.data.map.reduce(
          //   (sum, item) => sum + (parseInt(item.PNGOs, 10) || 0),
          //   0
          // );
          // const totalSchools = response.data.map.reduce(
          //   (sum, item) => sum + (parseInt(item.schools, 10) || 0),
          //   0
          // );

          // setGeographicalCoverage({
          //   districts: totalDistricts,
          //   RMs: totalRMs,
          //   PNGOs: totalPNGOs,
          //   schools: totalSchools,
          // });
        }
      } catch (error) {}
    };

    let locale = localStorage.getItem("locale")
      ? localStorage.getItem("locale")
      : "eng";
    setLocale(locale);

    fetchData();
  }, []);

  const handleProvinceHover = (provinceData) => {
    let selectedData = geoMapData.find(
      (item) => item.provinceName === provinceData.name
    );

    setSelectedProvinceData(selectedData);
  };

  const handleProvinceMouseOut = (value) => {
    if (value && value.isMouseOut) {
      setSelectedProvinceData(geographicalCoverage);
    }
  };

  return (
    <section className="mt-5 geo-coverage-section">
      <div className="container py-5">
        {locale === "eng" ? (
          <h2 className="heading text-center text-blue">
            Geographical Coverage
          </h2>
        ) : (
          <h2 className="heading text-center text-blue">भूगोलिक कवरेज</h2>
        )}

        <div className="d-flex justify-content-between flex-wrap mt-5">
          <div className="geo-coverage-subtitle"></div>
          <div className="d-flex align-items-center flex-wrap">
            <div className="d-flex flex-column align-items-center p-2 border-right">
              <h4 className="text-blue"> {geographicalCoverage.districts} </h4>
              {locale === "eng" ? <h6>Districts</h6> : <h6>जिल्लाहरू</h6>}
            </div>
            <div className="d-flex flex-column align-items-center p-2 border-right">
              <h4 className="text-blue"> {geographicalCoverage.RMs} </h4>
              {locale === "eng" ? (
                <h6>Municipalities/ Cities</h6>
              ) : (
                <h6>नगरपालिकाहरू/ शहरहरू</h6>
              )}
            </div>
            <div className="d-flex flex-column align-items-center p-2">
              <h4 className="text-blue"> {geographicalCoverage.PNGOs} </h4>
              {locale === "eng" ? <h6>Projects</h6> : <h6>परियोजनाहरू</h6>}
            </div>
          </div>
        </div>
      </div>
      <div className="geo-blue-bg">
        <div className="nepal-map">
          <ProvinceMap
            hoverColor="white"
            value="Chauri"
            stroke="#000"
            provinceColor={[
              "#3fa9f5",
              "#92cdea",
              "#62baef",
              "#3fa9f5",
              "#92cdea",
              "#62baef",
              "#3fa9f5",
            ]}
            strokeWidth={1}
            onMapHover={(val) => handleProvinceHover(val)}
            onMapMouseOut={(val) => handleProvinceMouseOut(val)}
          />
        </div>

        <div className="geo-legend">
          <div className="legend-item">
            <div
              className="legend-color"
              style={{ backgroundColor: "red" }}
            ></div>

            {locale === "eng" ? (
              <div className="legend-text">
                Districts:{" "}
                {selectedProvinceData && selectedProvinceData.districts
                  ? selectedProvinceData && selectedProvinceData.districts
                  : ""}
              </div>
            ) : (
              <div className="legend-text">
                जिल्लाहरू:{" "}
                {selectedProvinceData && selectedProvinceData.districts
                  ? selectedProvinceData && selectedProvinceData.districts
                  : ""}
              </div>
            )}
          </div>
          <div className="legend-item">
            <div
              className="legend-color"
              style={{ backgroundColor: "green" }}
            ></div>

            {locale === "eng" ? (
              <div className="legend-text">
                Municipalities/ Cities:{" "}
                {selectedProvinceData && selectedProvinceData.RMs
                  ? selectedProvinceData && selectedProvinceData.RMs
                  : ""}
              </div>
            ) : (
              <div className="legend-text">
                नगरपालिकाहरू/ शहरहरू:{" "}
                {selectedProvinceData && selectedProvinceData.RMs
                  ? selectedProvinceData && selectedProvinceData.RMs
                  : ""}
              </div>
            )}
          </div>
          <div className="legend-item">
            <div
              className="legend-color"
              style={{ backgroundColor: "yellow" }}
            ></div>

            {locale === "eng" ? (
              <div className="legend-text">
                Projects:{" "}
                {selectedProvinceData && selectedProvinceData.PNGOs
                  ? selectedProvinceData && selectedProvinceData.PNGOs
                  : ""}
              </div>
            ) : (
              <div className="legend-text">
                परियोजनाहरू:{" "}
                {selectedProvinceData && selectedProvinceData.PNGOs
                  ? selectedProvinceData && selectedProvinceData.PNGOs
                  : ""}
              </div>
            )}
          </div>
          <div className="legend-item">
            <div className="legend-color" style={{ backgroundColor: "" }}></div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default GeographicalCoverage;
