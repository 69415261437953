import React, { useEffect, useState } from "react";
import { getFAQ } from "../../services/api";
import Accessibility from "./Accessibility";

const FAQ = () => {
  const [FAQ, setFAQ] = useState({});

  const fetchData = async () => {
    try {
      const response = await getFAQ();
      setFAQ(response.data);
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      <section>
        <Accessibility />

        <div>
          <div dangerouslySetInnerHTML={{ __html: FAQ.content }} />
        </div>
      </section>
    </>
  );
};

export default FAQ;
