// All this routes has to be lazy loaded when goes to Production
import Home from "../pages/home/HomePageLayout";
import Login from "../pages/login/LoginPageLayout";

import NewsLetterUserList from "../components/auth/admin/section/NewLetter/NewsLetterUserList.jsx";
import MapNewsLetterUserGroup from "../components/auth/admin/section/NewLetter/mapNewsLetterUserGroup.jsx";
import Admin from "../pages/admin/AdminPage.jsx";
import NewsLetterGroupList from "../components/auth/admin/section/NewLetter/newslettergroup/NewsLetterGroupList.jsx";
import Slider from "../components/auth/admin/section/Slider.jsx";
import Stories from "../components/auth/admin/section/Stories.jsx";
import FooterAdmin from "../components/auth/admin/section/Footer.jsx";

import OurValues from "../components/auth/admin/section/OurValues.jsx";
import OurImpact from "../components/auth/admin/section/OurImpact.jsx";
import TheJourney from "../components/auth/admin/section/TheJourney.jsx";
import OurPartner from "../components/auth/admin/section/OurPartners.jsx";
import LandingPageLayout from "../components/home/LandingPageLayout.jsx";
import LearnMore from "../components/home/homePageRoutingSection/LearnMore.jsx";
import GeographicalCoverage from "../components/auth/admin/section/GeographicalCoverage.jsx";
import OurWork from "../components/auth/admin/section/OurWork.jsx";
import YourSupport from "../components/auth/admin/section/YourSupport.jsx";
import OurWorkLearnMore from "../components/home/homePageRoutingSection/OurWorkLearnMore.jsx";
import OurWorkViewAll from "../components/home/homePageRoutingSection/OurWorkViewAll.jsx";
import LatestNewsViewAll from "../components/home/homePageRoutingSection/LatestNewsViewAll.jsx";
import OurStoriesViewAll from "../components/home/homePageRoutingSection/OurStoriesViewAll.jsx";
import AboutUs from "../components/home/AboutUs.jsx";
import AboutUsAdmin from "../components/auth/admin/section/AboutUs.jsx";
import GetInvolved from "../components/auth/admin/section/GetInvolved.jsx";
import Resources from "../components/auth/admin/section/Resources.jsx";
import { ResourcesHome } from "../components/home/ResourcesHome.jsx";
import { GetInvolvedHome } from "../components/home/GetInvolvedHome.jsx";
import OurProgram from "../components/auth/admin/section/OurProgram.jsx";
import OurProgramHome from "../components/home/OurProgramHome.jsx";
import ContactUs from "../components/auth/admin/section/ContactUs.jsx";
import ContactUsHome from "../components/home/ContactUsHome.jsx";
import GetInTouches from "../components/auth/admin/GetInTouches.jsx";
import LatestNews from "../components/auth/admin/section/LatestNews.jsx";
import LatestNewsReadMore from "../components/home/homePageRoutingSection/LatestNewsReadMore.jsx";
import DonationHome from "../components/home/DonationHome.jsx";
import NavbarImages from "../components/auth/admin/section/NavbarImages.jsx";
import NavbarMenuImages from "../components/auth/admin/section/NavbarMenuImages.jsx";
import Donation from "../components/auth/admin/section/Donation.jsx";
import NewsPaperGroupMappingForm from "../components/auth/admin/section/NewsPaperGroupMappingForm.jsx";
import SocialLinks from "../components/auth/admin/section/SocialLinks.jsx";
import PopupPage from "../components/auth/admin/section/PopupPage.jsx";
import AboutUsOurPartners from "../components/home/AboutUsOurPartners.jsx";
import AboutUsHistory from "../components/auth/admin/section/AboutUsHistory.jsx";
import AboutUsIntroduction from "../components/auth/admin/section/AboutUsIntroduction.jsx";
import AboutUsThematicAreas from "../components/auth/admin/section/AboutUsThematicAreas.jsx";
import AboutUsOurValues from "../components/auth/admin/section/AboutUsOurValues.jsx";
import AboutUsOurApproach from "../components/auth/admin/section/AboutUsOurApproach.jsx";
import AboutUsHistoryHome from "../components/home/AboutUsHistory.jsx";
import AboutUsIntroductionHome from "../components/home/AboutUsIntroduction.jsx";
import MentalHealth from "../components/auth/admin/section/MentalHealth.jsx";
import StrategicPlan from "../components/auth/admin/section/StrategicPlan.jsx";
import AboutUsOurStrategicPlan from "../components/home/AboutUsOurStrategicPlan.jsx";
import TakeAMentalHealth from "../components/home/homePageRoutingSection/TakeAMentalHealth.jsx";
import OurPartnerReadMore from "../components/home/homePageRoutingSection/OurPartnerReadMore.jsx";
import FAQ from "../components/auth/admin/section/FooterLinksPages/FAQ.jsx";
import TermsOfService from "../components/auth/admin/section/FooterLinksPages/TermsOfService.jsx";
import PrivacyPolicy from "../components/auth/admin/section/FooterLinksPages/PrivacyPolicy.jsx";
import FAQHome from "../components/home/FAQ.jsx";
import TermsOfServiceHome from "../components/home/TermsOfService.jsx";
import PrivacyPolicyHome from "../components/home/PrivacyPolicy.jsx";
import Branding from "../components/auth/admin/section/Branding.jsx";
import NeedHelp from "../components/auth/admin/section/NeedHelp.jsx";
import NeedHelpHome from "../components/home/homePageRoutingSection/NeedHelp.jsx";
import PageNotFound from "../components/shared/Page-Not-Found/PageNotFound.jsx";
import GetInvolvedVacancyById from "../components/home/homePageRoutingSection/GetInvolvedVacancyById.jsx";
import GetInvolvedVolenteerById from "../components/home/homePageRoutingSection/GetInvolvedVolenteerById.jsx";
import PublicationAdmin from "../components/auth/admin/section/PublicationAdmin.jsx";
import PublicationById from "../components/home/homePageRoutingSection/PublicationById.jsx";
import QuickLinks from "../components/auth/admin/section/QuickLinks.jsx";
import Blog from "../components/auth/admin/section/Blog.jsx";
import BlogReadMore from "../components/home/homePageRoutingSection/BlogReadMore.jsx";
import OurStoriesReadMore from "../components/home/homePageRoutingSection/OurStoriesReadMore.jsx";
import NavbarTextAdmin from "../components/auth/admin/section/NavbarTextAdmin.jsx";
import GeoColor from "../components/auth/admin/section/GeoColor.jsx";
import DonationDetailPage from "../components/auth/admin/section/DonationDetailPage.jsx";
// import DynamicPageComponent from "../components/auth/admin/section/MenuItems.jsx";

export const mainRoutes = [
  {
    path: "/",
    name: "Home Page",
    element: Home,
    children: [
      {
        path: "",
        name: "Landing page",
        element: LandingPageLayout,
      },
      {
        path: "/learnMore",
        name: "Learn More page",
        element: LearnMore,
      },
      {
        path: "/ourWork/learnMore/:id",
        name: "Our Work Learn More page",
        element: OurWorkLearnMore,
      },
      {
        path: "/latestnews/readMore/:id",
        name: "Latest News Read More page",
        element: LatestNewsReadMore,
      },
      {
        path: "/ourWork/viewAll",
        name: "Our Work View All",
        element: OurWorkViewAll,
      },
      {
        path: "/latestNews/viewAll",
        name: "Latest News View All",
        element: LatestNewsViewAll,
      },
      {
        path: "/stories/readmore",
        name: "Our Stories Read More",
        element: OurStoriesViewAll,
      },
      {
        path: "/stories/readmore/:id",
        name: "Our Stories Read More",
        element: OurStoriesReadMore,
      },
      {
        path: "/aboutUs/:fieldName",
        name: "About Us",
        element: AboutUs,
      },
      {
        path: "/resources/:fieldName",
        name: "Resources",
        element: ResourcesHome,
      },
      {
        path: "/getInvolved/:fieldName",
        name: "Get Involved ",
        element: GetInvolvedHome,
      },
      {
        path: "/ourProgram/:programType",
        name: "Our Program  ",
        element: OurProgramHome,
      },
      {
        path: "/contactUs",
        name: "Contact Us  ",
        element: ContactUsHome,
      },
      {
        path: "/donation",
        name: "Donation home ",
        element: DonationHome,
      },
      {
        path: "aboutUsOurPartners",
        name: "Our Partners Page",
        element: AboutUsOurPartners,
      },
      {
        path: "/about-us/history",
        name: "About Us History  Page",
        element: AboutUsHistoryHome,
      },
      {
        path: "/about-us/introduction",
        name: "About Us Introduction  Page",
        element: AboutUsIntroductionHome,
      },
      {
        path: "/aboutUsOurStrategicPlan",
        name: "About Strategic Plan",
        element: AboutUsOurStrategicPlan,
      },
      {
        path: "/mentalHealth",
        name: "Take a mental Health test",
        element: TakeAMentalHealth,
      },
      {
        path: "/ourPartner/readMore/:id",
        name: "ourPartner Read More page",
        element: OurPartnerReadMore,
      },
      {
        path: "/FAQ",
        name: "FAQ",
        element: FAQHome,
      },
      {
        path: "/termsOfService",
        name: "Terms of Service",
        element: TermsOfServiceHome,
      },
      {
        path: "/privacyPolicy",
        name: "Privacy Policy",
        element: PrivacyPolicyHome,
      },
      {
        path: "needhelp",
        name: "Need Help",
        element: NeedHelpHome,
      },
      {
        path: "/getInvolved/vacancy/:id",
        name: "Individual Vacancy",
        element: GetInvolvedVacancyById,
      },
      {
        path: "/getInvolved/volenteer/:id",
        name: "Individual Vacancy",
        element: GetInvolvedVolenteerById,
      },
      {
        path: "/publication/:id",
        name: "Publication By Id",
        element: PublicationById,
      },
      {
        path: "/blog/readMore/:id",
        name: "Blog Read More page",
        element: BlogReadMore,
      },
    ],
  },
  { path: "/login", name: "Login Page", exact: true, element: Login },
  { path: "*", name: "Page Not Found", element: PageNotFound },

  {
    path: "/admin",
    name: "Admin Page",
    exact: true,
    element: Admin,
    children: [
      {
        path: "/admin/slider",
        name: "Admin Slider",
        exact: true,
        element: Slider,
      },
      {
        path: "/admin/stories",
        name: "Admin Stories",
        exact: true,
        element: Stories,
      },
      {
        path: "/admin/ourValues",
        name: "Admin Our Values",
        exact: true,
        element: OurValues,
      },
      {
        path: "/admin/ourImpact",
        name: "Admin Our Impact",
        exact: true,
        element: OurImpact,
      },
      {
        path: "/admin/theJourney",
        name: "Admin The Journey",
        exact: true,
        element: TheJourney,
      },
      {
        path: "/admin/ourPartners",
        name: "Admin Our Partners",
        exact: true,
        element: OurPartner,
      },
      {
        path: "/admin/footer",
        name: "Admin Footer",
        exact: true,
        element: FooterAdmin,
      },
      {
        path: "/admin/nwusersgroupmap",
        name: "News Letter Work page",
        exact: true,
        element: MapNewsLetterUserGroup,
      },
      {
        path: "/admin/nwusers",
        name: "News Letter Work page",
        exact: true,
        element: NewsLetterUserList,
      },
      {
        path: "/admin/nwgroups",
        name: "News Letter Work page",
        exact: true,
        element: NewsLetterGroupList,
      },

      {
        path: "/admin/geoCoverage",
        name: "Geographical Coverage Page",
        element: GeographicalCoverage,
      },
      {
        path: "/admin/ourWork",
        name: "Our Work Page",
        element: OurWork,
      },
      {
        path: "/admin/yourSupport",
        name: "Your Support Page",
        element: YourSupport,
      },
      {
        path: "/admin/aboutUs",
        name: "Admin About Us",
        exact: true,
        element: AboutUsAdmin,
      },
      {
        path: "getInvolved",
        name: "Get Involved",
        element: GetInvolved,
      },
      {
        path: "resources",
        name: "Get Involved",
        element: Resources,
      },
      {
        path: "ourProgram",
        name: "Get Involved",
        element: OurProgram,
      },
      {
        path: "contactUs",
        name: "Get Involved",
        element: ContactUs,
      },
      {
        path: "getInTouch",
        name: "Get In Touch  ",
        element: GetInTouches,
      },
      {
        path: "latestnews",
        name: "Latest News",
        element: LatestNews,
      },
      {
        path: "navImages",
        name: "Navbar Images",
        element: NavbarImages,
      },
      {
        path: "donation",
        name: "Donation ",
        element: Donation,
      },
      {
        path: "nlgmf",
        name: "News Letter Group",
        element: NewsPaperGroupMappingForm,
      },
      {
        path: "socialLinks",
        name: "socialLinks",
        element: SocialLinks,
      },
      {
        path: "popup",
        name: "Popup Page",
        element: PopupPage,
      },
      {
        path: "aboutUsHistory",
        name: "AboutUs History Page",
        element: AboutUsHistory,
      },
      {
        path: "aboutUsIntroduction",
        name: "AboutUs Introduction Page",
        element: AboutUsIntroduction,
      },
      {
        path: "aboutUsThematicArea",
        name: "AboutUs Thematic Area Page",
        element: AboutUsThematicAreas,
      },
      {
        path: "aboutUsOurValues",
        name: "AboutUs Our Values Page",
        element: AboutUsOurValues,
      },
      {
        path: "aboutUsOurApproach",
        name: "AboutUs Our Approach Page",
        element: AboutUsOurApproach,
      },
      {
        path: "mentalHealth",
        name: "Take a Mental Health",
        element: MentalHealth,
      },

      {
        path: "strategicPlan",
        name: "Our Strategic Plan",
        element: StrategicPlan,
      },
      {
        path: "FAQ",
        name: "FAQ",
        element: FAQ,
      },
      {
        path: "termsOfService",
        name: "Terms of Service",
        element: TermsOfService,
      },
      {
        path: "privacyPolicy",
        name: "Privacy Policy",
        element: PrivacyPolicy,
      },
      {
        path: "branding",
        name: "Branding",
        element: Branding,
      },
      {
        path: "needhelp",
        name: "Need Help",
        element: NeedHelp,
      },
      {
        path: "bannerimage",
        name: "Banner Image",
        element: NavbarMenuImages,
      },
      {
        path: "publication",
        name: " Publication",
        element: PublicationAdmin,
      },
      {
        path: "quicklink",
        name: " Quick Links",
        element: QuickLinks,
      },
      {
        path: "blog",
        name: "Blog",
        element: Blog,
      },
      {
        path: "navbartext",
        name: "Navbar and Header Text",
        element: NavbarTextAdmin,
      },
      {
        path: "geocolor",
        name: "Geographical coverage colors",
        element: GeoColor,
      },
      {
        path: "donationdetails",
        name: "Donation Detail Page",
        element: DonationDetailPage,
      },
      // {
      //   path: "menuitems",
      //   name: "Dynamic Page",
      //   element: DynamicPageComponent,
      // },
    ],
  },
];
