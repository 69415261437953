import React from "react";
import provinceMapData from "./provinceMapData";
import {
  getRandomColor,
  defaultColor,
  defaultProvinceColor,
} from "./helpers/color";

export default function ProvinceMap({
  onMapClick,
  onMapHover,
  onMapMouseOut,
  randomSectorColor,
  sectorClassName,
  containerClassName,
  color,
  hoverColor,
  provinceColor,
  stroke,
  strokeWidth,
}) {
  const handleMapClick = (item) => {
    if (onMapClick) {
      onMapClick({ name: item.name, zip: item.zip });
    }
  };

  const handleMapHover = (event, item) => {
    event.target.style.fill = hoverColor || defaultColor;
    if (onMapHover) {
      onMapHover({ name: item.name, zip: item.zip });
    }
  };

  const handleMapMouseOut = (event, item, pathColor) => {
    event.target.style.fill = pathColor;
    if (onMapMouseOut) {
      onMapMouseOut({ isMouseOut: true });
    }
  };

  return (
    <div style={{ maxWidth: "100%" }} className={containerClassName || ""}>
      <svg viewBox="0 0 1029.19 522.34">
        {provinceMapData.map((item, index) => {
          const pathColorList = provinceColor || defaultProvinceColor;
          let pathColor =
            pathColorList.length > index
              ? pathColorList[index]
              : defaultProvinceColor[index];
          if (color) pathColor = color;
          if (randomSectorColor) pathColor = getRandomColor();

          return (
            <path
              className={sectorClassName || ""}
              style={{ cursor: "pointer", fill: pathColor }}
              key={index}
              stroke={stroke || "#000"}
              strokeWidth={strokeWidth || "1px"}
              d={item.shape}
              onMouseOver={(event) => handleMapHover(event, item)}
              onClick={() => handleMapClick(item)}
              onMouseOut={(event) => handleMapMouseOut(event, item, pathColor)}
            ></path>
          );
        })}
      </svg>
    </div>
  );
}
