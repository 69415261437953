import React, { useEffect, useRef, useState } from "react";
import { ToastContainer } from "react-toastify";
import {
  getAllAboutUsIntrocuction,
  saveEnglishIntroduction,
  saveNepaliIntroduction,
} from "../../../../services/api";
import { toastError, toastSuccess } from "../../../../services/ToastService";

const AboutUsIntroduction = () => {
  const introductionImgRef = useRef();

  const [englishIntroduction, setEnglishIntroduction] = useState({
    introductionTitle: "",
    introductionDescription: "",
    ourVisionTitle: "",
    ourVisionDescription: "",
    ourMissionTitle: "",
    ourMissionDescription: "",
    ourGoalTitle: "",
    ourGoalDescription: "",
    imageTitle: "",
    image: "",
  });

  const [nepaliIntroduction, setNepaliIntroduction] = useState({
    introductionTitle: "",
    introductionDescription: "",
    ourVisionTitle: "",
    ourVisionDescription: "",
    ourMissionTitle: "",
    ourMissionDescription: "",
    ourGoalTitle: "",
    ourGoalDescription: "",
    imageTitle: "",
    image: "",
  });
  const shouldUploadFile = (fileRef, fileType) => {
    if (!fileRef.current || fileRef.current.files.length === 0) {
      return false;
    }
    const file = fileRef.current.files[0];
    return file && file.type.startsWith(fileType + "/");
  };

  const getAllIntroduction = async () => {
    try {
      let res = await getAllAboutUsIntrocuction();
      if (res.data) {
        res.data.forEach((item) => {
          if (item.locale === "eng") {
            setEnglishIntroduction((prevState) => ({
              ...prevState,
              introductionTitle: item.introductionTitle,
              introductionDescription: item.introductionDescription,
              ourVisionTitle: item.ourVisionTitle,
              ourVisionDescription: item.ourVisionDescription,
              ourMissionTitle: item.ourMissionTitle,
              ourMissionDescription: item.ourMissionDescription,
              ourGoalTitle: item.ourGoalTitle,
              ourGoalDescription: item.ourGoalDescription,
              imageTitle: item.imageTitle,
              image: item.image,
            }));
          } else if (item.locale === "nep") {
            setNepaliIntroduction((prevState) => ({
              ...prevState,
              introductionTitle: item.introductionTitle,
              introductionDescription: item.introductionDescription,
              ourVisionTitle: item.ourVisionTitle,
              ourVisionDescription: item.ourVisionDescription,
              ourMissionTitle: item.ourMissionTitle,
              ourMissionDescription: item.ourMissionDescription,
              ourGoalTitle: item.ourGoalTitle,
              ourGoalDescription: item.ourGoalDescription,
              imageTitle: item.imageTitle,
              image: item.image,
            }));
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getAllIntroduction();
  }, []);

  const onSubmit = async (e) => {
    e.stopPropagation();
    e.preventDefault();
    if (shouldUploadFile(introductionImgRef, "image")) {
    }
    try {
      if (englishIntroduction) {
        const formData = new FormData();
        formData.append(
          "introductionTitle",
          englishIntroduction.introductionTitle
        );
        formData.append(
          "introductionDescription",
          englishIntroduction.introductionDescription
        );
        formData.append("ourVisionTitle", englishIntroduction.ourVisionTitle);
        formData.append(
          "ourVisionDescription",
          englishIntroduction.ourVisionDescription
        );
        formData.append("ourMissionTitle", englishIntroduction.ourMissionTitle);
        formData.append(
          "ourMissionDescription",
          englishIntroduction.ourMissionDescription
        );
        formData.append("ourGoalTitle", englishIntroduction.ourGoalTitle);
        formData.append(
          "ourGoalDescription",
          englishIntroduction.ourGoalDescription
        );
        formData.append("imageTitle", englishIntroduction.imageTitle);
        if (shouldUploadFile(introductionImgRef, "image")) {
          formData.append("image", introductionImgRef.current.files[0]);
        } else {
          formData.append("image", "");
        }

        await saveEnglishIntroduction(formData);
      }

      if (nepaliIntroduction) {
        const formData = new FormData();
        formData.append(
          "introductionTitle",
          nepaliIntroduction.introductionTitle
        );
        formData.append(
          "introductionDescription",
          nepaliIntroduction.introductionDescription
        );
        formData.append("ourVisionTitle", nepaliIntroduction.ourVisionTitle);
        formData.append(
          "ourVisionDescription",
          nepaliIntroduction.ourVisionDescription
        );
        formData.append("ourMissionTitle", nepaliIntroduction.ourMissionTitle);
        formData.append(
          "ourMissionDescription",
          nepaliIntroduction.ourMissionDescription
        );
        formData.append("ourGoalTitle", nepaliIntroduction.ourGoalTitle);
        formData.append(
          "ourGoalDescription",
          nepaliIntroduction.ourGoalDescription
        );
        formData.append("imageTitle", nepaliIntroduction.imageTitle);
        if (shouldUploadFile(introductionImgRef, "image")) {
          formData.append("image", introductionImgRef.current.files[0]);
        } else {
          formData.append("image", "");
        }
        await saveNepaliIntroduction(formData);
      }
      await getAllIntroduction();
      toastSuccess();
    } catch (error) {
      console.log(error);
      toastError();
    }
  };

  return (
    <>
      <main className="main">
        <div className="pagetitle">
          <h1>About Us Introduction</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="index.html">Auth</a>
              </li>
              <li className="breadcrumb-item">Components</li>
              <li className="breadcrumb-item active">About Us Introduction</li>
            </ol>
          </nav>
        </div>
        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Add </h5>
                  <hr className="border-2" />
                  <div className="row">
                    <div className="col-md-6 mb-3">
                      <label className="form-label">Image  (Best Fit Size: height: 250px; width: 350px;)</label>
                      <input
                        type="file"
                        accept=".jpg,.jpeg,.png,.gif"
                        ref={introductionImgRef}
                      />
                    </div>

                    <div className=" mb-3 col-md-6">
                      <img
                        style={{
                          height: "200px",
                          width: "300px",
                          objectFit: "contain",
                        }}
                        src={englishIntroduction.image}
                        alt="Uploaded Photograph"
                      />
                    </div>
                    <div className="col-md-6">
                      <h4 className="card-title">English </h4>
                      <hr className="border-2" />
                      <div className="mb-3">
                        <label className="form-label">Introduction Title</label>
                        <input
                          type="text"
                          className="form-control"
                          value={englishIntroduction.introductionTitle}
                          onChange={(e) =>
                            setEnglishIntroduction({
                              ...englishIntroduction,
                              introductionTitle: e.target.value,
                            })
                          }
                        />
                      </div>

                      <div className="mb-3">
                        <label className="form-label">
                          Introduction Description
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={englishIntroduction.introductionDescription}
                          onChange={(e) =>
                            setEnglishIntroduction({
                              ...englishIntroduction,
                              introductionDescription: e.target.value,
                            })
                          }
                        />
                      </div>

                      <div className="mb-3">
                        <label className="form-label">Our Vision Title</label>
                        <input
                          type="text"
                          className="form-control"
                          value={englishIntroduction.ourVisionTitle}
                          onChange={(e) =>
                            setEnglishIntroduction({
                              ...englishIntroduction,
                              ourVisionTitle: e.target.value,
                            })
                          }
                        />
                      </div>

                      <div className="mb-3">
                        <label className="form-label">
                          Our Vision Description
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={englishIntroduction.ourVisionDescription}
                          onChange={(e) =>
                            setEnglishIntroduction({
                              ...englishIntroduction,
                              ourVisionDescription: e.target.value,
                            })
                          }
                        />
                      </div>

                      <div className="mb-3">
                        <label className="form-label">Our Mission Title</label>
                        <input
                          type="text"
                          className="form-control"
                          value={englishIntroduction.ourMissionTitle}
                          onChange={(e) =>
                            setEnglishIntroduction({
                              ...englishIntroduction,
                              ourMissionTitle: e.target.value,
                            })
                          }
                        />
                      </div>

                      <div className="mb-3">
                        <label className="form-label">
                          Our Mission Description
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={englishIntroduction.ourMissionDescription}
                          onChange={(e) =>
                            setEnglishIntroduction({
                              ...englishIntroduction,
                              ourMissionDescription: e.target.value,
                            })
                          }
                        />
                      </div>

                      <div className="mb-3">
                        <label className="form-label">Our Goal Title</label>
                        <input
                          type="text"
                          className="form-control"
                          value={englishIntroduction.ourGoalTitle}
                          onChange={(e) =>
                            setEnglishIntroduction({
                              ...englishIntroduction,
                              ourGoalTitle: e.target.value,
                            })
                          }
                        />
                      </div>

                      <div className="mb-3">
                        <label className="form-label">
                          Our Goal Description
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={englishIntroduction.ourGoalDescription}
                          onChange={(e) =>
                            setEnglishIntroduction({
                              ...englishIntroduction,
                              ourGoalDescription: e.target.value,
                            })
                          }
                        />
                      </div>

                      <div className="mb-3">
                        <label className="form-label">Image Title</label>
                        <input
                          type="text"
                          className="form-control"
                          value={englishIntroduction.imageTitle}
                          onChange={(e) =>
                            setEnglishIntroduction({
                              ...englishIntroduction,
                              imageTitle: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <h4 className="card-title">Nepali </h4>
                      <hr className="border-2" />
                      <div className="mb-3">
                        <label className="form-label">Introduction Title</label>
                        <input
                          type="text"
                          className="form-control"
                          value={nepaliIntroduction.introductionTitle}
                          onChange={(e) =>
                            setNepaliIntroduction({
                              ...nepaliIntroduction,
                              introductionTitle: e.target.value,
                            })
                          }
                        />
                      </div>

                      <div className="mb-3">
                        <label className="form-label">
                          Introduction Description
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={nepaliIntroduction.introductionDescription}
                          onChange={(e) =>
                            setNepaliIntroduction({
                              ...nepaliIntroduction,
                              introductionDescription: e.target.value,
                            })
                          }
                        />
                      </div>

                      <div className="mb-3">
                        <label className="form-label">Our Vision Title</label>
                        <input
                          type="text"
                          className="form-control"
                          value={nepaliIntroduction.ourVisionTitle}
                          onChange={(e) =>
                            setNepaliIntroduction({
                              ...nepaliIntroduction,
                              ourVisionTitle: e.target.value,
                            })
                          }
                        />
                      </div>

                      <div className="mb-3">
                        <label className="form-label">
                          Our Vision Description
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={nepaliIntroduction.ourVisionDescription}
                          onChange={(e) =>
                            setNepaliIntroduction({
                              ...nepaliIntroduction,
                              ourVisionDescription: e.target.value,
                            })
                          }
                        />
                      </div>

                      <div className="mb-3">
                        <label className="form-label">Our Mission Title</label>
                        <input
                          type="text"
                          className="form-control"
                          value={nepaliIntroduction.ourMissionTitle}
                          onChange={(e) =>
                            setNepaliIntroduction({
                              ...nepaliIntroduction,
                              ourMissionTitle: e.target.value,
                            })
                          }
                        />
                      </div>

                      <div className="mb-3">
                        <label className="form-label">
                          Our Mission Description
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={nepaliIntroduction.ourMissionDescription}
                          onChange={(e) =>
                            setNepaliIntroduction({
                              ...nepaliIntroduction,
                              ourMissionDescription: e.target.value,
                            })
                          }
                        />
                      </div>

                      <div className="mb-3">
                        <label className="form-label">Our Goal Title</label>
                        <input
                          type="text"
                          className="form-control"
                          value={nepaliIntroduction.ourGoalTitle}
                          onChange={(e) =>
                            setNepaliIntroduction({
                              ...nepaliIntroduction,
                              ourGoalTitle: e.target.value,
                            })
                          }
                        />
                      </div>

                      <div className="mb-3">
                        <label className="form-label">
                          Our Goal Description
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          value={nepaliIntroduction.ourGoalDescription}
                          onChange={(e) =>
                            setNepaliIntroduction({
                              ...nepaliIntroduction,
                              ourGoalDescription: e.target.value,
                            })
                          }
                        />
                      </div>

                      <div className="mb-3">
                        <label className="form-label">Image Title</label>
                        <input
                          type="text"
                          className="form-control"
                          value={nepaliIntroduction.imageTitle}
                          onChange={(e) =>
                            setNepaliIntroduction({
                              ...nepaliIntroduction,
                              imageTitle: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div
                      style={{ marginTop: "10px" }}
                      className="d-flex justify-content-center"
                    >
                      <button
                        onClick={(e) => onSubmit(e)}
                        type="button"
                        className="btn btn-primary "
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <ToastContainer />
    </>
  );
};

export default AboutUsIntroduction;
