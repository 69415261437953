import React, { useEffect, useState } from "react";
import { getOurValuesHeading } from "../../services/ourValuesService";
import Inclusion from "../../assets/icon/Group 1.svg";
import Integrity from "../../assets/icon/Group 2.svg";
import Accessibility from "../../assets/icon/Group 3.svg";
import Dignity from "../../assets/icon/Group 4.svg";
import Partnership from "../../assets/icon/Group 5.svg";
import Equality from "../../assets/icon/Group 6.svg";
import Diversity from "../../assets/icon/Group 7.svg";
import Accountability from "../../assets/icon/Group 8.svg";

const OurValues = () => {
  const [ourValues, setOurValues] = useState();
  const [locale, setLocale] = useState("eng");

  async function fetchAllOurValues() {
    try {
      const response = await getOurValuesHeading();
      setOurValues(response.data.data);
    } catch (error) {}
  }
  useEffect(() => {
    fetchAllOurValues();
    let locale = localStorage.getItem("locale")
      ? localStorage.getItem("locale")
      : "eng";
    setLocale(locale);
  }, []);
  return (
    <>
      <section className="thematic-areas-section position-relative mt-5">
        <div className="border thematic-areas-content">
          <h2 className="heading text-center">
            {" "}
            {locale
              ? locale === "eng"
                ? "Our Values"
                : "हाम्रा मूल्यहरू "
              : "Our Values"}{" "}
          </h2>
          <div className="row">
            <div className="col-12 col-md-3 d-flex flex-column justify-content-center mb-5">
              <img src={Inclusion} alt="" />
              <h4 className="mt-3">
                {" "}
                {locale
                  ? locale === "eng"
                    ? ourValues && ourValues.contents[0].title
                      ? ourValues.contents[0].title
                      : "Empowerment"
                    : ourValues && ourValues.contents[0].titleNepali
                    ? ourValues.contents[0].titleNepali
                    : "Empowerment"
                  : "Empowerment"}{" "}
              </h4>
            </div>
            <div className="col-12 col-md-3 d-flex flex-column justify-content-center mb-5">
              <img src={Integrity} alt="" />
              <h4 className="mt-3">
                {" "}
                {locale
                  ? locale === "eng"
                    ? ourValues && ourValues.contents[1].title
                      ? ourValues.contents[1].title
                      : "Empowerment"
                    : ourValues && ourValues.contents[1].titleNepali
                    ? ourValues.contents[1].titleNepali
                    : "Empowerment"
                  : "Empowerment"}{" "}
              </h4>
            </div>
            <div className="col-12 col-md-3 d-flex flex-column justify-content-center mb-5">
              <img src={Accessibility} alt="" />
              <h4 className="mt-3">
                {" "}
                {locale
                  ? locale === "eng"
                    ? ourValues && ourValues.contents[2].title
                      ? ourValues.contents[2].title
                      : "Empowerment"
                    : ourValues && ourValues.contents[2].titleNepali
                    ? ourValues.contents[2].titleNepali
                    : "Empowerment"
                  : "Empowerment"}{" "}
              </h4>
            </div>
            <div className="col-12 col-md-3 d-flex flex-column justify-content-center mb-5">
              <img src={Dignity} alt="" />
              <h4 className="mt-3">
                {" "}
                {locale
                  ? locale === "eng"
                    ? ourValues && ourValues.contents[3].title
                      ? ourValues.contents[3].title
                      : "Empowerment"
                    : ourValues && ourValues.contents[3].titleNepali
                    ? ourValues.contents[3].titleNepali
                    : "Empowerment"
                  : "Empowerment"}{" "}
              </h4>
            </div>
            <div className="col-12 col-md-3 d-flex flex-column justify-content-center mb-5">
              <img src={Partnership} alt="" />
              <h4 className="mt-3">
                {" "}
                {locale
                  ? locale === "eng"
                    ? ourValues && ourValues.contents[4].title
                      ? ourValues.contents[4].title
                      : "Empowerment"
                    : ourValues && ourValues.contents[4].titleNepali
                    ? ourValues.contents[4].titleNepali
                    : "Empowerment"
                  : "Empowerment"}{" "}
              </h4>
            </div>
            <div className="col-12 col-md-3 d-flex flex-column justify-content-center mb-5">
              <img src={Equality} alt="" />
              <h4 className="mt-3">
                {" "}
                {locale
                  ? locale === "eng"
                    ? ourValues && ourValues.contents[5].title
                      ? ourValues.contents[5].title
                      : "Empowerment"
                    : ourValues && ourValues.contents[5].titleNepali
                    ? ourValues.contents[5].titleNepali
                    : "Empowerment"
                  : "Empowerment"}{" "}
              </h4>
            </div>
            <div className="col-12 col-md-3 d-flex flex-column justify-content-center mb-5">
              <img src={Diversity} alt="" />
              <h4 className="mt-3">
                {" "}
                {locale
                  ? locale === "eng"
                    ? ourValues && ourValues.contents[6].title
                      ? ourValues.contents[6].title
                      : "Empowerment"
                    : ourValues && ourValues.contents[6].titleNepali
                    ? ourValues.contents[6].titleNepali
                    : "Empowerment"
                  : "Empowerment"}{" "}
              </h4>
            </div>
            <div className="col-12 col-md-3 d-flex flex-column justify-content-center mb-5">
              <img src={Accountability} alt="" />
              <h4 className="mt-3">
                {" "}
                {locale
                  ? locale === "eng"
                    ? ourValues && ourValues.contents[7].title
                      ? ourValues.contents[7].title
                      : "Empowerment"
                    : ourValues && ourValues.contents[7].titleNepali
                    ? ourValues.contents[7].titleNepali
                    : "Empowerment"
                  : "Empowerment"}{" "}
              </h4>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default OurValues;
