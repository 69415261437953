import React, { useEffect, useRef, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { toastPosition } from "../../config/toastProp";
import { createNewsLetterUser } from "../../services/NewsLetterUserService";
import { getNewsLetterGroups, mapUserToNewsLetter } from "../../services/api";
import Select from "react-select";

function NewsLetter() {
  const emailRef = useRef("");
  const [loading, setLoading] = useState(false);
  const [group, setGroup] = useState([
    {
      value: "",
      label: "",
      id: "",
    },
  ]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [locale, setLocale] = useState("eng");

  const fetchLikelyGroups = async (e) => {
    let response = await getNewsLetterGroups();
    if (response.data.newsLetterGroups) {
      const formattedGroups = response.data.newsLetterGroups.map((group) => ({
        value: group.name,
        label: group.name,
        id: group._id,
      }));
      setGroup(formattedGroups);
    }
  };

  useEffect(() => {
    fetchLikelyGroups();
    let locale = localStorage.getItem("locale")
      ? localStorage.getItem("locale")
      : "eng";
    setLocale(locale);
  }, []);

  const subscribeUser = async () => {
    setLoading(true);
    const userData = {};
    const email = emailRef.current.value;
    if (email.trim().length < 1) {
      toast.error(`email is required !`, toastPosition);
      setLoading(false);
      return;
    }
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
      toast.error(`invalid email !`, toastPosition);
      setLoading(false);
      return;
    }

    userData["email"] = email;
    userData["name"] = "testuser";
    try {
      const savedRes = await createNewsLetterUser(userData);
      if (savedRes !== undefined) {
        emailRef.current.value = "";
        if (savedRes.savedNewsLetterUser) {
          if (selectedOption) {
            let data = {
              groupId: selectedOption.id,
              userIds: [savedRes.savedNewsLetterUser._id],
            };

            await mapUserToNewsLetter(data);
          }
        }
      }
    } catch (error) {
      toast.error(`${error.message}`, {
        position: "top-center",
        autoClose: 200,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
        theme: "colored",
      });
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <section className="subscribe-section mt-5">
        <div className="subscribe-container border position-relative">
          <h2 className="text-white text-center">
            {locale === "eng"
              ? "Stay Updated on our latest news!"
              : "हाम्रो नवीनतम समाचारमा अपडेट रहनुहोस्!"}{" "}
          </h2>
          <div className="subscribe-form d-flex justify-content-center flex-wrap mt-5">
            <input
              ref={emailRef}
              type="email"
              className="subscribe-input mb-3"
              placeholder="Enter your email"
            />
            <Select
              className="subscribe-input mb-3 basic-single"
              classNamePrefix="select"
              defaultInputValue={""}
              onChange={setSelectedOption}
              options={group}
            />

            <button
              className="btn btn-blue-inverted ml-2 mb-3"
              onClick={subscribeUser}
              disabled={loading}
            >
              <i className="fas fa-envelope mr-2"></i>
              {locale === "eng" ? "Subscribe" : "सदस्यता लिनुहोस्"}{" "}
            </button>
          </div>
        </div>
      </section>
      <ToastContainer></ToastContainer>
    </>
  );
}

export default NewsLetter;
