import { toast } from "react-toastify";

export const toastSuccess = () => {
  toast.success("Success!", {
    position: "top-center",
    autoClose: 700,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: 0,
    theme: "colored",
  });
};

export const toastError = (error) => {
  toast.error("Something went wrong!", {
    position: "top-center",
    autoClose: 700,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: 0,
    theme: "colored",
  });
};
