import React, { useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { createNewsLetterUser } from "../../services/NewsLetterUserService";
import { getFooter } from "../../services/footerService";

import footerImage from "../../assets/img/logo-inverted.png";
import { getAllQuickLinks, getSocialLinks } from "../../services/api";

export default function Footer() {
  const [email, setEmail] = useState("");
  const [footer, setFooter] = useState({});
  const [locale, setLocale] = useState("eng");
  const [socialLinks, setSocialLinks] = useState();
  const [quickLink, setQuickLink] = useState({
    quicklink: [
      {
        _id: "",
        title: "",
        href: "",
      },
    ],
  });

  const subscribe = async (event) => {
    event.preventDefault();
    let isValidEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    if (!isValidEmail) {
      toast.error("Invalid Email !!!", {
        position: "top-center",
        autoClose: 800,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
        theme: "colored",
      });
      return;
    }
    const name = email.split("@")[0];
    const res = await createNewsLetterUser({ name: name, email: email });
    if (res.success === true) {
      toast.success(`suscribed with email ${email}`);
    }

    setEmail("");
  };

  const getQuickLink = async () => {
    try {
      let res = await getAllQuickLinks();
      if (res.data) {
        res.data.forEach((item) => {
          if (item.locale === locale) {
            setQuickLink((prevState) => ({
              ...prevState,
              quicklink: item.quicklink,
            }));
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    async function fetchFooter() {
      try {
        let response = await getFooter();
        setFooter(response.data.data);
        let socialLinks = await getSocialLinks();
        if (socialLinks.data) {
          setSocialLinks(socialLinks.data);
        }
      } catch (error) {}
    }
    fetchFooter();

    let locale = localStorage.getItem("locale")
      ? localStorage.getItem("locale")
      : "eng";
    setLocale(locale);
    getQuickLink();
  }, [locale]);
  return (
    <>
      <footer className="footer-section">
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-5">
              <div className="footer-logo">
                <img
                  src={footerImage}
                  alt="Koshish Logo"
                  className="img-fluid"
                />
              </div>

              <div className="footer-address text-white">
                <div className="flex align-items-center">
                  <i className="fas fa-map-marker-alt"></i>
                  <span className="ml-3">
                    {" "}
                    {locale
                      ? locale === "eng"
                        ? footer.address
                        : footer.addressNepali
                      : "Kusunti, Lalitpur-14, Nepal"}{" "}
                  </span>
                </div>
                <div className="d-flex align-items-center">
                  <i className="fas fa-phone"></i>
                  <span className="ml-3">
                    {locale === "eng" ? "Phone" : "फोन"}:{" "}
                    {locale
                      ? locale === "eng"
                        ? footer.phone
                        : footer.phoneNepali
                      : "(977) 5454545"}{" "}
                  </span>
                </div>
                <div className="d-flex align-items-center">
                  <i className="fas fa-envelope"></i>
                  <span className="ml-3">
                    {" "}
                    {footer && footer.email
                      ? footer.email
                      : "info@koshishnepal.org"}{" "}
                  </span>
                </div>
              </div>
              <div className="footer-address mt-4 mb-4">
                <div className="font-weight-bold">
                  {locale
                    ? locale === "eng"
                      ? " For any concerns, suggestions or feedback:"
                      : "समस्या, सुझाव वा प्रतिक्रिया को लागि:"
                    : " For any concerns, suggestions or feedback:"}
                </div>
                <div className="d-flex align-items-center">
                  <i className="fas fa-phone"></i>
                  <span className="ml-3">
                    {locale === "eng" ? "Toll Free" : "टोल फ्री"}:{" "}
                    {locale
                      ? locale === "eng"
                        ? footer.tollFreePhone
                        : footer.tollFreePhoneNepali
                      : "(+977) 969.6642.456"}{" "}
                  </span>
                </div>
                <div className="d-flex align-items-center">
                  <i className="fas fa-envelope"></i>
                  <span className="ml-3">
                    {" "}
                    {footer && footer.email
                      ? footer.feedBackEmail
                      : "feedback@koshish.org.np"}
                  </span>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-3 mb-4 d-flex justify-content-start justify-content-lg-center">
              <div>
                <h4 className="text-white font-weight-bold">
                  {locale === "eng" ? "Quick Links" : "तत्काल लिङ्कहरू"}
                </h4>
                <ul className="footer-quick-links text-white">
                  {quickLink.quicklink.map((link, index) => (
                    <React.Fragment key={index}>
                      <li>
                        <NavLink to={link.href}>{link.title}</NavLink>
                      </li>
                    </React.Fragment>
                  ))}
                </ul>
              </div>
            </div>
            <div style={{ paddingLeft: "215px" }} className="col-12 col-lg-4">
              <div className="footer-social-icons">
                <h4 className="text-white font-weight-bold">
                  {locale === "eng" ? "Follow us" : "हामीलाई अनुसरण गर्नुहोस्"}
                </h4>
                <div className="d-flex flex-wrap">
                  <NavLink
                    className="footer-icons"
                    to={socialLinks && socialLinks.facebook}
                  >
                    <i className="fab fa-facebook-f pr-3 "></i>
                  </NavLink>
                  <NavLink
                    className="footer-icons"
                    to={socialLinks && socialLinks.instagram}
                  >
                    <i className="fab fa-instagram pr-3 "></i>
                  </NavLink>
                  <NavLink
                    className="footer-icons"
                    to={socialLinks && socialLinks.twitter}
                  >
                    <i className="fab fa-x-twitter pr-3 "></i>
                  </NavLink>
                  <NavLink
                    className="footer-icons"
                    to={socialLinks && socialLinks.linkedIn}
                  >
                    <i className="fab fa-linkedin pr-3 "></i>
                  </NavLink>
                  <NavLink
                    className="footer-icons"
                    to={socialLinks && socialLinks.youtube}
                  >
                    <i className="fab fa-youtube pr-3 "></i>
                  </NavLink>
                </div>
              </div>
              <div className="make-a-difference mt-4">
                <h4 className="text-white font-weight-bold">
                  {locale === "eng"
                    ? "Make a difference"
                    : "परिवर्तन गर्नुहोस्"}
                </h4>
                <Link to="/donation">
                  <button className="btn btn-blue border mt-2">
                    <i className="fas fa-heart mr-2"></i>
                    {locale === "eng" ? "Donate Now" : "अहिले दान गर्नुहोस"}
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="mt-5  text-center">
            <NavLink to="#" className="font-weight-bold">
              <i className="fas fa-users mr-2"></i>{" "}
              {locale === "eng"
                ? "Need Help? Call"
                : "मद्दत चाहियो? कल गर्नुहोस"}{" "}
              {locale
                ? locale === "eng"
                  ? footer.tollFreePhone
                  : footer.tollFreePhoneNepali
                : "(+977) 969.6642.456"}
            </NavLink>{" "}
            &nbsp; | &nbsp;
            <NavLink to="/mentalHealth">
              {" "}
              {locale === "eng"
                ? "Take a Mental Health Test"
                : "मानसिक स्वास्थ्यको परिक्षण गर्नुहोस्"}
            </NavLink>{" "}
            &nbsp; | &nbsp;
            <NavLink to="/termsOfService">
              {locale === "eng" ? "Terms of Service" : "सेवा सर्तहरू"}{" "}
            </NavLink>{" "}
            &nbsp; | &nbsp;
            <NavLink to="/privacyPolicy">
              {locale === "eng" ? "Privacy Policy" : "गोपनीयता नीति"}{" "}
            </NavLink>{" "}
            &nbsp; | &nbsp;{" "}
            <NavLink to="/FAQ">
              {" "}
              {locale === "eng" ? "FAQ" : "पृष्ठहरू"}{" "}
            </NavLink>
            <div className="border-bottom mt-2 mb-2"></div>
            <p className="text-center text-white">
              © 2024 KOSHISH. All Rights Reserved.
            </p>
          </div>
        </div>
      </footer>
    </>
  );
}
