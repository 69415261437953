import axios from "axios";
import urls from "../urls/urls";

const api = axios.create({
  baseURL: urls.BASE_URL,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
});

let locale = localStorage.getItem("locale")
  ? localStorage.getItem("locale")
  : "eng";

export const login = async (data) => {
  let response;

  try {
    response = await api.post("/api/auth/login", data);
  } catch (error) {
    return error;
  }

  return response;
};

export const saveEnglishServiceTextEditorContent = async (data) => {
  try {
    await api.put("/api/service/updateService/eng", data);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const saveNepaliServiceTextEditorContent = async (data) => {
  try {
    await api.put("/api/service/updateService/nep", data);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const saveSliderImage = async (data) => {
  try {
    await api.put("/api/slider/sliderImage", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const saveSliderVideo = async (data) => {
  try {
    await api.put("/api/slider/sliderVideo", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const saveEnglishSliderContent = async (data) => {
  try {
    await api.put("/api/slider/updateSlider/eng", data);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const saveNepaliSliderContent = async (data) => {
  try {
    await api.put("/api/slider/updateSlider/nep", data);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const getSliderData = async () => {
  let response;
  try {
    response = await api.get(`/api/slider/getSlider/${locale}`);
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const getNepaliSliderContent = async () => {
  let response;
  try {
    response = await api.get("/api/slider/getSlider/nep");
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const getEnglishSliderContent = async () => {
  let response;
  try {
    response = await api.get("/api/slider/getSlider/eng");
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const getNepaliServiceTextEditorContent = async () => {
  let response;
  try {
    response = await api.get("/api/service/getService/nep");
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const getEnglishServiceTextEditorContent = async () => {
  let response;
  try {
    response = await api.get("/api/service/getService/eng");
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const createNewLetterGroup = async (formData) => {
  let response;
  try {
    response = await api.post("/api/newsLetter/newsLetterGroup", formData);
  } catch (error) {
    console.log(error);
    return error;
  }
  return response;
};

export const fetchNewLetterGroup = async () => {
  let response;
  try {
    response = await api.get("/api/newsLetter/newsLetterGroup");
  } catch (error) {
    console.log(error);
    return error;
  }
  return response;
};

export const createNewsLetterUser = async (userData) => {
  let response;
  try {
    response = await api.post("/api/newsLetter/newsLetterGroup", userData);
  } catch (error) {
    console.log(error);
    return error;
  }
  return response;
};

export const getLikelyUsers = async (data) => {
  let response;
  try {
    response = await api.get(
      `/api/newsLetter/newsLetterUsersLimited?emailLike=${data}`
    );
  } catch (error) {
    console.log(error);
    return error;
  }
  return response;
};

export const getLikelyGroups = async (data) => {
  let response;
  try {
    response = await api.get(
      `/api/newsLetter/newsLetterGroupsLimited?groupNameLike=${data}`
    );
  } catch (error) {
    console.log(error);
    return error;
  }
  return response;
};

export const addNewsLetterUserGroupMap = async (userGroupMap) => {
  let response;
  try {
    response = await api.post(
      "/api/newsLetter/newsLetterUserGroupMap",
      userGroupMap
    );
  } catch (error) {
    console.log(error);
    return error;
  }
  return response;
};

export const saveNepaliGeographicalCoverage = async (data) => {
  try {
    await api.put(
      "/api/geographicalCoverage/updateGeographicalCoverage/nep",
      data
    );
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const saveEnglishGeographicalCoverage = async (data) => {
  try {
    await api.put(
      "/api/geographicalCoverage/updateGeographicalCoverage/eng",
      data
    );
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const getNepaliGeographicalCoverage = async () => {
  let response;
  try {
    response = await api.get(
      "/api/geographicalCoverage/getGeographicalCoverage/nep"
    );
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const getEnglishGeographicalCoverage = async () => {
  let response;
  try {
    response = await api.get(
      "/api/geographicalCoverage/getGeographicalCoverage/eng"
    );
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const getGeographicalCoverage = async () => {
  let response;
  try {
    response = await api.get(
      `/api/geographicalCoverage/getGeographicalCoverage/${locale}`
    );
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const saveNepaliOurWork = async (data) => {
  try {
    await api.put("/api/ourwork/updateOurwork/nep", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const saveEnglishOurWork = async (data) => {
  try {
    await api.put("/api/ourwork/updateOurwork/eng", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const saveNepaliOurWorkDescription = async (data) => {
  try {
    await api.put("/api/ourwork/update/ourWork/description/nep", data);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const saveEnglishOurWorkDescription = async (data) => {
  try {
    await api.put("/api/ourwork/update/ourWork/description/eng", data);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const getAllOurwork = async () => {
  let response;
  try {
    response = await api.get("/api/ourWork/getAllWork");
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const getOurwork = async () => {
  let response;
  try {
    response = await api.get(`/api/ourWork/getOurwork/${locale}`);
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const getYourSupport = async () => {
  let response;
  try {
    response = await api.get(`/api/yoursupport/getYourSupport/${locale}`);
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const getNews = async () => {
  let response;
  try {
    response = await api.get(`/api/latestNews/getNews/${locale}`);
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const deleteNepaliWork = async (id) => {
  let response;
  try {
    response = await api.delete("/api/ourWork/delete/nep", { params: { id } });
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const deleteEnglishWork = async (id) => {
  let response;
  try {
    response = await api.delete("/api/ourWork/delete/eng", { params: { id } });
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const getAllSupportData = async () => {
  let response;
  try {
    response = await api.get("/api/yoursupport/getall");
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const saveNepaliYourSupportData = async (data) => {
  try {
    await api.put("/api/yoursupport/update/support/nep", data);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const saveEnglishYourSupportData = async (data) => {
  try {
    await api.put("/api/yoursupport/update/support/eng", data);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const getAllLatestNews = async () => {
  let response;
  try {
    response = await api.get("/api/latestNews/getAllNews");
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const getAllGeoMaps = async () => {
  let response;
  try {
    response = await api.get("/api/geographicalCoverage/getAllGeoMap");
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const getGeoMapData = async () => {
  let response;
  try {
    response = await api.get(`/api/geographicalCoverage/getGeoMap/${locale}`);
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const saveNepaliGeoMapData = async (data) => {
  try {
    await api.put("/api/geographicalCoverage/updateGeoMap/nep", data);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const saveEnglishGeoMapData = async (data) => {
  try {
    await api.put("/api/geographicalCoverage/updateGeoMap/eng", data);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const saveNepaliLatestNews = async (data) => {
  try {
    await api.put("/api/latestNews/updateLatestNews/nep", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const saveEnglishLatestNews = async (data) => {
  try {
    await api.put("/api/latestNews/updateLatestNews/eng", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const deleteNepaliNews = async (id) => {
  let response;
  try {
    response = await api.delete("/api/latestNews/delete/nep", {
      params: { id },
    });
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const deleteEnglishNews = async (id) => {
  let response;
  try {
    response = await api.delete("/api/latestNews/delete/eng", {
      params: { id },
    });
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const saveAboutUsImages = async (data) => {
  try {
    await api.put("/api/aboutUs/updateNavImage", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const getAboutUsImages = async () => {
  let response;
  try {
    response = await api.get("/api/aboutUs/getAboutUsImage");
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const getOurWorkImages = async () => {
  let response;
  try {
    response = await api.get("/api/ourProgram/getOurWorkImage");
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const saveOurWorkImages = async (data) => {
  try {
    await api.put("/api/ourProgram/updateOurWorkImage", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const getGetInvolvedImages = async () => {
  let response;
  try {
    response = await api.get("/api/getInvolved/getGetInvolvedImages");
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const saveGetInvolvedImages = async (data) => {
  try {
    await api.put("/api/getInvolved/updateGetInvolvedImage", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const getResourcesImages = async () => {
  let response;
  try {
    response = await api.get("/api/resources/getResourcesImage");
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const saveResourcesImages = async (data) => {
  try {
    await api.put("/api/resources/updateResourcesImage", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const getContactUsImages = async () => {
  let response;
  try {
    response = await api.get("/api/contactUs/getContactUsImages");
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const saveContactUsImages = async (data) => {
  try {
    await api.put("/api/contactUs/updateContactUsImages", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const getSocialLinks = async () => {
  let response;
  try {
    response = await api.get("/api/socialLinks/getSocialLinks");
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const saveSocialLinks = async (data) => {
  try {
    await api.put("/api/socialLinks/updateSocialLinks", data);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const getAllPopupPage = async () => {
  let response;
  try {
    response = await api.get("/api/popup/getAllPopupPage");
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const getPopupPage = async () => {
  let response;
  try {
    response = await api.get(`/api/popup/getPopupPage/${locale}`);
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const savePopupPageEnglish = async (data) => {
  try {
    await api.put("/api/popup/updatePopupPage/eng", data);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const savePopupPageNepali = async (data) => {
  try {
    await api.put("/api/popup/updatePopupPage/nep", data);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const getAllHistory = async () => {
  let response;
  try {
    response = await api.get("/api/aboutUs/getAllHistory");
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const getAboutUsHistory = async () => {
  let response;
  try {
    response = await api.get(`/api/aboutUs/getHistory/${locale}`);
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const saveNepaliHistoryDescription = async (data) => {
  try {
    await api.put("/api/aboutUs/update/history/description/nep", data);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const saveEnglishHistoryDescription = async (data) => {
  try {
    await api.put("/api/aboutUs/update/history/description/eng", data);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const saveNepaliHistory = async (data) => {
  try {
    await api.put("/api/aboutUs/updateHistory/nep", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const saveEnglishHistory = async (data) => {
  try {
    await api.put("/api/aboutUs/updateHistory/eng", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const deleteNepaliHistory = async (id) => {
  let response;
  try {
    response = await api.delete("/api/aboutUs/delete/history/nep", {
      params: { id },
    });
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const deleteEnglishHistory = async (id) => {
  let response;
  try {
    response = await api.delete("/api/aboutUs/delete/history/eng", {
      params: { id },
    });
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const getAllAboutUsIntrocuction = async () => {
  let response;
  try {
    response = await api.get("/api/aboutUs/getAllIntrodcution");
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const saveNepaliIntroduction = async (data) => {
  try {
    await api.put("/api/aboutUs/updateIntrodcution/nep", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const saveEnglishIntroduction = async (data) => {
  try {
    await api.put("/api/aboutUs/updateIntrodcution/eng", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const getAboutUsIntroduction = async () => {
  let response;
  try {
    response = await api.get(`/api/aboutUs/getIntrodcution/${locale}`);
  } catch (error) {
    console.log(error);
  }
  return response;
};

// Thematic Area

export const getAllThematicArea = async () => {
  let response;
  try {
    response = await api.get("/api/aboutUs/getAllThematicArea");
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const getAboutUsThematicArea = async () => {
  let response;
  try {
    response = await api.get(`/api/aboutUs/getThematicArea/${locale}`);
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const saveNepaliThematicAreaHeading = async (data) => {
  try {
    await api.put("/api/aboutUs/update/thematicArea/heading/nep", data);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const saveEnglishThematicAreaHeading = async (data) => {
  try {
    await api.put("/api/aboutUs/update/thematicArea/heading/eng", data);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const saveNepaliThematicArea = async (data) => {
  try {
    await api.put("/api/aboutUs/updateThematicArea/nep", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const saveEnglishThematicArea = async (data) => {
  try {
    await api.put("/api/aboutUs/updateThematicArea/eng", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const deleteNepaliThematicArea = async (id) => {
  let response;
  try {
    response = await api.delete("/api/aboutUs/delete/thematicArea/nep", {
      params: { id },
    });
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const deleteEnglishThematicArea = async (id) => {
  let response;
  try {
    response = await api.delete("/api/aboutUs/delete/thematicArea/eng", {
      params: { id },
    });
  } catch (error) {
    console.log(error);
  }
  return response;
};

// Our Values

export const getAllOurValues = async () => {
  let response;
  try {
    response = await api.get("/api/aboutUs/getAllOurValue");
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const getAboutUsOurValues = async () => {
  let response;
  try {
    response = await api.get(`/api/aboutUs/getOurValue/${locale}`);
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const saveNepaliOurValuesHeading = async (data) => {
  try {
    await api.put("/api/aboutUs/update/ourValue/heading/nep", data);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const saveEnglishOurValuesHeading = async (data) => {
  try {
    await api.put("/api/aboutUs/update/ourValue/heading/eng", data);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const saveNepaliOurValues = async (data) => {
  try {
    await api.put("/api/aboutUs/updateOurValue/nep", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const saveEnglishOurValues = async (data) => {
  try {
    await api.put("/api/aboutUs/updateOurValue/eng", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const deleteNepaliOurValues = async (id) => {
  let response;
  try {
    response = await api.delete("/api/aboutUs/delete/ourValue/nep", {
      params: { id },
    });
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const deleteEnglishOurValues = async (id) => {
  let response;
  try {
    response = await api.delete("/api/aboutUs/delete/ourValue/eng", {
      params: { id },
    });
  } catch (error) {
    console.log(error);
  }
  return response;
};

// Our Approach

export const getAllOurApproach = async () => {
  let response;
  try {
    response = await api.get("/api/aboutUs/getAllOurApproach");
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const getAboutUsOurApproach = async () => {
  let response;
  try {
    response = await api.get(`/api/aboutUs/getOurApproach/${locale}`);
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const saveNepaliOurApproachHeading = async (data) => {
  try {
    await api.put("/api/aboutUs/update/ourApproach/heading/nep", data);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const saveEnglishOurApproachHeading = async (data) => {
  try {
    await api.put("/api/aboutUs/update/ourApproach/heading/eng", data);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const saveNepaliOurApproach = async (data) => {
  try {
    await api.put("/api/aboutUs/updateOurApproach/nep", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const saveEnglishOurApproach = async (data) => {
  try {
    await api.put("/api/aboutUs/updateOurApproach/eng", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const deleteNepaliOurApproach = async (id) => {
  let response;
  try {
    response = await api.delete("/api/aboutUs/delete/ourApproach/nep", {
      params: { id },
    });
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const deleteEnglishOurApproach = async (id) => {
  let response;
  try {
    response = await api.delete("/api/aboutUs/delete/ourApproach/eng", {
      params: { id },
    });
  } catch (error) {
    console.log(error);
  }
  return response;
};

//
export const getDonationImage = async () => {
  let response;
  try {
    response = await api.get("/api/donation/image");
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const saveDonationImage = async (data) => {
  try {
    await api.put("/api/donation/update/donationImage", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

// strategic plan

export const getAllStrategicPlan = async () => {
  let response;
  try {
    response = await api.get("/api/strategicPlan/getAllStrategicPlan");
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const getStrategicPlan = async () => {
  let response;
  try {
    response = await api.get(`/api/strategicPlan/getStrategicPlan/${locale}`);
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const saveNepaliStrategicPlan = async (data) => {
  try {
    await api.put("/api/strategicPlan/updateStrategicPlan/nep", data);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const saveEnglishStrategicPlan = async (data) => {
  try {
    await api.put("/api/strategicPlan/updateStrategicPlan/eng", data);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

// mental Health
export const getAllMentalHealth = async () => {
  let response;
  try {
    response = await api.get("/api/mentalhealth/getAllMentalHealth");
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const getMentalHealth = async () => {
  let response;
  try {
    response = await api.get(`/api/mentalhealth/getMentalHealth/${locale}`);
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const saveNepaliMentalHealth = async (data) => {
  try {
    await api.put("/api/mentalhealth/updateMentalHealth/nep", data);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const saveEnglishMentalHealth = async (data) => {
  try {
    await api.put("/api/mentalhealth/updateMentalHealth/eng", data);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

// our partner

export const getAllOurPartner = async () => {
  let response;
  try {
    response = await api.get("/api/ourPartners/getAllPartner");
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const getOurPartner = async () => {
  let response;
  try {
    response = await api.get(`/api/ourPartners/getPartner/${locale}`);
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const saveNepaliOurPartnerHeading = async (data) => {
  try {
    await api.put("/api/ourPartners/update/partner/heading/nep", data);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const saveEnglishOurPartnerHeading = async (data) => {
  try {
    await api.put("/api/ourPartners/update/partner/heading/eng", data);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const saveNepaliOurPartner = async (data) => {
  try {
    await api.put("/api/ourPartners/updatePartner/nep", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const saveEnglishOurPartner = async (data) => {
  try {
    await api.put("/api/ourPartners/updatePartner/eng", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const deleteNepaliOurPartner = async (id) => {
  let response;
  try {
    response = await api.delete("/api/ourPartners/delete/partner/nep", {
      params: { id },
    });
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const deleteEnglishOurPartner = async (id) => {
  let response;
  try {
    response = await api.delete("/api/ourPartners/delete/partner/eng", {
      params: { id },
    });
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const getNewsLetterGroups = async () => {
  let response;
  try {
    response = await api.get("api/newsLetter/newsLetterGroup");
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const mapUserToNewsLetter = async (data) => {
  try {
    await api.post("/api/newsLetter/newsLetterUserGroupMap", data);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const deleteNewsGroup = async (id) => {
  let response;
  try {
    response = await api.delete(`/api/newsLetter/delete/group/${id}`);
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const updateNewsGroup = async (id, data) => {
  let response;
  try {
    response = await api.put(`/api/newsLetter/update/group/${id}`, data);
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const deleteNewsLetterUser = async (id) => {
  let response;
  try {
    response = await api.delete(`/api/newsLetter/delete/newsletteruser/${id}`);
  } catch (error) {
    console.log(error);
  }
  return response;
};

// FAQ

export const getAllFAQ = async () => {
  let response;
  try {
    response = await api.get("/api/footerLinks/getAllFAQ");
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const getFAQ = async () => {
  let response;
  try {
    response = await api.get(`/api/footerLinks/getFAQ/${locale}`);
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const saveNepaliFAQ = async (data) => {
  try {
    await api.put("/api/footerLinks/updateFAQ/nep", data);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const saveEnglishFAQ = async (data) => {
  try {
    await api.put("/api/footerLinks/updateFAQ/eng", data);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

// Terms Of Service

export const getAllTermsOfService = async () => {
  let response;
  try {
    response = await api.get("/api/footerLinks/getAllTermsOfService");
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const getTermsOfService = async () => {
  let response;
  try {
    response = await api.get(`/api/footerLinks/getTermsOfService/${locale}`);
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const saveNepaliTermsOfService = async (data) => {
  try {
    await api.put("/api/footerLinks/updateTermsOfService/nep", data);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const saveEnglishTermsOfService = async (data) => {
  try {
    await api.put("/api/footerLinks/updateTermsOfService/eng", data);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

// Privacy Policy

export const getAllPrivacyPolicy = async () => {
  let response;
  try {
    response = await api.get("/api/footerLinks/getAllPrivacyPolicy");
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const getPrivacyPolicy = async () => {
  let response;
  try {
    response = await api.get(`/api/footerLinks/getPrivacyPolicy/${locale}`);
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const saveNepaliPrivacyPolicy = async (data) => {
  try {
    await api.put("/api/footerLinks/updatePrivacyPolicy/nep", data);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const saveEnglishPrivacyPolicy = async (data) => {
  try {
    await api.put("/api/footerLinks/updatePrivacyPolicy/eng", data);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const getBranding = async () => {
  let response;
  try {
    response = await api.get("/api/branding/getBranding");
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const updateBranding = async (data, id) => {
  try {
    await api.put(`/api/branding/updateBranding/${id}`, data);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const updateLogo = async (data, id) => {
  try {
    await api.put(`/api/branding/updateBranding/logo/${id}`, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

// Need Help

export const getAllNeedHelp = async () => {
  let response;
  try {
    response = await api.get("/api/needhelp/getAllNeedHelp");
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const getNeedHelp = async () => {
  let response;
  try {
    response = await api.get(`/api/needhelp/getNeedHelp/${locale}`);
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const saveNepaliNeedHelp = async (data) => {
  try {
    await api.put("/api/needhelp/updateNeedHelp/nep", data);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const saveEnglishNeedHelp = async (data) => {
  try {
    await api.put("/api/needhelp/updateNeedHelp/eng", data);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

// Banner Images
export const saveBannerImages = async (data) => {
  try {
    await api.put("/api/bannerimage/updateBannerImage", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const getBannerImages = async () => {
  let response;
  try {
    response = await api.get("/api/bannerimage/getBannerImage");
  } catch (error) {
    console.log(error);
  }
  return response;
};

// Vacancy

export const saveNepaliVacancy = async (data) => {
  try {
    await api.put("/api/getInvolved/update/vacancy/nep", data);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const saveEnglishVacancy = async (data) => {
  try {
    await api.put("/api/getInvolved/update/vacancy/eng", data);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const getAllVacancy = async () => {
  let response;
  try {
    response = await api.get("/api/getInvolved/getAllVacancy");
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const getVacancy = async () => {
  let response;
  try {
    response = await api.get(`/api/getInvolved/getVacancy/${locale}`);
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const deleteNepaliVacancy = async (id) => {
  let response;
  try {
    response = await api.delete("/api/getInvolved/delete/vacancy/nep", {
      params: { id },
    });
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const deleteEnglishVacancy = async (id) => {
  let response;
  try {
    response = await api.delete("/api/getInvolved/delete/vacancy/eng", {
      params: { id },
    });
  } catch (error) {
    console.log(error);
  }
  return response;
};

// Volenteer

export const saveNepaliVolenteer = async (data) => {
  try {
    await api.put("/api/getInvolved/update/volenteer/nep", data);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const saveEnglishVolenteer = async (data) => {
  try {
    await api.put("/api/getInvolved/update/volenteer/eng", data);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const getAllVolenteer = async () => {
  let response;
  try {
    response = await api.get("/api/getInvolved/getAllVolenteer");
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const getVolenteer = async () => {
  let response;
  try {
    response = await api.get(`/api/getInvolved/getVolenteer/${locale}`);
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const deleteNepaliVolenteer = async (id) => {
  let response;
  try {
    response = await api.delete("/api/getInvolved/delete/volenteer/nep", {
      params: { id },
    });
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const deleteEnglishVolenteer = async (id) => {
  let response;
  try {
    response = await api.delete("/api/getInvolved/delete/volenteer/eng", {
      params: { id },
    });
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const getPublicationById = async (id) => {
  let response;
  try {
    response = await api.get(`/api/publication/getPublication/${id}`);
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const getAllPublications = async () => {
  let response;
  try {
    response = await api.get("/api/publication/getAllPublications");
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const updatePublication = async (data) => {
  try {
    await api.put("/api/publication/updatePublication", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const deletePublication = async (id) => {
  let response;
  try {
    response = await api.delete("/api/publication/delete", {
      params: { id },
    });
  } catch (error) {
    console.log(error);
  }
  return response;
};

// quick Link

export const getAllQuickLinks = async () => {
  let response;
  try {
    response = await api.get("/api/quicklink/getAllQuickLink");
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const updateNepQuickLink = async (data) => {
  try {
    await api.put("/api/quicklink/updateQuickLink/nep", data);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const updateEngQuickLink = async (data) => {
  try {
    await api.put("/api/quicklink/updateQuickLink/eng", data);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const deleteNepQuickLink = async (id) => {
  let response;
  try {
    response = await api.delete("/api/quicklink/delete/nep", {
      params: { id },
    });
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const deleteEngQuickLink = async (id) => {
  let response;
  try {
    response = await api.delete("/api/quicklink/delete/eng", {
      params: { id },
    });
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const deleteEnglishBlogs = async (id) => {
  let response;
  try {
    response = await api.delete("/api/blogs/delete/eng", {
      params: { id },
    });
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const deleteNepaliBlogs = async (id) => {
  let response;
  try {
    response = await api.delete("/api/blogs/delete/nep", {
      params: { id },
    });
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const getAllLatestBlogs = async () => {
  let response;
  try {
    response = await api.get("/api/blogs/getAllBlogs");
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const saveEnglishBlogs = async (data) => {
  try {
    await api.put("/api/blogs/updateBlogs/eng", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const saveNepaliBlogs = async (data) => {
  try {
    await api.put("/api/blogs/updateBlogs/nep", data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const getBlogs = async () => {
  let response;
  try {
    response = await api.get(`/api/blogs/getBlogs/${locale}`);
  } catch (error) {
    console.log(error);
  }
  return response;
};

// Navbar Text
export const getNavbarText = async () => {
  let response;
  try {
    response = await api.get(`/api/navbartext/getText/${locale}`);
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const getAllNavbarText = async () => {
  let response;
  try {
    response = await api.get(`/api/navbartext/getAllText`);
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const updateNepaliNavbarText = async (data) => {
  try {
    await api.put("/api/navbartext/updateText/nep", data);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const updateEnglishNavbarText = async (data) => {
  try {
    await api.put("/api/navbartext/updateText/eng", data);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

// Geo map color
export const deleteGeoColor = async (id) => {
  let response;
  try {
    response = await api.delete("/api/geocolor/delete", {
      params: { id },
    });
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const updateGeoColor = async (data, id) => {
  try {
    await api.put(`/api/geocolor/updatecolor`, data);
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
};

export const getGeoColor = async () => {
  let response;
  try {
    response = await api.get(`/api/geocolor/getcolors`);
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const getDonationDetails = async () => {
  let response;
  try {
    response = await api.get("/api/donation/donationDetails");
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const getDonationUserDetails = async () => {
  let response;
  try {
    response = await api.get("/api/donation/userDetails");
  } catch (error) {
    console.log(error);
  }
  return response;
};

export const getSearchDetails = async (searchText) => {
  let response;
  try {
    response = await api.get(`/api/search/navsearch/${searchText}`);
  } catch (error) {
    console.log(error);
  }
  return response;
};
