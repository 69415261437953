import React, { useEffect, useState } from "react";
import {
  getAboutUsImages,
  getBannerImages,
  getStrategicPlan,
} from "../../services/api";
import coverImage from "../../assets/img/cover.jpg";
import Accessibility from "./Accessibility";
import PropagateLoader from "react-spinners/PropagateLoader";

const AboutUsOurStrategicPlan = () => {
  const [aboutUsImages, setAboutUsImages] = useState();
  const [currentLocale, setcurrentLocale] = useState("EN");
  const [strategicPlan, setStrategicPlan] = useState({});

  const fetchData = async () => {
    try {
      const res = await getBannerImages();
      const resData = res.data;
      if (resData) {
        setAboutUsImages(resData);
      }

      const response = await getStrategicPlan();
      setStrategicPlan(response.data);
    } catch (error) {}
  };

  useEffect(() => {
    fetchData();
    let currentLocale = localStorage.getItem("locale")
      ? localStorage.getItem("locale") === "eng"
        ? "EN"
        : "NP"
      : "EN";
    setcurrentLocale(currentLocale);
  }, []);
  return (
    <>
      <section className="about-us-section ">
        <Accessibility />
        <div
          className="banner"
          style={{
            backgroundImage: `url('${
              aboutUsImages && aboutUsImages.aboutUsStrategicPlan
                ? aboutUsImages.aboutUsStrategicPlan
                : coverImage
            }')`,
          }}
        >
          <div className="banner-content">
            <h1 className="text-white text-center">
              {currentLocale === "EN"
                ? "Our Strategic Plan"
                : "हाम्रो रणनीतिक योजना"}
            </h1>
          </div>
        </div>

        <div className="container">
          <div>
            <div className="card-body">
              {strategicPlan && strategicPlan.content ? (
                <div
                  dangerouslySetInnerHTML={{ __html: strategicPlan.content }}
                />
              ) : (
                <>
                  Loading <PropagateLoader />
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutUsOurStrategicPlan;
