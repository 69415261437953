import React, { useEffect, useState } from "react";
import { getAllOurImpacts } from "../../services/ourImpactService";
import NumberCounter from "../shared/Number-Counter/NumberCounter";
import Person from "../../assets/icon/Group 9.svg";
import Support from "../../assets/icon/Group 10.svg";
import Capacitated from "../../assets/icon/Group 11.svg";

const OurImpacts = () => {
  const [ourImpacts, setOurImpacts] = useState({});
  const [locale, setLocale] = useState("eng");
  const [showCounters, setShowCounters] = useState(false);

  const shuffleArray = (array) => {
    const shuffledArray = array.slice(); // Create a copy of the array
    for (let i = shuffledArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffledArray[i], shuffledArray[j]] = [
        shuffledArray[j],
        shuffledArray[i],
      ];
    }
    return shuffledArray;
  };

  async function fetchAllOurImpacts() {
    try {
      const response = await getAllOurImpacts();
      const shuffledContents = shuffleArray(response.data.data.contents);
      setOurImpacts({ ...response.data.data, contents: shuffledContents });
    } catch (error) {}
  }
  useEffect(() => {
    let locale = localStorage.getItem("locale")
      ? localStorage.getItem("locale")
      : "eng";
    setLocale(locale);
    fetchAllOurImpacts();
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    const ourImpactSection = document.getElementById("our-impact-section");
    if (!ourImpactSection) return;
    const rect = ourImpactSection.getBoundingClientRect();

    const isVisible =
      rect.top >= -120 && rect.bottom <= window.innerHeight + 120;

    setShowCounters(isVisible);
  };

  const handleRefreshClick = () => {
    fetchAllOurImpacts();
  };

  return (
    <>
      <section className="our-impact-section position-relative mt-4">
        <div className="border our-impact-content">
          <div className="">
            <h2 className="heading">
              {" "}
              {locale
                ? locale === "eng"
                  ? "Our Impact"
                  : "हाम्रो प्रभाव"
                : "Our Impact"}{" "}
            </h2>
          </div>
          <div
            id="our-impact-section"
            className="d-flex align-items-center justify-content-center flex-wrap flex-lg-nowrap"
          >
            <div className="our-impact-fields d-flex flex-column">
              <img src={Person} alt="" />
              <h3 className="our-impact-number">
                {locale &&
                ourImpacts &&
                ourImpacts.contents &&
                ourImpacts.contents[0] ? (
                  locale === "eng" ? (
                    <NumberCounter
                      animate={showCounters}
                      n={parseInt(ourImpacts.contents[0].count)}
                    />
                  ) : (
                    ourImpacts.contents[0].countNepali
                  )
                ) : (
                  "650,230"
                )}
              </h3>
              <p>
                {locale &&
                ourImpacts &&
                ourImpacts.contents &&
                ourImpacts.contents[0]
                  ? locale === "eng"
                    ? ourImpacts.contents[0].desc
                    : ourImpacts.contents[0].descNepali
                  : "Lorem ipsum dolor sit amet consectetur adipisicing elit.Quisquam, quos."}
              </p>
            </div>
            <div className="slash"></div>
            <div className="our-impact-fields d-flex flex-column">
              <img src={Support} alt="" />
              <h3 className="our-impact-number">
                {locale &&
                ourImpacts &&
                ourImpacts.contents &&
                ourImpacts.contents[1] ? (
                  locale === "eng" ? (
                    <NumberCounter
                      animate={showCounters}
                      n={parseInt(ourImpacts.contents[1].count)}
                    />
                  ) : (
                    ourImpacts.contents[1].countNepali
                  )
                ) : (
                  "650,230"
                )}
              </h3>
              <p>
                {locale &&
                ourImpacts &&
                ourImpacts.contents &&
                ourImpacts.contents[1]
                  ? locale === "eng"
                    ? ourImpacts.contents[1].desc
                    : ourImpacts.contents[1].descNepali
                  : "Lorem ipsum dolor sit amet consectetur adipisicing elit.Quisquam, quos."}
              </p>
            </div>
            <div className="slash"></div>
            <div className="our-impact-fields d-flex flex-column">
              <img src={Capacitated} alt="" />
              <h3 className="our-impact-number">
                {locale &&
                ourImpacts &&
                ourImpacts.contents &&
                ourImpacts.contents[2] ? (
                  locale === "eng" ? (
                    <NumberCounter
                      animate={showCounters}
                      n={parseInt(ourImpacts.contents[2].count)}
                    />
                  ) : (
                    ourImpacts.contents[2].countNepali
                  )
                ) : (
                  "650,230"
                )}
              </h3>
              <p>
                {locale &&
                ourImpacts &&
                ourImpacts.contents &&
                ourImpacts.contents[2]
                  ? locale === "eng"
                    ? ourImpacts.contents[2].desc
                    : ourImpacts.contents[2].descNepali
                  : "Lorem ipsum dolor sit amet consectetur adipisicing elit.Quisquam, quos."}
              </p>
            </div>
          </div>
          <button
            style={{
              all: "unset",
              cursor: "pointer", // Add any other custom styles here
            }}
            onClick={() => handleRefreshClick()}
          >
            <i className="fas fa-rotate-right refresh-icon"></i>
          </button>
        </div>
      </section>
    </>
  );
};

export default OurImpacts;
