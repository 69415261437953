import React, { useEffect, useState } from "react";
import CustomModal from "../popup/CustomModal";
import CreateNewsLetterGroup from "./createNewsLetterGroup";
import URLS from "../../../../../../urls/urls";
import { ToastContainer, toast } from "react-toastify";
import {
  deleteNewsGroup,
  updateNewsGroup,
} from "../../../../../../services/api";

const NewsLetterGroupList = () => {
  const [nlGroups, setNlGroups] = useState([]);
  const [editMode, setEditMode] = useState(null);
  const [editGroupName, setEditGroupName] = useState("");

  const fetchGroupList = async () => {
    try {
      const response = await fetch(
        `${URLS.BASE_URL}/api/newsLetter/newsLetterGroup`,
        {
          method: "get",
        }
      );
      const resData = await response.json();
      if (resData.success === true) {
        setNlGroups(resData.newsLetterGroups);
      } else {
        toast.error(`${resData.errormessage}`, {
          position: "top-center",
          autoClose: 700,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
          theme: "colored",
        });
      }
    } catch (error) {
      toast.error(`Something went wrong`, {
        position: "top-center",
        autoClose: 700,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
        theme: "colored",
      });
    }
  };

  useEffect(() => {
    fetchGroupList();
  }, []);

  const [isModalOpen, setModalOpen] = useState(false);
  const successGroupAdd = (name) => {
    toast.success(`Group ${name} has been added successfully`, {
      position: "top-center",
      autoClose: 700,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: 0,
      theme: "colored",
    });
    fetchGroupList();
  };

  const deleteGroups = async (event, id) => {
    event.preventDefault();
    event.stopPropagation();

    if (id) {
      let res = await deleteNewsGroup(id);

      if (res.status === 200) {
        fetchGroupList();
      }
    }
  };

  const handleEditClick = (id, currentName) => {
    setEditMode(id);
    setEditGroupName(currentName);
  };

  const handleNameChange = (e) => {
    setEditGroupName(e.target.value);
  };

  const updateGroupName = async (id) => {
    try {
      const res = await updateNewsGroup(id, { name: editGroupName });
      if (res.status === 200) {
        fetchGroupList();
        toast.success(`Group name updated successfully`, {
          position: "top-center",
          autoClose: 700,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
          theme: "colored",
        });
      } else {
        toast.error(`Failed to update group name`, {
          position: "top-center",
          autoClose: 700,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
          theme: "colored",
        });
      }
    } catch (error) {
      toast.error(`Something went wrong`, {
        position: "top-center",
        autoClose: 700,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
        theme: "colored",
      });
    } finally {
      setEditMode(null);
    }
  };

  return (
    <>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Services</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="index.html">Auth</a>
              </li>
              <li className="breadcrumb-item">NewsLetter</li>
              <li className="breadcrumb-item active">NewsLetter Groups</li>
            </ol>
          </nav>
        </div>
        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">NewsLetter's Group List</h5>
                  <hr className="border-2" />

                  <button
                    className="btn btn-primary"
                    onClick={() => setModalOpen(true)}
                  >
                    Create New Group
                  </button>
                  <CustomModal
                    isOpen={isModalOpen}
                    onRequestClose={() => setModalOpen(false)}
                    contentComponent={
                      <CreateNewsLetterGroup
                        finishGroupAdding={successGroupAdd}
                      />
                    }
                  ></CustomModal>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>S.N</th> <th>ID</th> <th>Group Name</th>{" "}
                        <th>Delete</th>
                      </tr>
                    </thead>
                    <tbody>
                      {nlGroups.map((item, index) => (
                        <tr key={item._id}>
                          <td>{index + 1}</td>
                          <td>{item._id}</td>
                          <td
                            onClick={() => handleEditClick(item._id, item.name)}
                          >
                            {editMode === item._id ? (
                              <input
                                type="text"
                                value={editGroupName}
                                onChange={handleNameChange}
                                onBlur={() => updateGroupName(item._id)}
                                autoFocus
                              />
                            ) : (
                              item.name
                            )}
                          </td>
                          <td>
                            <i
                              onClick={(e) => deleteGroups(e, item._id)}
                              style={{ cursor: "pointer" }}
                              className="bi bi-trash"
                            ></i>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ToastContainer />
      </main>
    </>
  );
};

export default NewsLetterGroupList;
