import React from "react";
import { NavLink } from "react-router-dom";

const PageNotFound = () => {
  return (
    <>
      <section className="error-section">
        <div class="error-container">
          <div class="error-text">
            <h1>Page Not Found</h1>
            <p>
              We can't seem to find the page you're looking for. Please check
              the URL for any typos.
            </p>

            <ul class="error-menu">
              <li>
                <NavLink to="/">Go to Homepage</NavLink>
              </li>
            </ul>
          </div>
          <div>
            <img
              class="error-image"
              src="https://omjsblog.files.wordpress.com/2023/07/errorimg.png"
              alt="Error image"
            />
          </div>
        </div>
      </section>
    </>
  );
};

export default PageNotFound;
