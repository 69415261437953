import React, { useEffect, useRef, useState } from "react";
import { SketchPicker } from "react-color";
import { ToastContainer, toast } from "react-toastify";
import {
  addStory,
  addStoryHeading,
  deleteStory,
  getStories,
  getStoryHeading,
  updateStory,
} from "../../../../services/storiesService";
import Footer from "./Footer";
import CustomModal from "./NewLetter/popup/CustomModal";
import StoriesList from "./StoriesList";
import { Box, Button, Modal } from "@mui/material";
import { Close } from "@mui/icons-material";
import JoditEditor from "jodit-react";
import {
  englishConfig,
  nepaliConfig,
} from "../../../../services/joditConfigService";

const Stories = () => {
  const headingRef = useRef();
  const headingNepaliRef = useRef();
  const descRef = useRef();
  const descNepaliRef = useRef();
  const imageRef = useRef();
  const personRef = useRef();
  const personNepaliRef = useRef();
  const detailEnglishRef = useRef(null);
  const detailNepaliRef = useRef(null);
  const [color, setColor] = useState("#aabbcc");
  const [storyHeading, setStoryHeading] = useState({
    heading: "",
    headingNepali: "",
    readMoreBtnColor: "",
  });
  const [isModalOpen, setModalOpen] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [isCollapsed, setIsCollapsed] = useState([]);
  const [storyList, setStoryList] = useState([
    {
      desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
      descNepali: "लोरेम इप्सम डोलोर सित अमेत, कोन्सेक्टेतुर अदिपिस्किंग एलिट।",
      image: "https://example.com/image1.jpg",
      person: "John Doe",
      personNepali: "जोन डो",
      display: true,
      detail: "",
      detailNepali: "",
    },
  ]);
  const [enableInput, setEnableInput] = useState(false);
  const [imageKey, setImageKey] = useState(0);

  const toggleCollapse = (index) => {
    setIsCollapsed((prevState) =>
      prevState.map((state, i) => (i === index ? !state : state))
    );
  };

  const handleAddStory = () => {
    setStoryList([
      ...storyList,
      {
        desc: "",
        descNepali: "",
        image: "",
        person: "",
        personNepali: "",
        display: false,
        detail: "",
        detailNepali: "",
      },
    ]);
  };

  const handelRemoveStory = async (index, id) => {
    if (id && id !== "") {
      const response = await deleteStory(id);
      toast.success(`Story has been deleted successfully  `, {
        position: "top-center",
        autoClose: 700,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
        theme: "colored",
      });
      fetchStories();
      removeStory(index);
    } else {
      removeStory(index);
    }
  };

  const removeStory = (index) => {
    const updatedStory = [...storyList];
    updatedStory.splice(index, 1);
    setStoryList(updatedStory);
  };

  const handleSaveStory = async (index, id, event) => {
    event.preventDefault();

    if (id && id !== "") {
      const storyToUpdate = storyList[index];
      try {
        const response = await updateStory([storyToUpdate]);
        toast.success(`Story has been edited successfully  `, {
          position: "top-center",
          autoClose: 700,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
          theme: "colored",
        });
        fetchStories();
        setImageKey((prevKey) => prevKey + 1);
      } catch (error) {
        toast.error(`${JSON.stringify(error)} `, {
          position: "top-center",
          autoClose: 700,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
          theme: "colored",
        });
      }
    } else {
      await submitStory(event);
    }
    fetchStories();
  };

  const handleChange = (index, field, e) => {
    const updatedStories = [...storyList];

    let value;
    if (e.target.type === "checkbox") {
      value = e.target.checked;
    } else {
      value = e.target.value;
    }

    if (e.target.name === "avatar") {
      updatedStories[index]["newImage"] = new FormData();
      const reader = new FileReader();
      reader.readAsDataURL(e.target.files[0]);
      reader.onload = () => {
        if (reader.readyState === 2) {
          updatedStories[index]["image"] = reader.result;
          updatedStories[index]["newImage"] = reader.result;
          setStoryList(updatedStories);
        }
      };
    } else {
      updatedStories[index][field] = value;
      setStoryList(updatedStories);
    }
  };

  const submitStoryHeading = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("heading", storyHeading.heading);
    formData.append("headingNepali", storyHeading.headingNepali);
    formData.append("readMoreBtnColor", color);
    try {
      const response = await addStoryHeading(formData);
      toast.success("stroy Saved Successfully!", {
        position: "top-center",
        autoClose: 700,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
        theme: "colored",
      });
    } catch (error) {
      toast.error(error.response.data.errormessage, {
        position: "top-center",
        autoClose: 700,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
        theme: "colored",
      });
      // alert(error);
    }
  };

  const submitStory = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("desc", descRef.current.value);
    formData.append("descNepali", descNepaliRef.current.value);
    formData.append("person", personRef.current.value);
    formData.append("personNepali", personNepaliRef.current.value);
    formData.append("detail", detailEnglishRef.current.value);
    formData.append("detailNepali", detailEnglishRef.current.value);
    formData.append("image", imageRef.current.files[0]); // Append the file to FormData
    try {
      const response = await addStory(formData);
      if (response) {
        toast.success("stroy Saved Successfully!", {
          position: "top-center",
          autoClose: 700,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: 0,
          theme: "colored",
        });
      }
    } catch (error) {
      let msg;
      if (error.code === "ERR_NETWORK") {
        msg = "Something went wrong !!!";
      } else {
        msg = error.response.data.errorMessage;
      }
      toast.error(msg, {
        position: "top-center",
        autoClose: 700,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: 0,
        theme: "colored",
      });
    }
  };

  const storyUpdatedMsg = (id) => {
    toast.success(`story has been updated successfully `, {
      position: "top-center",
      autoClose: 700,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: 0,
      theme: "colored",
    });
    setModalOpen(false);
  };

  async function fetchStories() {
    try {
      const response = await getStories();
      setStoryList(response.data.data.contents);
    } catch (error) {
      console.error("Error fetching stories:", error);
    }
  }

  useEffect(() => {
    fetchStories();
  }, []);

  useEffect(() => {
    setIsCollapsed(new Array(storyList.length).fill(true));
  }, [storyList.length]);

  useEffect(() => {
    async function fetchStoryHeading() {
      const response = await getStoryHeading();
      // setStoryHeading(response.json);
      const rData = response.data.data;
      if (rData) {
        setStoryHeading(rData);
      }
    }
    fetchStoryHeading();
  }, []);
  return (
    <>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Stories</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="index.html">Auth</a>
              </li>
              <li className="breadcrumb-item">Components</li>
              <li className="breadcrumb-item active">Stories</li>
            </ol>
          </nav>
        </div>
        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">Stories Page</h5>

                  <hr className="border-2" />
                  <div className="row">
                    {/* a qukc brown fox jumps over the lazy dog. a qukc brown fox
                    jumps over the lazy dog.{" "} */}
                    <div className="col-md-5">
                      <div className="mb-3">
                        <label className="form-label"> Heading</label>
                        <input
                          type="text"
                          value={storyHeading.heading}
                          name="heading"
                          ref={headingRef}
                          className="form-control"
                          onChange={(e) =>
                            setStoryHeading({
                              ...storyHeading,
                              heading: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-5">
                      <div className="mb-3">
                        <label className="form-label"> Heading Nepali</label>
                        <input
                          value={storyHeading.headingNepali}
                          name="headingNepali"
                          type="text"
                          className="form-control"
                          onChange={(e) =>
                            setStoryHeading({
                              ...storyHeading,
                              headingNepali: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div
                      style={{ marginTop: "10px" }}
                      className="ml-3 d-flex justify-content-center"
                    >
                      <button
                        type="submit"
                        onClick={(e) => submitStoryHeading(e)}
                        className="btn btn-primary "
                      >
                        Create Story's Heading
                      </button>
                    </div>
                  </div>
                  <hr />

                  {storyList &&
                    storyList.map((story, index) => (
                      <>
                        <div key={index} className="parameters-container">
                          <div
                            className="parameters-header"
                            onClick={() => toggleCollapse(index)}
                          >
                            <span className="parameters-title">
                              {index + 1}. Story
                            </span>
                            <div className="icon-containers">
                              <button className="parameters-toggle-btn">
                                <i
                                  onClick={handleAddStory}
                                  class="bi bi-plus-lg"
                                ></i>
                              </button>
                              <button className="parameters-toggle-btn">
                                <i
                                  onClick={() =>
                                    handelRemoveStory(index, story._id)
                                  }
                                  class="bi bi-trash3"
                                ></i>
                              </button>
                              <button
                                className="parameters-toggle-btn"
                                id={`toggleButton${index}`}
                              >
                                {isCollapsed[index] ? "\u25BC" : "\u25B2"}
                              </button>
                            </div>
                          </div>
                          <div
                            className="parameters-body"
                            id={`parametersBody${index}`}
                            style={{
                              display: isCollapsed[index] ? "none" : "block",
                            }}
                          >
                            <div className=" border border-dark rounded">
                              <div className="d-flex mt-3 container">
                                <h5 className="card-title">
                                  {index + 1}. Story
                                </h5>

                                <hr className="border-2" />
                                <div className=" justify-content-end">
                                  <button
                                    onClick={(e) =>
                                      handleSaveStory(index, story._id, e)
                                    }
                                    className="btn btn-primary"
                                  >
                                    Save
                                  </button>
                                </div>
                              </div>
                              <div className=" row m-2 ">
                                <div className="col-md-12 mb-3">
                                  <label className="form-label">
                                    Person Image
                                  </label>
                                  <label className="form-label">
                                    (Best Fit Size: width: 150px; height:
                                    150px;)
                                  </label>{" "}
                                  <input
                                    type="file"
                                    ref={imageRef}
                                    className="form-control"
                                    multiple
                                    accept="image/jpg, image/png, image/gif, image/jpeg"
                                  ></input>
                                  <div>
                                    <img
                                      style={{
                                        height: "200px",
                                        width: "300px",
                                        objectFit: "contain",
                                      }}
                                      src={story.image}
                                      alt="Uploaded Photograph"
                                    />
                                  </div>
                                </div>

                                <div class="form-check col-md-12 mb-3">
                                  <input
                                    class="form-check-input "
                                    type="checkbox"
                                    checked={story.display}
                                    onChange={(e) =>
                                      handleChange(index, "display", e)
                                    }
                                    id="isDisplay"
                                  />
                                  <label
                                    class="form-check-label"
                                    for="isDisplay"
                                  >
                                    Display
                                  </label>
                                </div>

                                <div className="col-md-6">
                                  <h4 className="card-title">English </h4>
                                  <hr className="border-2" />

                                  <div className=" mb-3">
                                    <label className="form-label">
                                      Description
                                    </label>
                                    <textarea
                                      ref={descRef}
                                      onChange={(e) =>
                                        handleChange(index, "desc", e)
                                      }
                                      value={story.desc}
                                      className="form-control"
                                      rows="3"
                                    ></textarea>
                                  </div>

                                  <div className="mb-3">
                                    <label className="form-label">
                                      Person Name
                                    </label>
                                    <input
                                      type="text"
                                      value={story.person}
                                      ref={personRef}
                                      className="form-control"
                                      onChange={(e) =>
                                        handleChange(index, "person", e)
                                      }
                                    />
                                  </div>
                                  <hr />
                                  <div className=" mb-3">
                                    <label className="form-label">
                                      Detail Page
                                    </label>
                                    <JoditEditor
                                      ref={detailEnglishRef}
                                      tabIndex={1}
                                      value={story.detail ? story.detail : ""}
                                      config={englishConfig}
                                      onChange={(e) =>
                                        handleChange(index, "detail", {
                                          target: { type: "editor", value: e },
                                        })
                                      }
                                    />
                                  </div>
                                </div>

                                <div className="col-md-6">
                                  <h4 className="card-title">Nepali </h4>
                                  <hr className="border-2" />
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Description
                                    </label>
                                    <textarea
                                      ref={descNepaliRef}
                                      value={story.descNepali}
                                      onChange={(e) =>
                                        handleChange(index, "descNepali", e)
                                      }
                                      className="form-control"
                                      rows="3"
                                    ></textarea>
                                  </div>
                                  <div className="mb-3">
                                    <label className="form-label">
                                      Person Name
                                    </label>
                                    <input
                                      type="text"
                                      value={story.personNepali}
                                      ref={personNepaliRef}
                                      onChange={(e) =>
                                        handleChange(index, "personNepali", e)
                                      }
                                      className="form-control"
                                    />
                                  </div>
                                  <hr />
                                  <div className=" mb-3">
                                    <label className="form-label">
                                      Detail Page
                                    </label>
                                    <JoditEditor
                                      ref={detailNepaliRef}
                                      tabIndex={1}
                                      value={
                                        story.detailNepali
                                          ? story.detailNepali
                                          : ""
                                      }
                                      config={nepaliConfig}
                                      onChange={(e) =>
                                        handleChange(index, "detailNepali", {
                                          target: {
                                            type: "editorNeplai",
                                            value: e,
                                          },
                                        })
                                      }
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="m-3 d-flex justify-content-end  ">
                                <button
                                  onClick={() =>
                                    handelRemoveStory(index, story._id)
                                  }
                                  className="btn btn-primary"
                                >
                                  Remove Story
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                        <br />
                      </>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <hr />
      <ToastContainer />
    </>
  );
};

export default Stories;
const style = {
  position: "absolute",
  top: "40%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "fit-content",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
