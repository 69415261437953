import React from "react";
import { useEffect, useState } from "react";
import { toastError, toastSuccess } from "../../../../services/ToastService";
import { ToastContainer } from "react-toastify";
import {
  deleteEnglishOurValues,
  deleteNepaliOurValues,
  getAllOurValues,
  saveEnglishOurValues,
  saveEnglishOurValuesHeading,
  saveNepaliOurValues,
  saveNepaliOurValuesHeading,
} from "../../../../services/api";

const AboutUsOurValues = () => {
  const [isCollapsed, setIsCollapsed] = useState([]);
  const [formsFieldsEnglish, setformsFieldsEnglish] = useState({
    heading: "",
    values: [
      {
        _id: "",
        image: "",
        title: "",
      },
    ],
  });

  const [formsFieldsNepali, setformsFieldsNepali] = useState({
    heading: "",
    values: [
      {
        _id: "",
        image: "",
        title: "",
      },
    ],
  });
  const [error, setError] = useState("");

  const getallOurValues = async () => {
    try {
      let res = await getAllOurValues();
      if (res.data) {
        res.data.forEach((item) => {
          if (item.locale === "eng") {
            setformsFieldsEnglish((prevState) => ({
              ...prevState,
              heading: item.heading,
              values: item.values,
            }));
          } else if (item.locale === "nep") {
            setformsFieldsNepali((prevState) => ({
              ...prevState,
              heading: item.heading,
              values: item.values,
            }));
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleEnglishInputChange = (index, event) => {
    const { name, value } = event.target;
    setformsFieldsEnglish((prevState) => {
      const updatedOurValues = [...prevState.values];
      updatedOurValues[index][name] = value;
      return { ...prevState, values: updatedOurValues };
    });
  };

  const handleNepaliInputChange = (index, event) => {
    const { name, value } = event.target;
    setformsFieldsNepali((prevState) => {
      const updatedOurValues = [...prevState.values];
      updatedOurValues[index][name] = value;
      return { ...prevState, values: updatedOurValues };
    });
  };

  const handleAddOurValues = () => {
    setformsFieldsEnglish({
      ...formsFieldsEnglish,
      values: [
        ...formsFieldsEnglish.values,
        {
          _id: "",
          image: "",
          title: "",
        },
      ],
    });

    setformsFieldsNepali({
      ...formsFieldsNepali,
      values: [
        ...formsFieldsNepali.values,
        {
          _id: "",
          image: "",
          title: "",
        },
      ],
    });
  };

  const handleRemoveOurValues = async (
    index,
    nepaliOurValues,
    enslishOurValues
  ) => {
    if (
      nepaliOurValues._id &&
      nepaliOurValues._id !== "" &&
      enslishOurValues._id &&
      enslishOurValues._id !== ""
    ) {
      await deleteNepaliOurValues(nepaliOurValues._id);
      await deleteEnglishOurValues(enslishOurValues._id);

      removeOurValues(index);
    } else {
      removeOurValues(index);
    }
  };

  const removeOurValues = (index) => {
    const updatedEnglishOurValues = [...formsFieldsEnglish.values];
    updatedEnglishOurValues.splice(index, 1);
    setformsFieldsEnglish({
      ...formsFieldsEnglish,
      values: updatedEnglishOurValues,
    });

    const updatedNepaliOurValues = [...formsFieldsNepali.values];
    updatedNepaliOurValues.splice(index, 1);
    setformsFieldsNepali({
      ...formsFieldsNepali,
      values: updatedNepaliOurValues,
    });
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    try {
      if (formsFieldsEnglish) {
        let data = {
          heading: formsFieldsEnglish.heading,
        };
        await saveEnglishOurValuesHeading(data);
      }

      if (formsFieldsNepali) {
        let data = {
          heading: formsFieldsNepali.heading,
        };
        await saveNepaliOurValuesHeading(data);
      }

      toastSuccess();
    } catch (e) {
      toastError();
      console.log(e);
    }
  };

  const handleImageChange = (index, event) => {
    const file = event.target.files[0];
    if (file) {
      // Check the file size (in bytes)
      if (file.size > 320 * 1024) {
        // 320 KB = 320 * 1024 bytes
        setError("Please upload an image below the size of 320KB");
        // Optionally, clear the file input
        event.target.value = "";
      } else {
        setError(""); // Clear error if file size is valid
        // Proceed with uploading the file or processing the image
      }
    }

    setformsFieldsEnglish((prevState) => {
      const updatedOurValues = [...prevState.values];
      updatedOurValues[index].image = file;
      return { ...prevState, values: updatedOurValues };
    });

    setformsFieldsNepali((prevState) => {
      const updatedOurValues = [...prevState.values];
      updatedOurValues[index].image = file;
      return { ...prevState, values: updatedOurValues };
    });
  };

  useEffect(() => {
    setIsCollapsed(new Array(formsFieldsEnglish.values.length).fill(true));
  }, [formsFieldsEnglish.values.length]);

  useEffect(() => {
    getallOurValues();
  }, []);

  const toggleCollapse = (index) => {
    setIsCollapsed((prevState) =>
      prevState.map((state, i) => (i === index ? !state : state))
    );
  };

  const handleSaveOurValues = async (index, e) => {
    e.preventDefault();
    try {
      if (formsFieldsEnglish) {
        const formData = new FormData();
        formData.append("title", formsFieldsEnglish.values[index].title);
        formData.append("image", formsFieldsEnglish.values[index].image);
        formData.append("id", formsFieldsEnglish.values[index]._id);
        await saveEnglishOurValues(formData);
      }

      if (formsFieldsNepali) {
        const formData = new FormData();
        formData.append("title", formsFieldsNepali.values[index].title);
        formData.append("image", formsFieldsNepali.values[index].image);
        formData.append("id", formsFieldsNepali.values[index]._id);
        await saveNepaliOurValues(formData);
      }
      toastSuccess();
    } catch (error) {
      console.log(error);
      toastError();
    }
  };

  return (
    <>
      <main className="main">
        <div className="pagetitle">
          <h1>OurValues</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="index.html">Auth</a>
              </li>
              <li className="breadcrumb-item">Components</li>
              <li className="breadcrumb-item active">OurValues</li>
            </ol>
          </nav>
        </div>
        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="mt-3 mb-3 d-flex justify-content-end">
                    <button
                      type="submit"
                      onClick={onSubmit}
                      className="btn btn-primary"
                    >
                      Save
                    </button>
                  </div>
                  <div className="row ">
                    <div className="col-md-6">
                      <h4 className="card-title">English </h4>
                      <hr className="border-2" />
                      <div className="mb-3">
                        <label className="form-label">Heading</label>
                        <textarea
                          type="text"
                          className="form-control"
                          value={formsFieldsEnglish.heading}
                          onChange={(e) =>
                            setformsFieldsEnglish({
                              ...formsFieldsEnglish,
                              heading: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <h4 className="card-title">Nepali </h4>
                      <hr className="border-2" />
                      <div className="mb-3">
                        <label className="form-label">Heading</label>
                        <textarea
                          type="text"
                          className="form-control"
                          value={formsFieldsNepali.heading}
                          onChange={(e) =>
                            setformsFieldsNepali({
                              ...formsFieldsNepali,
                              heading: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>

                  {formsFieldsEnglish.values.map((values, index) => (
                    <>
                      <div key={index} className="parameters-container">
                        <div
                          className="parameters-header"
                          onClick={() => toggleCollapse(index)}
                        >
                          <span className="parameters-title">
                            {index + 1}. OurValues
                          </span>
                          <div className="icon-containers">
                            <button className="parameters-toggle-btn">
                              <i
                                onClick={handleAddOurValues}
                                class="bi bi-plus-lg"
                              ></i>
                            </button>
                            <button className="parameters-toggle-btn">
                              <i
                                onClick={() =>
                                  handleRemoveOurValues(
                                    index,
                                    formsFieldsNepali.values[index],
                                    values
                                  )
                                }
                                class="bi bi-trash3"
                              ></i>
                            </button>
                            <button
                              className="parameters-toggle-btn"
                              id={`toggleButton${index}`}
                            >
                              {isCollapsed[index] ? "\u25BC" : "\u25B2"}
                            </button>
                          </div>
                        </div>
                        <div
                          className="parameters-body"
                          id={`parametersBody${index}`}
                          style={{
                            display: isCollapsed[index] ? "none" : "block",
                          }}
                        >
                          <div className=" border border-dark rounded">
                            <div className="d-flex mt-3 container">
                              <h5 className="card-title">
                                {index + 1}. OurValues
                              </h5>
                              <hr className="border-2" />
                              <div className=" justify-content-end">
                                <button
                                  onClick={(e) => handleSaveOurValues(index, e)}
                                  className="btn btn-primary"
                                >
                                  Save
                                </button>
                              </div>
                            </div>
                            <div className=" row m-2 ">
                              <div className="col-md-6 mb-3">
                                <label className="form-label">
                                  Image (Best Fit Size: height: 70px; width:
                                  70px;)
                                </label>
                                <input
                                  type="file"
                                  onChange={(e) => handleImageChange(index, e)}
                                  accept="image/jpg, image/png, image/gif, image/jpeg"
                                />
                                {error && (
                                  <div
                                    style={{ color: "red", marginTop: "5px" }}
                                  >
                                    {error}
                                  </div>
                                )}
                              </div>

                              <div className=" mb-3 col-md-6">
                                <img
                                  style={{
                                    height: "200px",
                                    width: "300px",
                                    objectFit: "contain",
                                  }}
                                  src={values.image}
                                  alt="Uploaded Photograph"
                                />
                              </div>

                              <div className="col-md-6">
                                <h4 className="card-title">English </h4>
                                <hr className="border-2" />
                                <div className=" mb-3">
                                  <label className="form-label">Title</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={values.title}
                                    name="title"
                                    onChange={(e) =>
                                      handleEnglishInputChange(index, e)
                                    }
                                  />
                                </div>
                              </div>

                              <div className="col-md-6">
                                <h4 className="card-title">Nepali </h4>
                                <hr className="border-2" />
                                <div className="mb-3">
                                  <label className="form-label">Title</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={
                                      formsFieldsNepali.values[index].title
                                    }
                                    name="title"
                                    onChange={(e) =>
                                      handleNepaliInputChange(index, {
                                        target: {
                                          name: "title",
                                          value: e.target.value,
                                        },
                                      })
                                    }
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="m-3 d-flex justify-content-end  ">
                              <button
                                onClick={() =>
                                  handleRemoveOurValues(
                                    index,
                                    formsFieldsNepali.values[index],
                                    values
                                  )
                                }
                                className="btn btn-primary"
                              >
                                Remove OurValues
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <br />
                    </>
                  ))}

                  <div className="mt-3 mb-3 d-flex justify-content-end">
                    <button
                      className="btn btn-primary"
                      onClick={handleAddOurValues}
                    >
                      Add OurValues
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ToastContainer />
      </main>
    </>
  );
};

export default AboutUsOurValues;
