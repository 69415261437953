import React, { useEffect, useState } from "react";
import { getMentalHealth } from "../../../services/api";
import Accessibility from "../Accessibility";

const TakeAMentalHealth = () => {
  const [mentalHealth, setMentalHealth] = useState({});

  const fetchData = async () => {
    try {
      const response = await getMentalHealth();
      setMentalHealth(response.data);
    } catch (error) {}
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <>
        <section>
          <Accessibility />

          <div>
            <div dangerouslySetInnerHTML={{ __html: mentalHealth.content }} />
          </div>
        </section>
      </>
    </>
  );
};

export default TakeAMentalHealth;
