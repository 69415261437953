import React, { useEffect, useState } from "react";
import Accessibility from "../Accessibility";
import { getVacancy } from "../../../services/api";
import { useParams } from "react-router-dom";

const GetInvolvedVacancyById = () => {
  const [vacancy, setVacancy] = useState("");
  let { id } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getVacancy();
        setTimeout(() => {
          if (res.data) {
            let vacancy = res.data.vacancy.find(
              (item) => item._id.toString() === id.toString()
            );
            if (vacancy) {
              setVacancy(vacancy);
            } else {
              setVacancy(res.data.partner[0]);
            }
          }
        });
      } catch (error) {}
    };

    fetchData();
  }, []);

  return (
    <>
      <section className="about-us-section ">
        <div>
          <div dangerouslySetInnerHTML={{ __html: vacancy.content }} />
        </div>
      </section>
    </>
  );
};

export default GetInvolvedVacancyById;
