import React, { useEffect, useRef, useState } from "react";
import { getInvolvedData } from "../../services/getInvolvedService";
import { NavLink, useParams } from "react-router-dom";
import { getBannerImages, getVacancy, getVolenteer } from "../../services/api";
import coverImage from "../../assets/img/cover.jpg";
import Accessibility from "./Accessibility";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";
import Popup from "../shared/Popup/Popup";
import { ToastContainer, toast } from "react-toastify";
import { toastPosition } from "../../config/toastProp";
import { sendNewsLetterToEmails } from "../../services/NewsLetterGroupMapping";

export const GetInvolvedHome = () => {
  const [vacancy, setVacancy] = useState("");
  const [procurement, setProcurement] = useState("");
  const [volunteer, setVolunteer] = useState("");
  const [donate, setDonate] = useState("");
  const [locale, setLocale] = useState("nep");
  const [host, setHost] = useState("");
  let { fieldName } = useParams();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedData, setSelectedData] = useState();
  const vacancyFileRef = useRef();
  const volenteerFileRef = useRef();
  const [emailData, setEmailData] = useState({
    name: "",
    email: "",
  });

  const stateDatas = {
    procurement: setProcurement,
    donate: setDonate,
  };
  const [navbarImages, setNavBarImages] = useState();

  const stateKeys = Object.keys(stateDatas);
  useEffect(() => {
    const storedLocale = localStorage.getItem("locale") || "eng";
    setLocale(storedLocale === "eng" ? "eng" : "nep");
    setHost(window.location.host);
  }, []);
  useEffect(() => {
    const getDatasForGetInvolved = async (field, locale = "") => {
      try {
        const res = await getInvolvedData(field + locale);
        const statedData = res.data.data;
        const stateUpdateFunc = stateDatas[field];
        stateUpdateFunc(statedData);
      } catch (error) {}
    };

    stateKeys.forEach((state) => {
      if (locale.toLocaleLowerCase() === "eng") {
        getDatasForGetInvolved(state);
      } else if (locale.toLocaleLowerCase() === "nep") {
        getDatasForGetInvolved(state, "Nepali");
      }
    });
    fetchData();
  }, [locale]);

  const fetchData = async () => {
    try {
      const res = await getBannerImages();
      const resData = res.data;
      if (resData) {
        setNavBarImages(resData);
      }

      let vacancy = await getVacancy();
      if (vacancy.data) {
        setVacancy(vacancy.data);
      }

      let volenteer = await getVolenteer();
      if (volenteer.data) {
        setVolunteer(volenteer.data);
      }
    } catch (error) {}
  };

  const togglePopup = (item) => {
    setSelectedData(item);

    setTimeout(() => {
      setIsPopupOpen(!isPopupOpen);
    }, 200);
  };

  const submit = (e, item) => {
    e.preventDefault();

    const mapData = {};
    mapData["groups"] = [emailData.email];
    const reader = new FileReader();

    if (item === "vacancy") {
      if (vacancyFileRef.current.files.length === 0) {
        toast.error("please upload file !", toastPosition);
        return;
      }

      reader.readAsDataURL(vacancyFileRef.current.files[0]);
    } else {
      if (volenteerFileRef.current.files.length === 0) {
        toast.error("please upload file !", toastPosition);
        return;
      }

      reader.readAsDataURL(volenteerFileRef.current.files[0]);
    }

    reader.onload = async () => {
      if (reader.readyState === 2) {
        mapData["file"] = reader.result;
        if (item === "vacancy") {
          mapData["subject"] = "Vacancy Application";
          mapData["content"] =
            "Vacancy Application Request From " +
            emailData.email +
            " for " +
            (emailData.name !== "" ? emailData.name : selectedData.name);
        } else {
          mapData["subject"] = "Volenteer Application";
          mapData["content"] =
            "Volunteer Application Request From " +
            emailData.email +
            " for " +
            (emailData.name !== "" ? emailData.name : selectedData.name);
        }

        mapData["isGetInvolved"] = true;
        try {
          const res = await sendNewsLetterToEmails(mapData);
          toast.success(`Send Successfully`, toastPosition);
          setEmailData({
            name: "",
            email: "",
          });
          setIsPopupOpen(!isPopupOpen);
        } catch (error) {
          toast.error(error.response.data.errormessage, toastPosition);
        }
      }
    };
  };

  return (
    <>
      <section className="about-us-section ">
        <Accessibility />
        {fieldName === "vacancy" && (
          <div
            className="banner"
            style={{
              backgroundImage: `url('${
                navbarImages && navbarImages.getInvolvedVacancy
                  ? navbarImages.getInvolvedVacancy
                  : coverImage
              }')`,
            }}
          >
            <div className="banner-content">
              <h1 className="text-white text-center">
                {" "}
                {locale === "eng" ? "Vacancy" : "रिक्ति"}
              </h1>
            </div>
          </div>
        )}

        {fieldName === "procurement" && (
          <div
            className="banner"
            style={{
              backgroundImage: `url('${
                navbarImages && navbarImages.getInvolvedProcurement
                  ? navbarImages.getInvolvedProcurement
                  : coverImage
              }')`,
            }}
          >
            <div className="banner-content">
              <h1 className="text-white text-center">
                {" "}
                {locale === "eng" ? "Procurement" : "खरिदारी"}
              </h1>
            </div>
          </div>
        )}

        {fieldName === "volunteer" && (
          <div
            className="banner"
            style={{
              backgroundImage: `url('${
                navbarImages && navbarImages.getInvolvedVolunteer
                  ? navbarImages.getInvolvedVolunteer
                  : coverImage
              }')`,
            }}
          >
            <div className="banner-content">
              <h1 className="text-white text-center">
                {" "}
                {locale === "eng" ? "Volunteer" : "स्वयंसेवक"}
              </h1>
            </div>
          </div>
        )}

        {fieldName === "donate" && (
          <div
            className="banner"
            style={{
              backgroundImage: `url('${
                navbarImages && navbarImages.getInvolvedDonate
                  ? navbarImages.getInvolvedDonate
                  : coverImage
              }')`,
            }}
          >
            <div className="banner-content">
              <h1 className="text-white text-center">
                {" "}
                {locale === "eng" ? "Donate" : "दान गर्नुहोस्"}
              </h1>
            </div>
          </div>
        )}
        <div className="container">
          <div className="">
            <div className="card-body">
              {fieldName && fieldName === "vacancy" && (
                <>
                  {vacancy && vacancy.vacancy ? (
                    vacancy.vacancy.map((item, index) => (
                      <div key={index} className="container">
                        <div>
                          <div className="card-body">
                            <div className="row mt-4">
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: item.content,
                                }}
                              >
                                {}
                              </p>

                              <div className="d-flex flex-row justify-content-evenly ">
                                <FacebookShareButton
                                  url={`${host}/getInvolved/vacancy/${item._id}`}
                                >
                                  <div className="social-icons px-2">
                                    <i class="fa-brands fa-facebook-f"></i>
                                  </div>
                                </FacebookShareButton>

                                <TwitterShareButton
                                  url={`${host}/getInvolved/vacancy/${item._id}`}
                                >
                                  <div className="social-icons px-2">
                                    <i class="fa-brands fa-x-twitter"></i>
                                  </div>
                                </TwitterShareButton>

                                <LinkedinShareButton
                                  url={`${host}/getInvolved/vacancy/${item._id}`}
                                >
                                  <div className="social-icons px-2">
                                    <i class="fa-brands fa-linkedin"></i>
                                  </div>
                                </LinkedinShareButton>

                                <div
                                  className="social-icons px-2 "
                                  style={{ marginTop: "14px" }}
                                >
                                  <NavLink to={"mailto:"}>
                                    <i class="fa-solid fa-envelope"></i>
                                  </NavLink>
                                </div>

                                <button
                                  type="button"
                                  onClick={() => togglePopup(item, "vacancy")}
                                  style={{ background: "var(--light-blue)" }}
                                  className="btn btn-primary m-2 "
                                >
                                  Apply
                                </button>
                                <Popup
                                  isOpen={isPopupOpen}
                                  onClose={togglePopup}
                                >
                                  <form>
                                    <div className="form-group">
                                      <label for="exampleInputEmail1">
                                        Email address
                                      </label>
                                      <input
                                        type="email"
                                        className="form-control"
                                        onChange={(e) =>
                                          setEmailData({
                                            ...emailData,
                                            email: e.target.value,
                                          })
                                        }
                                        id="exampleInputEmail1"
                                        aria-describedby="emailHelp"
                                        placeholder="Enter email"
                                      />
                                    </div>
                                    <div className="form-group">
                                      <label for="name">Name</label>
                                      <input
                                        type="text"
                                        className="form-control"
                                        value={
                                          selectedData && selectedData.name
                                            ? selectedData.name
                                            : ""
                                        }
                                        id="name"
                                        placeholder="Enter Name"
                                      />
                                    </div>

                                    <div className="form-group">
                                      <label for="exampleFormControlFile1">
                                        Upload Document in PDF
                                      </label>
                                      <input
                                        type="file"
                                        ref={vacancyFileRef}
                                        className="form-control-file"
                                        id="exampleFormControlFile1"
                                      />
                                    </div>
                                    <button
                                      type="submit"
                                      onClick={(event) =>
                                        submit(event, "vacancy")
                                      }
                                      className="btn btn-primary"
                                    >
                                      Submit
                                    </button>
                                  </form>
                                </Popup>
                                <ToastContainer></ToastContainer>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <></>
                  )}
                </>
              )}

              {fieldName && fieldName === "procurement" && (
                <>
                  <p dangerouslySetInnerHTML={{ __html: procurement }}>{}</p>
                  <div className="d-flex flex-row justify-content-evenly ">
                    <FacebookShareButton
                      url={`${host}/getInvolved/procurement`}
                    >
                      <div className="social-icons px-2">
                        <i class="fa-brands fa-facebook-f"></i>
                      </div>
                    </FacebookShareButton>

                    <TwitterShareButton url={`${host}/getInvolved/procurement`}>
                      <div className="social-icons px-2">
                        <i class="fa-brands fa-x-twitter"></i>
                      </div>
                    </TwitterShareButton>

                    <LinkedinShareButton
                      url={`${host}/getInvolved/procurement`}
                    >
                      <div className="social-icons px-2">
                        <i class="fa-brands fa-linkedin"></i>
                      </div>
                    </LinkedinShareButton>

                    <div className="social-icons px-2 ">
                      <NavLink to={"mailto:"}>
                        <i class="fa-solid fa-envelope"></i>
                      </NavLink>
                    </div>
                  </div>
                </>
              )}

              {fieldName && fieldName === "volunteer" && (
                <>
                  {volunteer && volunteer.volenteer ? (
                    volunteer.volenteer.map((item, index) => (
                      <div key={index} className="container">
                        <div>
                          <div className="card-body">
                            <div className="row mt-4">
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: item.content,
                                }}
                              >
                                {}
                              </p>

                              <FacebookShareButton
                                url={`${host}/getInvolved/volunteer/${item._id}`}
                              >
                                <div className="social-icons px-2">
                                  <i class="fa-brands fa-facebook-f"></i>
                                </div>
                              </FacebookShareButton>

                              <TwitterShareButton
                                url={`${host}/getInvolved/volunteer/${item._id}`}
                              >
                                <div className="social-icons px-2">
                                  <i class="fa-brands fa-x-twitter"></i>
                                </div>
                              </TwitterShareButton>

                              <LinkedinShareButton
                                url={`${host}/getInvolved/volunteer/${item._id}`}
                              >
                                <div className="social-icons px-2">
                                  <i class="fa-brands fa-linkedin"></i>
                                </div>
                              </LinkedinShareButton>

                              <div
                                className="social-icons px-2 "
                                style={{ marginTop: "14px" }}
                              >
                                <NavLink to={"mailto:"}>
                                  <i class="fa-solid fa-envelope"></i>
                                </NavLink>
                              </div>

                              <button
                                type="button"
                                onClick={() => togglePopup(item, "vacancy")}
                                style={{ background: "var(--light-blue)" }}
                                className="btn btn-primary m-2 "
                              >
                                Apply
                              </button>
                              <Popup isOpen={isPopupOpen} onClose={togglePopup}>
                                <form>
                                  <div className="form-group">
                                    <label for="exampleInputEmail1">
                                      Email address
                                    </label>
                                    <input
                                      type="email"
                                      className="form-control"
                                      onChange={(e) =>
                                        setEmailData({
                                          ...emailData,
                                          email: e.target.value,
                                        })
                                      }
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                      placeholder="Enter email"
                                    />
                                  </div>
                                  <div className="form-group">
                                    <label for="name">Name</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      value={
                                        selectedData && selectedData.name
                                          ? selectedData.name
                                          : ""
                                      }
                                      onChange={(e) =>
                                        setEmailData({
                                          ...emailData,
                                          name: e.target.value,
                                        })
                                      }
                                      id="name"
                                      placeholder="Enter Name"
                                    />
                                  </div>

                                  <div className="form-group">
                                    <label for="exampleFormControlFile1">
                                      Upload Document in PDF
                                    </label>
                                    <input
                                      type="file"
                                      ref={volenteerFileRef}
                                      className="form-control-file"
                                      id="exampleFormControlFile1"
                                    />
                                  </div>
                                  <button
                                    type="submit"
                                    onClick={(event) =>
                                      submit(event, "volenteer")
                                    }
                                    className="btn btn-primary"
                                  >
                                    Submit
                                  </button>
                                </form>
                              </Popup>
                              <ToastContainer></ToastContainer>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <></>
                  )}
                </>
              )}

              {fieldName && fieldName === "donate" && (
                <>
                  <div>
                    <p dangerouslySetInnerHTML={{ __html: donate }}></p>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
