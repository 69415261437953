import React, { useEffect, useState } from "react";
import { getOurProgramData } from "../../services/ourProgramService";
import { useParams } from "react-router-dom";
import { getBannerImages, getOurWorkImages } from "../../services/api";
import coverImage from "../../assets/img/cover.jpg";
import Accessibility from "./Accessibility";
import PropagateLoader from "react-spinners/PropagateLoader";

const OurProgramHome = () => {
  const [field, setFieldValue] = useState("");
  const [locale, setLocale] = useState("eng");
  const [navbarImages, setNavBarImages] = useState();
  const [loading, setLoading] = useState(true); // Add loading state

  const { programType } = useParams();

  const fetchFieldValue = async (field) => {
    try {
      const res = await getOurProgramData(field);
      const stateData = res.data.data;
      setFieldValue(stateData[field]);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    let Locale = localStorage.getItem("locale") === "nep" ? "nep" : "eng";
    setLocale(Locale);

    const fetchData = async () => {
      setLoading(true); // Start loader
      try {
        if (locale === "eng") {
          await fetchFieldValue(programType);
        } else {
          await fetchFieldValue(programType + "Nepali");
        }

        const res = await getBannerImages();
        setNavBarImages(res.data || {});
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false); // Stop loader
      }
    };

    fetchData();
  }, [programType, locale]);

  return (
    <>
      <section className="about-us-section">
        <Accessibility />
        {loading ? (
          <div className="p-4 ml-6">
            Loading <PropagateLoader />
          </div>
        ) : (
          <>
            {programType === "advocacyAwarness" && (
              <div
                className="banner"
                style={{
                  backgroundImage: `url('${
                    navbarImages?.ourWorkAdvocacyAwarness || coverImage
                  }')`,
                }}
              >
                <div className="banner-content">
                  <h1 className="text-white text-center">
                    {locale === "eng" ? "Advocacy Awarness" : "वकालत चेतना"}
                  </h1>
                </div>
              </div>
            )}

            {programType === "empowermentAndCommunityInclusion" && (
              <div
                className="banner"
                style={{
                  backgroundImage: `url('${
                    navbarImages?.ourWorkEmpowerment || coverImage
                  }')`,
                }}
              >
                <div className="banner-content">
                  <h1 className="text-white text-center">
                    {locale === "eng"
                      ? "Empowerment And Community Inclusion"
                      : "सशक्तिकरण र सामुदायिक समावेशीकरण"}
                  </h1>
                </div>
              </div>
            )}

            {programType === "strengthenCommunitySupportSystem" && (
              <div
                className="banner"
                style={{
                  backgroundImage: `url('${
                    navbarImages?.ourWorkSupport || coverImage
                  }')`,
                }}
              >
                <div className="banner-content">
                  <h1 className="text-white text-center">
                    {locale === "eng"
                      ? "Strengthen Community Support System"
                      : "सामुदायिक समर्थन प्रणाली बलियो बनाउनुहोस्"}
                  </h1>
                </div>
              </div>
            )}

            {programType === "organizationalDevelopment" && (
              <div
                className="banner"
                style={{
                  backgroundImage: `url('${
                    navbarImages?.ourWorkOrgDevelopment || coverImage
                  }')`,
                }}
              >
                <div className="banner-content">
                  <h1 className="text-white text-center">
                    {locale === "eng"
                      ? "Organizational Development"
                      : "संगठनात्मक विकास"}
                  </h1>
                </div>
              </div>
            )}

            {programType === "ecsc" && (
              <div
                className="banner"
                style={{
                  backgroundImage: `url('${
                    navbarImages?.ourWorkESCS || coverImage
                  }')`,
                }}
              >
                <div className="banner-content">
                  <h1 className="text-white text-center">
                    {locale === "eng"
                      ? "Emergency Care Service Center"
                      : "आपतकालीन हेरचाह सेवा केन्द्र"}
                  </h1>
                </div>
              </div>
            )}
            <div className="responsive-div">
              <div className="container">
                <div className="">
                  <div className="card-body">
                    <div dangerouslySetInnerHTML={{ __html: field }}></div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </section>
    </>
  );
};

export default OurProgramHome;
