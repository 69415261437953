import React, { useEffect, useState } from "react";
import { getSliderData } from "../../services/api";

import slider1 from "../../assets/img/slider-1.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper/modules";
import { NavLink } from "react-router-dom";

export default function Slider() {
  const [sliderData, setSliderData] = useState(null);
  const [locale, setLocale] = useState("nep");

  const [videoURL, setVideoURL] = useState(
    "http://localhost:5000/public/videos/1709843178096-file_example_MP4_640_3MG.mp4"
  );

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getSliderData();

        setTimeout(() => {
          if (res) {
            setSliderData(res.data.slider);
            setVideoURL(res.data.slider.video);
          }
        });
      } catch (error) {}
    };

    fetchData();

    const storedLocale = localStorage.getItem("locale") || "eng";
    setLocale(storedLocale === "eng" ? "eng" : "nep");
  }, []);

  return (
    <>
      {sliderData && sliderData.showImage && sliderData.showImage === "true" ? (
        <section className="hero-section">
          <div className="swiper-container">
            <div className="swiper-wrapper">
              <Swiper>
                <SwiperSlide>
                  {" "}
                  <div className="swiper-slide position-relative">
                    <div className="slide-hero-text d-flex flex-column align-items-start">
                      <h1>
                        {sliderData
                          ? sliderData.title
                          : "Championing Possibilities, Building Brighter Horizons"}{" "}
                      </h1>
                      <p>
                        {sliderData
                          ? sliderData.content
                          : "Helping children and youth to thrive. This text could be " +
                            "significanlty longer Lorem ipsum dolor sit amet consectetur" +
                            "adipisicing elit. Dolor optio ab distinctio porro accusamus" +
                            "nisi aperiam, dolores quo, quae possimus laudantium? Debitis" +
                            "aperiam animi est iusto quis cupiditate! Aspernatur, maxime."}
                      </p>
                      <NavLink
                        to="/about-us/introduction"
                        className="btn btn-blue"
                      >
                        {locale === "eng" ? "Learn More" : "थप जान्नुहोस्"}{" "}
                        <i className="fas fa-circle-arrow-right ml-3"></i>
                      </NavLink>
                    </div>
                    <div className="gradient-black"></div>
                    <img
                      src={
                        sliderData &&
                        sliderData.image &&
                        sliderData.image !== ""
                          ? sliderData.image
                          : slider1
                      }
                      alt="Banner"
                      className="img-fluid"
                    />
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
            <div className="swiper-pagination"></div>
          </div>
          <a href="#">
            <div className="go-up-btn">
              <i className="fas fa-arrow-up"></i>
            </div>
          </a>
        </section>
      ) : (
        <section className="hero-section">
          <div className="swiper-container">
            <div>
              <Swiper
                speed={400}
                autoplay={{
                  delay: 5000,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                  type: "bullets",
                }}
                modules={[Autoplay, Pagination]}
              >
                <SwiperSlide>
                  {" "}
                  <div className="swiper-slide position-relative">
                    <div className="slide-hero-text d-flex flex-column align-items-start">
                      <h1>
                        {sliderData
                          ? sliderData.title
                          : "Championing Possibilities, Building Brighter Horizons"}{" "}
                      </h1>
                      <p>
                        {sliderData
                          ? sliderData.content
                          : "Helping children and youth to thrive. This text could be " +
                            "significanlty longer Lorem ipsum dolor sit amet consectetur" +
                            "adipisicing elit. Dolor optio ab distinctio porro accusamus" +
                            "nisi aperiam, dolores quo, quae possimus laudantium? Debitis" +
                            "aperiam animi est iusto quis cupiditate! Aspernatur, maxime."}
                      </p>
                      <NavLink
                        to="/about-us/introduction"
                        className="btn btn-blue"
                      >
                        Learn More{" "}
                        <i className="fas fa-circle-arrow-right ml-3"></i>
                      </NavLink>
                    </div>
                    <div className="gradient-black"></div>
                    <video
                      className="video-background"
                      autoPlay
                      muted
                      loop
                      playsInline
                      src={videoURL}
                    ></video>
                  </div>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </section>
      )}
    </>
  );
}
