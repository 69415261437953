import React, { useState } from "react";
import { SketchPicker } from "react-color";
import { getBranding } from "../../services/api";
import { toastError } from "../../services/ToastService";
import ColorPicker, { useColorPicker } from "react-best-gradient-color-picker";

function Accessibility() {
  const [showColorPicker, setShowColorPicker] = useState();
  const [showHeaderColorPicker, setShowHeaderColorPicker] = useState();

  const [color, setColor] = useState(
    "linear-gradient(90deg, rgba(96,93,93,1) 0%, rgba(255,255,255,1) 100%)"
  );
  const [headerColor, setHeaderColor] = useState(
    "linear-gradient(90deg, rgba(96,93,93,1) 0%, rgba(255,255,255,1) 100%)"
  );
  const { valueToHex } = useColorPicker(color, setColor);
  const headerColorValueToHex = useColorPicker(
    headerColor,
    setHeaderColor
  ).valueToHex;

  const handleAccessibility = (event) => {
    event.preventDefault();
    const isClickInside = document
      .getElementById("accessibility-btn")
      .contains(event.target);
    if (isClickInside) {
      const x = document.getElementById("accessibility-sidebar");
      if (x.style.left != "0px") {
        x.style.left = "0px";
      } else {
        x.style.left = "-188px";
      }
    }
  };

  const fetchData = async () => {
    try {
      const branding = await getBranding();

      if (branding.data && branding.data.length > 0) {
        const barColor = branding.data[0].barColor;
        const BarColor = barColor && barColor !== "" ? barColor : "#768fbe";

        const contentColor = branding.data[0].contentColor;
        const ContentColor =
          contentColor && contentColor !== "" ? contentColor : "#00419a";

        document.documentElement.style.setProperty("--light-blue", BarColor);
        document.documentElement.style.setProperty("--blue", ContentColor);
      } else {
        document.documentElement.style.setProperty("--light-blue", "#768fbe");
        document.documentElement.style.setProperty("--blue", "#00419a");
      }
    } catch (error) {
      console.error("Error fetching content:", error);
      toastError();
    }
  };

  const changeTheme = (color) => {
    if (showHeaderColorPicker) {
      setShowHeaderColorPicker(!showHeaderColorPicker);
    }
    let Selectedcolor = valueToHex().toString();
    setColor(color);

    document.documentElement.style.setProperty(
      "--blue",
      Selectedcolor.toString()
    );
  };

  const changeHeaderTheme = (color) => {
    if (showColorPicker) {
      setShowColorPicker(!showColorPicker);
    }

    let Selectedcolor = headerColorValueToHex().toString();
    setHeaderColor(color);

    document.documentElement.style.setProperty(
      "--light-blue",
      Selectedcolor.toString()
    );
  };

  const handleClick = (action) => {
    var allElements = document.querySelectorAll("*");
    var excludedSection = document.querySelector(".accessibility-sidebar");
    switch (action) {
      case "increase-font":
        allElements.forEach(function (element) {
          if (excludedSection.contains(element)) return;

          var computedStyle = window.getComputedStyle(element);
          var currentSize = parseFloat(computedStyle.fontSize);
          var newSize = currentSize * 1.2; // Increase font size by 20%
          element.style.fontSize = newSize + "px";
        });
        break;
      case "decrease-font":
        allElements.forEach(function (element) {
          if (excludedSection.contains(element)) return;

          var computedStyle = window.getComputedStyle(element);
          var currentSize = parseFloat(computedStyle.fontSize);
          var newSize = currentSize / 1.2; // Decrease font size by 20%
          element.style.fontSize = newSize + "px";
        });
        break;
      case "high-contrast":
        allElements.forEach((element) => {
          if (excludedSection.contains(element)) return;
          if (
            element.tagName !== "svg" &&
            element.tagName !== "path" &&
            element.tagName !== "BODY" &&
            element.tagName !== "MAIN" &&
            element.id !== "root" &&
            element.tagName !== "IMG"
          ) {
            element.classList.toggle("high-contrast");
          }
        });
        break;
      case "negative-contrast":
        allElements.forEach(function (element) {
          if (excludedSection.contains(element)) return;
          const isInHeader = element.closest("#header") !== null;
          const isImgInHeader = isInHeader && element.tagName === "IMG";
          if (
            element.tagName !== "svg" &&
            element.tagName !== "path" &&
            element.tagName !== "BODY" &&
            element.tagName !== "MAIN" &&
            element.id !== "root" &&
            (!isInHeader || isImgInHeader) &&
            element.id !== "stories-container-div" &&
            element.tagName === "IMG"
          ) {
            element.classList.toggle("negative-contrast");
          }
        });
        break;
      case "underline-links":
        var allLinks = document.querySelectorAll("a");
        allLinks.forEach(function (link) {
          if (excludedSection.contains(link)) return;
          link.classList.toggle("underline");
        });
        break;
      case "reset-accessibility":
        allElements.forEach(function (element) {
          element.style.fontSize = "";
          if (element.tagName !== "svg" && element.tagName !== "path") {
            element.classList.remove("high-contrast");
            element.classList.remove("negative-contrast");
          }
        });
        var allLinks = document.querySelectorAll("a");
        allLinks.forEach(function (link) {
          link.classList.remove("underline");
        });
        if (showColorPicker) {
          setShowColorPicker(!showColorPicker);
        }
        if (showHeaderColorPicker) {
          setShowHeaderColorPicker(!showHeaderColorPicker);
        }
        fetchData();
        break;
      case "change-theme":
        setShowColorPicker(!showColorPicker);
        break;
      case "change-header-theme":
        setShowHeaderColorPicker(!showHeaderColorPicker);
        break;

      default:
        break;
    }
  };

  return (
    <>
      <section className="accessibility-sidebar" id="accessibility-sidebar">
        <div
          onClick={(e) => handleClick(e.target.id)}
          className="accessibility-icons"
        >
          <div className="accessibility-icon" id="increase-font">
            <i className="fas fa-font"></i> Increase-font
          </div>
          <div className="accessibility-icon" id="decrease-font">
            <i className="fas fa-font"></i> Decrease-font
          </div>
          <div className="accessibility-icon" id="high-contrast">
            <i className="fas fa-adjust"></i> High Contrast
          </div>
          <div className="accessibility-icon" id="negative-contrast">
            <i className="fas fa-adjust"></i> Negative Contrast
          </div>
          <div className="accessibility-icon" id="underline-links">
            <i className="fas fa-underline"></i> Underline Links
          </div>
          <div className="accessibility-icon" id="change-header-theme">
            <i className="fas fa-paintbrush"></i> Header Color
          </div>
          <div className="accessibility-icon" id="change-theme">
            <i className="fas fa-paintbrush"></i> Content Color
          </div>
          <div className="accessibility-icon" id="reset-accessibility">
            <i className="fas fa-sync-alt"></i> Reset
          </div>
        </div>
        <div
          className="accessibility-btn"
          id="accessibility-btn"
          onClick={handleAccessibility}
        >
          <i className="fas fa-universal-access"></i>
        </div>
        {showColorPicker && (
          <div className="accessibility-color-picker">
            <ColorPicker
              height={250}
              width={200}
              value={color}
              onChange={(e) => changeTheme(e)}
              hidePresets={true}
              hideInputs={true}
              hideControls={true}
              hideOpacity={true}
              hideEyeDrop={true}
              hideAdvancedSliders={true}
              hideColorGuide={true}
              hideInputType={true}
              hideColorTypeBtns={true}
              hideGradientType={true}
              hideGradientAngle={true}
              hideGradientStop={true}
              hideGradientControls={true}
            />
            <div className="d-flex justify-content-center mt-2">
              <button
                onClick={() => setShowColorPicker(!showColorPicker)}
                className="btn btn-primary"
              >
                Close
              </button>
            </div>
          </div>
        )}

        {showHeaderColorPicker && (
          <div className="accessibility-color-picker">
            <ColorPicker
              height={250}
              width={200}
              value={headerColor}
              onChange={(e) => changeHeaderTheme(e)}
              hidePresets={true}
              hideInputs={true}
              hideControls={true}
              hideOpacity={true}
              hideEyeDrop={true}
              hideAdvancedSliders={true}
              hideColorGuide={true}
              hideInputType={true}
              hideColorTypeBtns={true}
              hideGradientType={true}
              hideGradientAngle={true}
              hideGradientStop={true}
              hideGradientControls={true}
            />
            <div className="d-flex justify-content-center mt-2">
              <button
                onClick={() => setShowHeaderColorPicker(!showHeaderColorPicker)}
                className="btn btn-primary"
              >
                Close
              </button>
            </div>
          </div>
        )}
      </section>
    </>
  );
}

export default Accessibility;
