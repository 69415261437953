import React, { useEffect, useRef, useState } from "react";
import {
  deleteEnglishHistory,
  deleteNepaliHistory,
  getAllHistory,
  saveEnglishHistory,
  saveEnglishHistoryDescription,
  saveNepaliHistory,
  saveNepaliHistoryDescription,
} from "../../../../services/api";
import { ToastContainer } from "react-toastify";
import { toastError, toastSuccess } from "../../../../services/ToastService";

const AboutUsHistory = () => {
  const [isCollapsed, setIsCollapsed] = useState([]);
  const [formsFieldsEnglish, setformsFieldsEnglish] = useState({
    historyDescription: "",
    history: [
      {
        _id: "",
        image: "",
        title: "",
        subTitle: "",
        description: "",
      },
    ],
  });

  const [formsFieldsNepali, setformsFieldsNepali] = useState({
    historyDescription: "",
    history: [
      {
        _id: "",
        image: "",
        title: "",
        subTitle: "",
        description: "",
      },
    ],
  });
  const [error, setError] = useState("");

  const getallHistory = async () => {
    try {
      let res = await getAllHistory();

      if (res.data) {
        res.data.forEach((item) => {
          if (item.locale === "eng") {
            setformsFieldsEnglish((prevState) => ({
              ...prevState,
              historyDescription: item.historyDescription,
              history: item.history,
            }));
          } else if (item.locale === "nep") {
            setformsFieldsNepali((prevState) => ({
              ...prevState,
              historyDescription: item.historyDescription,
              history: item.history,
            }));
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleEnglishInputChange = (index, event) => {
    const { name, value } = event.target;
    setformsFieldsEnglish((prevState) => {
      const updatedHistory = [...prevState.history];
      updatedHistory[index][name] = value;
      return { ...prevState, history: updatedHistory };
    });
  };

  const handleNepaliInputChange = (index, event) => {
    const { name, value } = event.target;
    setformsFieldsNepali((prevState) => {
      const updatedHistory = [...prevState.history];
      updatedHistory[index][name] = value;
      return { ...prevState, history: updatedHistory };
    });
  };

  const handleAddHistory = () => {
    setformsFieldsEnglish({
      ...formsFieldsEnglish,
      history: [
        ...formsFieldsEnglish.history,
        {
          _id: "",
          image: "",
          title: "",
          subTitle: "",
          description: "",
        },
      ],
    });

    setformsFieldsNepali({
      ...formsFieldsNepali,
      history: [
        ...formsFieldsNepali.history,
        {
          _id: "",
          image: "",
          title: "",
          subTitle: "",
          description: "",
        },
      ],
    });
  };

  const handleRemoveHistory = async (index, nepaliHistory, enslishHistory) => {
    if (
      nepaliHistory._id &&
      nepaliHistory._id !== "" &&
      enslishHistory._id &&
      enslishHistory._id !== ""
    ) {
      await deleteNepaliHistory(nepaliHistory._id);
      await deleteEnglishHistory(enslishHistory._id);

      removeHistory(index);
    } else {
      removeHistory(index);
    }
  };

  const removeHistory = (index) => {
    const updatedEnglishHistory = [...formsFieldsEnglish.history];
    updatedEnglishHistory.splice(index, 1);
    setformsFieldsEnglish({
      ...formsFieldsEnglish,
      history: updatedEnglishHistory,
    });

    const updatedNepaliHistory = [...formsFieldsNepali.history];
    updatedNepaliHistory.splice(index, 1);
    setformsFieldsNepali({
      ...formsFieldsNepali,
      history: updatedNepaliHistory,
    });
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    try {
      if (formsFieldsEnglish) {
        let data = {
          historyDescription: formsFieldsEnglish.historyDescription,
        };
        await saveEnglishHistoryDescription(data);
      }

      if (formsFieldsNepali) {
        let data = {
          historyDescription: formsFieldsNepali.historyDescription,
        };
        await saveNepaliHistoryDescription(data);
      }

      toastSuccess();
    } catch (e) {
      toastError();
      console.log(e);
    }
  };

  const handleImageChange = (index, event) => {
    const file = event.target.files[0];
    if (file) {
      // Check the file size (in bytes)
      if (file.size > 640 * 1024) {
        // 320 KB = 320 * 1024 bytes
        setError("Please upload an image below the size of 640KB");
        // Optionally, clear the file input
        event.target.value = "";
      } else {
        setError(""); // Clear error if file size is valid
        // Proceed with uploading the file or processing the image
      }
    }

    setformsFieldsEnglish((prevState) => {
      const updatedHistory = [...prevState.history];
      updatedHistory[index].image = file;
      return { ...prevState, history: updatedHistory };
    });

    setformsFieldsNepali((prevState) => {
      const updatedHistory = [...prevState.history];
      updatedHistory[index].image = file;
      return { ...prevState, history: updatedHistory };
    });
  };

  useEffect(() => {
    setIsCollapsed(new Array(formsFieldsEnglish.history.length).fill(true));
  }, [formsFieldsEnglish.history.length]);

  useEffect(() => {
    getallHistory();
  }, []);

  const toggleCollapse = (index) => {
    setIsCollapsed((prevState) =>
      prevState.map((state, i) => (i === index ? !state : state))
    );
  };

  const handleSaveHistory = async (index, e) => {
    e.preventDefault();
    try {
      if (formsFieldsEnglish) {
        const formData = new FormData();
        formData.append("title", formsFieldsEnglish.history[index].title);
        formData.append("image", formsFieldsEnglish.history[index].image);
        formData.append("subTitle", formsFieldsEnglish.history[index].subTitle);
        formData.append(
          "description",
          formsFieldsEnglish.history[index].description
        );
        formData.append("id", formsFieldsEnglish.history[index]._id);
        await saveEnglishHistory(formData);
      }

      if (formsFieldsNepali) {
        const formData = new FormData();
        formData.append("title", formsFieldsNepali.history[index].title);
        formData.append("image", formsFieldsNepali.history[index].image);
        formData.append("subTitle", formsFieldsNepali.history[index].subTitle);
        formData.append(
          "description",
          formsFieldsNepali.history[index].description
        );
        formData.append("id", formsFieldsNepali.history[index]._id);
        await saveNepaliHistory(formData);
      }
      toastSuccess();
    } catch (error) {
      console.log(error);
      toastError();
    }
  };

  return (
    <>
      <main className="main">
        <div className="pagetitle">
          <h1>History</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="index.html">Auth</a>
              </li>
              <li className="breadcrumb-item">Components</li>
              <li className="breadcrumb-item active">History</li>
            </ol>
          </nav>
        </div>
        <section className="section">
          <div className="row">
            <div className="col-lg-12">
              <div className="card">
                <div className="card-body">
                  <div className="mt-3 mb-3 d-flex justify-content-end">
                    <button
                      type="submit"
                      onClick={onSubmit}
                      className="btn btn-primary"
                    >
                      Save
                    </button>
                  </div>
                  <div className="row ">
                    <div className="col-md-6">
                      <h4 className="card-title">English </h4>
                      <hr className="border-2" />
                      <div className="mb-3">
                        <label className="form-label">Description</label>
                        <textarea
                          type="text"
                          className="form-control"
                          value={formsFieldsEnglish.historyDescription}
                          onChange={(e) =>
                            setformsFieldsEnglish({
                              ...formsFieldsEnglish,
                              historyDescription: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <h4 className="card-title">Nepali </h4>
                      <hr className="border-2" />
                      <div className="mb-3">
                        <label className="form-label">Description</label>
                        <textarea
                          type="text"
                          className="form-control"
                          value={formsFieldsNepali.historyDescription}
                          onChange={(e) =>
                            setformsFieldsNepali({
                              ...formsFieldsNepali,
                              historyDescription: e.target.value,
                            })
                          }
                        />
                      </div>
                    </div>
                  </div>

                  {formsFieldsEnglish.history.map((history, index) => (
                    <>
                      <div key={index} className="parameters-container">
                        <div
                          className="parameters-header"
                          onClick={() => toggleCollapse(index)}
                        >
                          <span className="parameters-title">
                            {index + 1}. History
                          </span>
                          <div className="icon-containers">
                            <button className="parameters-toggle-btn">
                              <i
                                onClick={handleAddHistory}
                                class="bi bi-plus-lg"
                              ></i>
                            </button>
                            <button className="parameters-toggle-btn">
                              <i
                                onClick={() =>
                                  handleRemoveHistory(
                                    index,
                                    formsFieldsNepali.history[index],
                                    history
                                  )
                                }
                                class="bi bi-trash3"
                              ></i>
                            </button>
                            <button
                              className="parameters-toggle-btn"
                              id={`toggleButton${index}`}
                            >
                              {isCollapsed[index] ? "\u25BC" : "\u25B2"}
                            </button>
                          </div>
                        </div>
                        <div
                          className="parameters-body"
                          id={`parametersBody${index}`}
                          style={{
                            display: isCollapsed[index] ? "none" : "block",
                          }}
                        >
                          <div className=" border border-dark rounded">
                            <div className="d-flex mt-3 container">
                              <h5 className="card-title">
                                {index + 1}. History
                              </h5>
                              <hr className="border-2" />
                              <div className=" justify-content-end">
                                <button
                                  onClick={(e) => handleSaveHistory(index, e)}
                                  className="btn btn-primary"
                                >
                                  Save
                                </button>
                              </div>
                            </div>
                            <div className=" row m-2 ">
                              <div className="col-md-6 mb-3">
                                <label className="form-label">
                                  Image (Best Fit Size: height: 350px, width:
                                  750px)
                                </label>
                                <input
                                  type="file"
                                  onChange={(e) => handleImageChange(index, e)}
                                  accept="image/jpg, image/png, image/gif, image/jpeg"
                                />
                                {error && (
                                  <div
                                    style={{ color: "red", marginTop: "5px" }}
                                  >
                                    {error}
                                  </div>
                                )}
                              </div>

                              <div className=" mb-3 col-md-6">
                                <img
                                  style={{
                                    height: "200px",
                                    width: "300px",
                                    objectFit: "contain",
                                  }}
                                  src={history.image}
                                  alt="Uploaded Photograph"
                                />
                              </div>

                              <div className="col-md-6">
                                <h4 className="card-title">English </h4>
                                <hr className="border-2" />
                                <div className=" mb-3">
                                  <label className="form-label">Title</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={history.title}
                                    name="title"
                                    onChange={(e) =>
                                      handleEnglishInputChange(index, e)
                                    }
                                  />
                                </div>

                                <div className=" mb-3">
                                  <label className="form-label">
                                    Sub Title
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={history.subTitle}
                                    name="subTitle"
                                    onChange={(e) =>
                                      handleEnglishInputChange(index, e)
                                    }
                                  />
                                </div>

                                <div className=" mb-3">
                                  <label className="form-label">
                                    Description
                                  </label>
                                  <textarea
                                    type="text"
                                    className="form-control"
                                    value={history.description}
                                    name="description"
                                    onChange={(e) =>
                                      handleEnglishInputChange(index, e)
                                    }
                                  />
                                </div>
                              </div>

                              <div className="col-md-6">
                                <h4 className="card-title">Nepali </h4>
                                <hr className="border-2" />
                                <div className="mb-3">
                                  <label className="form-label">Title</label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={
                                      formsFieldsNepali.history[index].title
                                    }
                                    name="title"
                                    onChange={(e) =>
                                      handleNepaliInputChange(index, {
                                        target: {
                                          name: "title",
                                          value: e.target.value,
                                        },
                                      })
                                    }
                                  />
                                </div>

                                <div className="mb-3">
                                  <label className="form-label">
                                    Sub Title
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={
                                      formsFieldsNepali.history[index].subTitle
                                    }
                                    name="subTitle"
                                    onChange={(e) =>
                                      handleNepaliInputChange(index, {
                                        target: {
                                          name: "subTitle",
                                          value: e.target.value,
                                        },
                                      })
                                    }
                                  />
                                </div>

                                <div className="mb-3">
                                  <label className="form-label">
                                    Description
                                  </label>
                                  <textarea
                                    type="text"
                                    className="form-control"
                                    value={
                                      formsFieldsNepali.history[index]
                                        .description
                                    }
                                    name="description"
                                    onChange={(e) =>
                                      handleNepaliInputChange(index, {
                                        target: {
                                          name: "description",
                                          value: e.target.value,
                                        },
                                      })
                                    }
                                  />
                                </div>
                              </div>
                            </div>

                            <div className="m-3 d-flex justify-content-end  ">
                              <button
                                onClick={() =>
                                  handleRemoveHistory(
                                    index,
                                    formsFieldsNepali.history[index],
                                    history
                                  )
                                }
                                className="btn btn-primary"
                              >
                                Remove History
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                      <br />
                    </>
                  ))}

                  <div className="mt-3 mb-3 d-flex justify-content-end">
                    <button
                      className="btn btn-primary"
                      onClick={handleAddHistory}
                    >
                      Add History
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ToastContainer />
      </main>
    </>
  );
};

export default AboutUsHistory;
