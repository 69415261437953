import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getPublicationById } from "../../../services/api";

const PublicationById = () => {
  const [publication, setpublication] = useState("");
  let { id } = useParams();

  const getPublication = async () => {
    try {
      let res = await getPublicationById(id);
      if (res.data) {
        setpublication(res.data);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getPublication();
  }, []);
  return (
    <>
      <section className="news-section mt-5">
        <div className="container">
          <div className="row">
            <div className="col-4">
              <h3 className="font-weight-bold text-blue">
                {publication.title}
              </h3>
            </div>
            <div className="col-8">
              {" "}
              <embed
                src={publication.pdf ? publication.pdf : ""}
                title={publication.title}
                className="individual-publication"
                type="application/pdf"
              ></embed>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PublicationById;
