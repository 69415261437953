import React, { useEffect, useState } from "react";
import { getTermsOfService } from "../../services/api";
import Accessibility from "./Accessibility";

const TermsOfService = () => {
  const [TermsOfService, setTermsOfService] = useState({});

  const fetchData = async () => {
    try {
      const response = await getTermsOfService();
      setTermsOfService(response.data);
    } catch (error) {
      throw error;
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      <section>
        <Accessibility />

        <div>
          <div dangerouslySetInnerHTML={{ __html: TermsOfService.content }} />
        </div>
      </section>
    </>
  );
};

export default TermsOfService;
